import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';

import { CirclePicker } from 'react-color';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../../sharedFiles/navigation-store';

import FormValidator from '../../../../../sharedFiles/FormValidator';

import ThemeSelectorComponent from './ThemeSelectorComponent';
import IconBuilderPreviewComponent from './IconBuilderPreviewComponent';
import WidgetCodeComponent from '../../../../../sharedComponents/getWidgetCode/WidgetCodeComponent';
import ConfirmationComponent from '../../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`
const WidgetIconBuilderComponent = observer(class WidgetIconBuilderComponent extends Component {

	constructor (props) {
        super(props);

        this.validator = new FormValidator([
 			{
 				field: 'installationName',
 				method: 'isEmpty',
 				validWhen: false, 
	        	message: 'Installation Name is required.' 
 			}
	    ]);

	    this.state = {
			validation: this.validator.valid(),
			websiteUid:this.props.appstore.selectedWebisiteUid,
	    }
	    this.submitted = false;

    }

	componentDidMount(){		
		this.props.widgetStore.selectedWidgetIcon = "theme";
		this.props.widgetStore.getCode = false;
		this.props.widgetStore.sendCode = false;

		//set dropdown data
		this.props.widgetStore.operatorDrpDwnOptions = this.props.appstore.deptOps.allOps;
		this.props.widgetStore.deptDrpDwnOptions = this.props.appstore.deptOps.allDept;
		this.props.widgetStore.widgetIconDetails.department = this.props.widgetStore.deptDrpDwnOptions[0];
		this.props.widgetStore.widgetIconDetails.operator = this.props.widgetStore.operatorDrpDwnOptions[0];
		this.props.widgetStore.widgetIconDetails.language = this.props.appstore.selectedLanguage;
		this.props.widgetStore.pageisLoaded = true;
		this.props.widgetStore.getallCustomIconByAccountId(this.props.appstore);
		 let self=this;
		setTimeout(function() { self.props.widgetStore.setwidgetDataforEdit(self.props.appstore) }, 100);
	}


	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) { 
			//if user changes website then point to main widgets page
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});	
			  this.props.appstore.navigate('/admin/setup/widgets/');	  
		}
	  }

	handleFormSubmit(event) {
		event.preventDefault();
		const validation = this.validator.validate(this.props.widgetStore.widgetIconDetails);
	    this.setState({ validation });
	    this.submitted = true;
		if (validation.isValid) {

			let trimedName = this.props.widgetStore.widgetIconDetails.installationName.trim();
		   if(trimedName.length==0){
			   //stop submission if spaces
			   return;
		   }
		   // handle actual form submission here
		   this.props.widgetStore.widgetIconDetails.installationName =trimedName;

		   if (this.props.widgetStore.selectedonlinefile != "" || this.props.widgetStore.selectedofflinefile != "") {
			const formData = new FormData()
			formData.set('id', '100');  
				this.state.online = this.props.widgetStore.selectedonlinefile ;
				this.state.offline = this.props.widgetStore.selectedofflinefile;
				this.setState({ uploading: true })
				if(this.props.widgetStore.selectedonlinefile != ""){ formData.append("file", this.state.online)}
				if( this.props.widgetStore.selectedofflinefile != ""){formData.append("offlineFile", this.state.offline)}
				
			 
			this.props.widgetStore.savecustomeiceon(this.props.appstore, formData);
				 
		} else {
		   this.props.widgetStore.saveWidgetIcon(this.props.appstore);
		}
	    
	    //  this.props.deptStore.saveDepartment(this.props.widgetStore.widgetIconDetails);
	    }
	}

	componentWillUnmount() {
		this.props.widgetStore.getCode = false;
		this.props.widgetStore.sendCode = false;
    }

	toggleFeature(feature, currentState) {
		const { enableVirtualAgent, enableChatBot } = currentState;
	
		if (feature === "VirtualAgent") {
			if (!enableChatBot) {			
				this.props.widgetStore.widgetIconDetails.enableVirtualAgent = !enableVirtualAgent;
			} else {			
				this.props.widgetStore.validateChatbot_VA_Enable=true;
			}
		}
	
		if (feature === "ChatBot") {
			if (!enableVirtualAgent) {				
			  this.props.widgetStore.widgetIconDetails.enableChatBot = !enableChatBot;
			} else {			
			  this.props.widgetStore.validateChatbot_VA_Enable=true;
			}
		}
	}

	render(){

		var self = this;
		const vacss = {
			pointerEvents:"none",
			opacity: 0.3
		}
		const nocss = {};

		let validation = this.submitted ?                         // if the form has been submitted at least once
                      this.validator.validate(this.props.widgetStore.widgetIconDetails) :   // then check validity every time we render
                      this.state.validation  
					//   if (this.props.widgetStore.pageisLoaded) {
					// 	return (<div className="profileLoader">
					// 		<GridLoader
					// 			css={override}
					// 			size={20}
					// 			marging={2}
					// 			color={this.props.appstore.selectedColor.color}
					// 		// loading={this.responseStore.responsereload}
					// 		/>
					// 	</div>)
					// }
		const { appstore,
				widgetStore } = this.props; 
		return (
				<div key={appstore.selectedWebisiteUid} className="settingsForm directLinkWrapper widgetIconWrapper col-12 w-100 pr-0">
					<h4 className="text-left">{appstore.getLanguageTrans("Widget")} + {appstore.getLanguageTrans("Icon Builder")} 
					</h4>

					<div className="settingFormWrapper box">
						<div className="row">
							<div className={"col-12 col-xl-7" + (!appstore.menuActive ? ' col-lg-7' : '')}>
								<form>
									<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className={"pr-0" + (validation.installationName.isInvalid && ' field-error')}>{appstore.getLanguageTrans("Installation name")} <span className="mandatory">*</span></Form.Label>
								    	<Col sm="6" lg="7">
								    		<Form.Control type="text" className={validation.installationName.isInvalid && 'has-error'}
	    											name="installationName" placeholder={appstore.getLanguageTrans("Enter Installation Name")} 
								    				value={widgetStore.widgetIconDetails.installationName} 
								    				onChange={(e) => widgetStore.widgetIconDetails.installationName = e.target.value} />
								    		<span className="error-block">{validation.installationName.message}</span>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Department")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.deptDrpDwnOptions}
																store={widgetStore}
																key ={Math.random()}
											    				callBack={(selectedOption) => widgetStore.getOpforDept(appstore,widgetStore.widgetIconDetails,selectedOption)}
											    				selectedOption = {widgetStore.widgetIconDetails.department}/>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Operator")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.operatorDrpDwnOptions}
																store={widgetStore}
																key ={Math.random()}
											    				callBack={(selectedOption) => widgetStore.widgetIconDetails.operator = selectedOption}
											    				selectedOption = {widgetStore.widgetIconDetails.operator}/>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Language")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
										    <DropDownComponent dropDownOptions={appstore.languages}
															store={appstore}
															key ={Math.random()}
															callBack={(selectedLanguage) =>widgetStore.setLanguage(selectedLanguage,appstore,widgetStore.widgetIconDetails)}
															selectedOption = {appstore.selectedLanguage}/>
								    	</Col>
								  	</Form.Group>
									
						 <Form.Group as={Row} controlId="formGroupMsg">
							<Form.Label column lg="5" sm="6" className="pr-0">
								{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("Virtual Agent")}
							</Form.Label>
							{appstore.userInfo.accountplan.canUseVirtualAgent != 1 && (
								<span data-tooltip={appstore.getLanguageTrans("Upgrade your plan")} data-tooltip-conf="top">
									<Col className="custom_radioBtn" style={vacss}>
										<CheckBoxComponent 
											radioValue={widgetStore.widgetIconDetails.enableVirtualAgent}
											callBack={() => this.toggleFeature("VirtualAgent", {
												enableVirtualAgent: widgetStore.widgetIconDetails.enableVirtualAgent,
												enableChatBot: widgetStore.widgetIconDetails.enableChatBot
											}, widgetStore)}
										/>
									</Col>
								</span>
							)}
							{appstore.userInfo.accountplan.canUseVirtualAgent != 0 && (
								<Col className="custom_radioBtn">
									<CheckBoxComponent 
										radioValue={widgetStore.widgetIconDetails.enableVirtualAgent}
										callBack={() => this.toggleFeature("VirtualAgent", {
											enableVirtualAgent: widgetStore.widgetIconDetails.enableVirtualAgent,
											enableChatBot: widgetStore.widgetIconDetails.enableChatBot
										}, widgetStore)}
									/>
								</Col>
							)}
						</Form.Group>

						<Form.Group as={Row} controlId="formGroupMsg">
							<Form.Label column lg="5" sm="6" className="pr-0">
								{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("ChatBot")}
							</Form.Label>
							{appstore.userInfo.accountplan.enable_chatbot != 1 && (
								<span data-tooltip={appstore.getLanguageTrans("Upgrade your plan")} data-tooltip-conf="top">
									<Col className="custom_radioBtn" style={vacss}>
										<CheckBoxComponent 
											radioValue={widgetStore.widgetIconDetails.enableChatBot}
											callBack={() => this.toggleFeature("ChatBot", {
												enableVirtualAgent: widgetStore.widgetIconDetails.enableVirtualAgent,
												enableChatBot: widgetStore.widgetIconDetails.enableChatBot
											}, widgetStore)}
										/>
									</Col>
								</span>
							)}
							{appstore.userInfo.accountplan.enable_chatbot != 0 && appstore.userInfo.accountplan.max_chatbot != 0 && (
								appstore.userInfo.accountsmodel.chatbotusedcount > 0 ? (
									<Col className="custom_radioBtn">
										<CheckBoxComponent 
											radioValue={widgetStore.widgetIconDetails.enableChatBot}
											callBack={() => this.toggleFeature("ChatBot", {
												enableVirtualAgent: widgetStore.widgetIconDetails.enableVirtualAgent,
												enableChatBot: widgetStore.widgetIconDetails.enableChatBot
											}, widgetStore)}
										/>
									</Col>
								) : (
									<span data-tooltip={appstore.getLanguageTrans("Please Set Up Your ChatBot")} data-tooltip-conf="top">
										<Col className="custom_radioBtn" style={vacss}>
											<CheckBoxComponent 
												radioValue={widgetStore.widgetIconDetails.enableChatBot}
												callBack={() => this.toggleFeature("ChatBot", {
													enableVirtualAgent: widgetStore.widgetIconDetails.enableVirtualAgent,
													enableChatBot: widgetStore.widgetIconDetails.enableChatBot
												}, widgetStore)}
											/>
										</Col>
									</span>
								)
							)}
						</Form.Group>


								  	{/* <Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Enable pre-chat fields")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.widgetIconDetails.preChatEnabled}
									    							callBack = {() => widgetStore.widgetIconDetails.preChatEnabled = !widgetStore.widgetIconDetails.preChatEnabled}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Show pre-chat fields to invited users")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.widgetIconDetails.preChatToUsers}
									    							callBack = {() => widgetStore.widgetIconDetails.preChatToUsers = !widgetStore.widgetIconDetails.preChatToUsers}/>
									    </Col>
								  	</Form.Group> */}
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Hide when offline")}</Form.Label>
								    	{appstore.userInfo.accountplan.hide_widget_offline !=1 &&
										<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
										<Col className="custom_radioBtn" style={vacss}>
									    	<CheckBoxComponent radioValue={widgetStore.widgetIconDetails.offlineHidden}
									    							callBack = {() => widgetStore.widgetIconDetails.offlineHidden = !widgetStore.widgetIconDetails.offlineHidden}/>
									    </Col></span>}
										{appstore.userInfo.accountplan.hide_widget_offline !=0 &&
										
										<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.widgetIconDetails.offlineHidden}
									    							callBack = {() => widgetStore.widgetIconDetails.offlineHidden = !widgetStore.widgetIconDetails.offlineHidden}/>
									    </Col>}
								  	</Form.Group>
								</form>
							</div>
							<div className={"col-12 col-xl-5" + (!appstore.menuActive ? ' col-lg-5' : '')}>
								<p className="theme">
									<span className={widgetStore.selectedWidgetIcon === "theme" ? 'selected' : ''}
											onClick={()=> widgetStore.selectedWidgetIcon = "theme"}>{appstore.getLanguageTrans("Choose a Theme")}</span>
									<span className={widgetStore.selectedWidgetIcon === "icon" ? 'selected' : ''}
											onClick={()=> widgetStore.selectedWidgetIcon = "icon"}>{appstore.getLanguageTrans("Choose an Icon")}</span>
								</p>
								{
									widgetStore.selectedWidgetIcon === "theme" && 
										<ThemeSelectorComponent appstore={appstore} widgetStore={widgetStore} />	
								}

								{
									widgetStore.selectedWidgetIcon === "icon" && 
										<IconBuilderPreviewComponent widgetStore={widgetStore} appstore={appstore} />	
								}
								
							</div>
						</div>
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullRight">
									<Button className="btn_clr" variant="light" onClick={() => {widgetStore.selectedWidget = 0}}>
								    	<p>{appstore.getLanguageTrans("Cancel")}</p>
								    </Button>
								    <Button className="btn_save" onClick={(event) => this.handleFormSubmit(event)}>
								    	<p>{appstore.getLanguageTrans("Save & Get code")}</p>
								    </Button>
								</div>
							</div>
						</div>
						{
							widgetStore.getCode && 
								<WidgetCodeComponent store = {widgetStore} appstore={appstore}/>
						}
						{
					widgetStore.conformationpopup &&
					<ConfirmationComponent show={widgetStore.conformationpopup}
						store={widgetStore}
						title="Delete Icon"
						body_html="<p>Are you sure you want to delete the selected Icon?</p>"
						cancel_btn="Cancel"
						save_btn="Confirm"
						callBack={() => widgetStore.deletecustomicon(appstore)}
						closePopup={() => (widgetStore.conformationpopup = false, widgetStore.icon_uid="",widgetStore.id="",widgetStore.mode="")}
						appStore={appstore} />
				}
				{
				   widgetStore.validateChatbot_VA_Enable && 
					<ConfirmationComponent show={widgetStore.validateChatbot_VA_Enable}
					store={this.appstore}
					body_html="<p>You cannot enable both the Virtual Agent and the ChatBot at the same time..</p>"
					cancel_btn="Okay" singleButton
					callBack={() =>  widgetStore.validateChatbot_VA_Enable=false}
					closePopup={() => widgetStore.validateChatbot_VA_Enable=false}
					appStore = {appstore}/>
				}
						
					</div>
				</div>
		)
	}
})
export default WidgetIconBuilderComponent;
