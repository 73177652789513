import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';
import WarningComponent from '../../../../../sharedComponents/confirmationPopup/WarningComponent';
import { CirclePicker } from 'react-color';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../../sharedFiles/navigation-store';
import FormValidator from '../../../../../sharedFiles/FormValidator';
import WidgetCodeComponent from '../../../../../sharedComponents/getWidgetCode/WidgetCodeComponent';
import ThemeSelectorComponent from './ThemeSelectorComponent';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

import TourGuide from '../../../../../sharedComponents/TourGuide';
import Tour from "react-product-intro"; 
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import ConfirmationComponent from '../../../../../sharedComponents/confirmationPopup/ConfirmationComponent';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const WidgetBuilderComponent = observer(class WidgetBuilderComponent extends Component {

	constructor (props) {
        super(props);

        this.validator = new FormValidator([
 			{
 				field: 'installationName',
 				method: 'isEmpty',
 				validWhen: false, 
	        	message: 'Installation Name is required.' 
 			}
	    ]);

	    this.state = {
			validation: this.validator.valid(),
			websiteUid:this.props.appstore.selectedWebisiteUid,
			// tourStep:5
	    }
	    this.submitted = false;
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.props.widgetStore.loadCustomlibrary(this.props.appstore)
		this.props.widgetStore.setwidgetBuildertourSteps(this.props.appstore);
    } 

	componentDidMount(){
		//set dropdown data
		this.props.widgetStore.operatorDrpDwnOptions = this.props.appstore.deptOps ?  this.props.appstore.deptOps.allOps : this.props.widgetStore.deptDrpDwnOptions;
		this.props.widgetStore.deptDrpDwnOptions= this.props.appstore.deptOps ? this.props.appstore.deptOps.allDept : this.props.widgetStore.deptDrpDwnOptions;
		this.props.widgetStore.widgetDetails.department = this.props.widgetStore.deptDrpDwnOptions[0];
		this.props.widgetStore.widgetDetails.operator = this.props.widgetStore.operatorDrpDwnOptions[0];
		this.props.widgetStore.widgetDetails.language = this.props.appstore.selectedLanguage;
		this.props.widgetStore.getCode = false;
		this.props.widgetStore.sendCode = false;
		this.props.widgetStore.pageisLoaded=true;
		this.props.widgetStore.setwidgetDataforEdit(this.props.appstore);

		//this.props.appstore.helpTourStarted 
		// setTimeout(() => {
			if(this.props.appstore.helpTourStarted){
				this.props.widgetStore.widgetBuildertourStart = true;
			}
		// }, 3000);
	}

	
	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) { 
			//if user changes website then point to main widgets page
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});	
			  this.props.appstore.navigate('/admin/setup/widgets/');	  
		}
	  }

	handleFormSubmit(event) {
		event.preventDefault();
		const validation = this.validator.validate(this.props.widgetStore.widgetDetails);
	    this.setState({ validation });
	    this.submitted = true;
		if (validation.isValid) {
		  // handle actual form submission here
		  let trimedName = this.props.widgetStore.widgetDetails.installationName.trim();
		   if(trimedName.length==0){
			   //stop submission if spaces
			   return;
		   }
		  
		  this.props.widgetStore.widgetDetails.installationName =trimedName;
		  this.props.widgetStore.saveWidget(this.props.appstore);
	    //  this.props.deptStore.saveDepartment(this.props.widgetStore.widgetDetails);
	    }
	}
	 
	componentWillUnmount() {
    }

	toggleFeature(feature, currentState) {
		const { enableVirtualAgent, enableChatBot } = currentState;
	
		if (feature === "VirtualAgent") {
			if (!enableChatBot) {
				this.props.widgetStore.widgetDetails.enableVirtualAgent = !enableVirtualAgent;
			} else {			
				this.props.widgetStore.validateChatbot_VA_Enable=true;
			}
		}
	
		if (feature === "ChatBot") {
			if (!enableVirtualAgent) {				
				this.props.widgetStore.widgetDetails.enableChatBot = !enableChatBot;
			} else {			
			 	this.props.widgetStore.validateChatbot_VA_Enable=true;
			}
		}
	}
	

	render(){

		var self = this;
		let validation = this.submitted ?                         // if the form has been submitted at least once
                      this.validator.validate(this.props.widgetStore.widgetDetails) :   // then check validity every time we render
                      this.state.validation  
					  const vacss = {
						pointerEvents:"none",
						opacity: 0.3
					}
					const nocss = {};
		const { appstore,
				widgetStore } = this.props;
			// if(!this.props.widgetStore.pageisLoaded){
			// 	return (<div></div>)
			// }			
		return (
				<div key={appstore.selectedWebisiteUid} className="settingsForm directLinkWrapper col-12 w-100 pr-0">
					<h4 className="text-left">{appstore.getLanguageTrans("Widget Builder")} 
					</h4>

					<div className="settingFormWrapper box">
						<div className="row">
							<div className={"col-12 col-xl-7" + (!appstore.menuActive ? ' col-lg-7' : '')}>
								<form>
									<Form.Group as={Row} controlId="formGroupMsg"  className="installation_namewidget_tour">
								    	<Form.Label column lg="5" sm="6" className={"pr-0" + (validation.installationName.isInvalid && ' field-error')}>Installation name <span className="mandatory">*</span></Form.Label>
								    	<Col sm="6" lg="7">
								    		<Form.Control type="text" className={validation.installationName.isInvalid && 'has-error'}
	    											name="installationName" placeholder={appstore.getLanguageTrans("Enter Installation Name")} 
								    				value={widgetStore.widgetDetails.installationName} 
								    				onChange={(e) => widgetStore.widgetDetails.installationName = e.target.value} />
								    		<span className="error-block">{validation.installationName.message}</span>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Department")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
											<DropDownComponent dropDownOptions={widgetStore.deptDrpDwnOptions}
											                    key ={Math.random()}
									    						store={widgetStore}
											    				callBack={(selectedOption) => widgetStore.getOpforDept(appstore,widgetStore.widgetDetails,selectedOption)}
											    				selectedOption = {widgetStore.widgetDetails.department}/>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Operator")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.operatorDrpDwnOptions}
																store={widgetStore}
																key ={Math.random()}
											    				callBack={(selectedOption) => widgetStore.widgetDetails.operator = selectedOption}
											    				selectedOption = {widgetStore.widgetDetails.operator}/>

								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Language")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		{/* <DropDownComponent dropDownOptions={widgetStore.lanDropDownOptions}
									    						store={widgetStore}
											    				callBack={(selectedOption) => widgetStore.widgetDetails.language = selectedOption}
																selectedOption = {widgetStore.widgetDetails.language}/> */}
											<DropDownComponent dropDownOptions={appstore.languages}
												store={appstore}
												key ={Math.random()}
												callBack={(selectedLanguage) =>widgetStore.setLanguage(selectedLanguage,appstore,widgetStore.widgetDetails)}
												selectedOption = {appstore.selectedLanguage}/>					
								    	</Col>
								  	</Form.Group>
									 
				<Form.Group as={Row} controlId="formGroupMsg">
					<Form.Label column lg="5" sm="6" className="pr-0">
						{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("Virtual Agent")}
					</Form.Label>
					{appstore.userInfo.accountplan.canUseVirtualAgent != 1 && (
						<span data-tooltip={appstore.getLanguageTrans("Upgrade your plan")} data-tooltip-conf="top">
							<Col className="custom_radioBtn" style={vacss}>
								<CheckBoxComponent 
									radioValue={widgetStore.widgetDetails.enableVirtualAgent}
									callBack={() => this.toggleFeature("VirtualAgent", {
										enableVirtualAgent: widgetStore.widgetDetails.enableVirtualAgent,
										enableChatBot: widgetStore.widgetDetails.enableChatBot
									})}
								/>
							</Col>
						</span>
					)}
					{appstore.userInfo.accountplan.canUseVirtualAgent != 0 && (
						<Col className="custom_radioBtn">
							<CheckBoxComponent 
								radioValue={widgetStore.widgetDetails.enableVirtualAgent}
								callBack={() => this.toggleFeature("VirtualAgent", {
									enableVirtualAgent: widgetStore.widgetDetails.enableVirtualAgent,
									enableChatBot: widgetStore.widgetDetails.enableChatBot
								})}
							/>
						</Col>
					)}
				</Form.Group>

				<Form.Group as={Row} controlId="formGroupMsg">
					<Form.Label column lg="5" sm="6" className="pr-0">
						{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("ChatBot")}
					</Form.Label>
					{appstore.userInfo.accountplan.enable_chatbot != 1 && (
						<span data-tooltip={appstore.getLanguageTrans("Upgrade your plan")} data-tooltip-conf="top">
							<Col className="custom_radioBtn" style={vacss}>
								<CheckBoxComponent 
									radioValue={widgetStore.widgetDetails.enableChatBot}
									callBack={() => this.toggleFeature("ChatBot", {
										enableVirtualAgent: widgetStore.widgetDetails.enableVirtualAgent,
										enableChatBot: widgetStore.widgetDetails.enableChatBot
									})}
								/>
							</Col>
						</span>
					)}
					
					{appstore.userInfo.accountplan.enable_chatbot != 0 && appstore.userInfo.accountplan.max_chatbot != 0 && (
						 appstore.userInfo.accountsmodel.chatbotusedcount > 0 ? (
							<Col className="custom_radioBtn">
								<CheckBoxComponent 
									radioValue={widgetStore.widgetDetails.enableChatBot}
									callBack={() => this.toggleFeature("ChatBot", {
										enableVirtualAgent: widgetStore.widgetDetails.enableVirtualAgent,
										enableChatBot: widgetStore.widgetDetails.enableChatBot
									})}
								/>
							</Col>
						) : (
							<span data-tooltip={appstore.getLanguageTrans("Please Set Up Your ChatBot")} data-tooltip-conf="top">
								<Col className="custom_radioBtn" style={vacss}>
									<CheckBoxComponent 
										radioValue={widgetStore.widgetDetails.enableChatBot}
										callBack={() => this.toggleFeature("ChatBot", {
											enableVirtualAgent: widgetStore.widgetDetails.enableVirtualAgent,
											enableChatBot: widgetStore.widgetDetails.enableChatBot
										})}
									/>
								</Col>
							</span>
						)
					)}
				</Form.Group>


								  	{/* <Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Enable pre-chat fields")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.widgetDetails.preChatEnabled}
									    							callBack = {() => widgetStore.widgetDetails.preChatEnabled = !widgetStore.widgetDetails.preChatEnabled}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Show pre-chat fields to invited users")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.widgetDetails.preChatToUsers}
									    							callBack = {() => widgetStore.widgetDetails.preChatToUsers = !widgetStore.widgetDetails.preChatToUsers}/>
									    </Col>
								  	</Form.Group> */}
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("When widget presents, show invitation inside it")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.widgetDetails.showInvitation}
									    							callBack = {() => widgetStore.widgetDetails.showInvitation = !widgetStore.widgetDetails.showInvitation}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Hide when offline")}</Form.Label>
										{appstore.userInfo.accountplan.hide_widget_offline !=1 &&
										<span data-tooltip={appstore.getLanguageTrans("Upgarde your plan")} data-tooltip-conf="top">
								    	<Col className="custom_radioBtn"  style={vacss}>
										   
									    	<CheckBoxComponent radioValue={widgetStore.widgetDetails.offlineHidden}
									    							callBack = {() => widgetStore.widgetDetails.offlineHidden = !widgetStore.widgetDetails.offlineHidden}
																	className="e-btn"> </CheckBoxComponent>
																	
									    </Col>
										</span>}
										{appstore.userInfo.accountplan.hide_widget_offline !=0 &&
								    	<Col className="custom_radioBtn" >
										   
									    	<CheckBoxComponent radioValue={widgetStore.widgetDetails.offlineHidden}
									    							callBack = {() => widgetStore.widgetDetails.offlineHidden = !widgetStore.widgetDetails.offlineHidden}
																	className="e-btn"> </CheckBoxComponent>
																	
									    </Col>
										}
										
								  	</Form.Group>
								</form>
							</div>
							<div className={"col-12 col-xl-5" + (!appstore.menuActive ? ' col-lg-5' : '')}>
								<p className="theme">{appstore.getLanguageTrans("Choose a Theme")}</p>
								  {widgetStore.widgetDetails.themeChoosen != undefined &&
								  <ThemeSelectorComponent appstore={appstore} widgetStore={widgetStore} />}
							</div>
						</div>
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullRight">
									<Button style={appstore.helpTourStarted ? {pointerEvents:"none"}:{}} className="btn_clr" variant="light" onClick={() => {widgetStore.selectedWidget = 0}}>
								    	<p>{appstore.getLanguageTrans("Cancel")}</p>
								    </Button>
								    <Button style={(appstore.tourcurrent_step==5 && appstore.helpTourStarted) ? {pointerEvents:'auto'} : appstore.helpTourStarted ? {pointerEvents:'none'} :{}} className="btn_save btn_inv_widget_tour" onClick={(event) => this.handleFormSubmit(event)}>
								    	<p>{appstore.getLanguageTrans("Save & Get code")}</p>
								    </Button>
								</div>
							</div>
						</div>
						{
							widgetStore.getCode && 
								<WidgetCodeComponent store = {widgetStore} appstore={appstore}/>
						}
					</div>
                     {widgetStore.saveLoader &&
						 <div className="adminloader">	 
						     <GridLoader 
									 css={override}
									 size={20}
									 marging={2} 
									 color={this.props.appstore.selectedColor.color}
									 // loading={this.responseStore.responsereload}
									 />		 
				        </div>
					 }
					{ widgetStore.errorPopup &&
					<WarningComponent show={widgetStore.errorPopup}
											store={widgetStore}  
											cancel_btn="OK"  
											closePopup={() => widgetStore.errorPopup = false}
											appStore = {appstore} />
				}
 
				{
					  widgetStore.widgetBuildertourStart && 
						<TourGuide 
						store={widgetStore}
						appStore = {appstore}
						tourState = {widgetStore.widgetBuildertourSteps}
						/>
						// <Tour
						// active={widgetStore.widgetBuildertourStart}
						// step={this.props.appstore.selectedtourStep}
						// onNext={(step) => step==8 ? (widgetStore.widgetBuildertourStart=false,appstore.navigate('/traffic')) : this.props.appstore.selectedtourStep=step}
						// // onBack={(step) => this.setState({tourStep: step})}
						// onCancel={() => {appstore.helpTourStarted = false;widgetStore.widgetBuildertourStart=false}}
						// steps = {appstore.tourstesps} />
				}
				{
				   widgetStore.validateChatbot_VA_Enable && 
					<ConfirmationComponent show={widgetStore.validateChatbot_VA_Enable}
					store={this.appstore}
					body_html="<p>You cannot enable both the Virtual Agent and the ChatBot at the same time..</p>"
					cancel_btn="Okay" singleButton
					callBack={() =>  widgetStore.validateChatbot_VA_Enable=false}
					closePopup={() => widgetStore.validateChatbot_VA_Enable=false}
					appStore = {appstore}/>
				}

				</div>
		)
	}
})
export default WidgetBuilderComponent;
