import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';


class ChatBotSettingsStore {


  constructor(appStore) {
    this.appStore = appStore;
    this.agentDetails = {
      "name": '',
      "file": "",
      "image": "",
      "selectedfile": "",
    };
    this.deleteImg = false;
    //this.getsettingsdata();
    this.dropDownOptions = [
      { value: 0, label: 'Disabled' },
      { value: 1, label: 'When web + mobile operators are offline' },
      { value: 2, label: 'When web operators are offline' },
      { value: 3, label: 'Always use ChatBot' }
    ];
    this.mode = this.dropDownOptions[0];
    this.prechat = false;
    this.realchat = false;
    this.defaultSectionClass = "customname";
    this.customname = false;
    this.customnameBegin = false;
    this.customlNameEnd = false;
    this.customEmailBegin = false;
    this.customEmailEnd = false;
    this.customPhoneBegin = false;
    this.customPhoneEnd = false;
    this.errormodel = false;
    this.userProfilePic = '';
    this.editor = null;
    this.scaleValue = 1;
    this.selectedImage = '';
    this.openCropper = false;
    this.fileUploadErrors = '';
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.imageCropModel = false;
    this.imageRef = null;
    this.imagewarning = false;
    this.warning_image_name = "";
    this.defaultSettings = {
      "name": 'ChatBot',
    };
  }

  cancelsettings() {
    this.deleteImg = false;
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.getsettingsdata();
  }

  savesettings(formData) {
    formData.append("name", this.agentDetails.name);   
    var pre = 1;
    var human = 1;
    if (!this.prechat) { pre = 0; } if (!this.realchat) { human = 0 }
    formData.append("skipPrechatFields", pre);
    formData.append("showChatWithHuman", human);
    formData.append("enableMode", this.mode.value);
    formData.append("image", this.agentDetails.image);
    formData.append("websiteUid", this.appStore.selectedWebisiteUid);  
    formData.append("deleteimage", this.deleteImg);

    axios({
      method: 'POST',
      url: this.appStore.url + 'chatBotSettingsSave.json',
      headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
      data: formData
    }).then(result => {
      //console.log(result.data);  
      this.deleteImg=false;
      this.cropimage = {
        src: null,
        crop: {
          unit: '%',
          width: 50,
          aspect: 1 / 1,
        },
        croppedImageUrl: null,
      }
      this.imageCropModel = false;
      this.imageRef = null;
      this.imagewarning = false;
      this.warning_image_name = "";
      //console.log(result.data);
      if (result.data == 1) {
        this.getsettingsdata();
      } else {
        this.errormodel = true;
      }
      this.appStore.global_errorcount=0;
    }).catch((error) => {
        // this.appStore.logout(); 
        this.deleteImg=false;
        this.cropimage = {
          src: null,
          crop: {
            unit: '%',
            width: 50,
            aspect: 1 / 1,
          },
          croppedImageUrl: null,
        }
        this.imageCropModel = false;
        this.imageRef = null;
        this.imagewarning = false;
        this.warning_image_name = "";
        console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){      
      }
    });
  }
 
  savedefualtsettings(data) {
    axios({
      method: 'POST',
      url: this.appStore.url + 'aiChatbotdefulatSettings.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: data
    }).then(result => {
      // console.log(result);
      this.getsettingsdata();
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      //console.log(error);
      // this.appStore.logout(); 
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }

  getsettingsdata() {
    this.deleteImg = false;
    this.pagenotLoaded = true;    
    axios({
      method: 'POST',
      url: this.appStore.url + 'aiChatbotSettings.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: {"websiteUid": this.appStore.selectedWebisiteUid }
    }).then(result => {
      //  console.log(result.data); 
      if(result.data.length==1){
        if(result.data.logoutstatus==1){
          this.appStore.logout();
        }
      }
        
      if (result.data.uid != null) {        
        var agentDetailed = {
          "name": result.data.ai_name,         
          "image": result.data.websiteSittings.image
        }
        this.agentDetails = agentDetailed;      
        var prechating = false;
        var livechat = false
        if (result.data.websiteSittings.ai_skipPrechatFields == 1) {
          prechating = true;
        }
        if (result.data.websiteSittings.ai_showChatWithHuman == 1) {
          livechat = true;
        }
      //  console.log(JSON.stringify(result.data.websiteSittings));
        this.prechat = prechating;
        this.realchat = livechat;
        let self = this;
        let x = self.dropDownOptions.filter(function (option) {
          //filtering defualt English language data first time based on account id 
          if (option.value == result.data.websiteSittings.ai_enableMode) {
            self.mode = option;
          }
        });

      } else {
        this.agentDetails = {
          "name": ''         
        };
        this.prechat = false;
        this.realchat = false;
        this.mode = this.dropDownOptions[0];
      }
      this.pagenotLoaded = false;
      this.appStore.global_errorcount=0;      
    }).catch((error) => {
      //  this.appStore.logout(); 
      this.pagenotLoaded = false;
      this.agentDetails = {
        "name": ''
      };
      this.prechat = false;
      this.realchat = false;
      this.mode = this.dropDownOptions[0];
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }
}

decorate(ChatBotSettingsStore, {  
  mode: observable,
  state: observable,
  agenteditDetails: observable,
  addata: observable,
  prechat: observable,
  realchat: observable,
  agentDetails: observable,
  customname: observable,
  customnameBegin: observable,
  customlNameEnd: observable,
  customEmailBegin: observable,
  customEmailEnd: observable,
  customPhoneBegin: observable,
  customPhoneEnd: observable,
  pagenotLoaded: observable,
  errormodel: observable,
  userProfilePic: observable,
  editor: observable,
  scaleValue: observable,
  selectedImage: observable,
  openCropper: observable,
  fileUploadErrors: observable,
  cropimage: observable,
  imageCropModel: observable,
  imageRef: observable,
  deleteImg: observable,
  imagewarning: observable,
  warning_image_name: observable,
  defaultSettings: observable
})
export default ChatBotSettingsStore;