import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../sharedFiles/ImagesURLs';
import RadioButtonComponent from '../../sharedComponents/radionButton2/RadioButton';
import CheckBoxComponent from '../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../sharedComponents/dropDown/dropDown';
import CheckBoxCustomComponent from '../../sharedComponents/checkBox_2/checkBoxCustom2';
import navigationStore from '../../sharedFiles/navigation-store';
import ConfirmationComponent from '../../sharedComponents/confirmationPopup/ConfirmationComponent';
import NewWindow from 'react-new-window';
import TrafficStore from './TrafficComponentStore';
import GeoMapModel from '../../sharedComponents/geoMapPopup/GeoMapModel';
import ReactPaginate from 'react-paginate';
import TourGuide from '../../sharedComponents/TourGuide';
// import Tour from "react-product-intro"; 
import ErrorMessageComponent from '../../sharedComponents/confirmationPopup/ErrorMessageComponent';

const TrafficComponent = observer(class TrafficComponent extends Component {

	constructor(props) {
		super(props);

		this.trafficStore = new TrafficStore();
		this.state = {
			offset: 0,
			totalVisitors: this.props.appstore.trafficVisitorStatusChanged,
			// tourStep:8,
		};

		// if (window.performance) {
		//   this.trafficStore.getAllvisitorsOnRefresh(this.props.appstore);
		// }
	}

	componentDidMount() {
		this.trafficStore.getAllvisitorsOnRefresh(this.props.appstore); 
		this.props.appstore.menuChanged = window.location.pathname;
		this.trafficStore.setWebsites(this.props.appstore);
		this.trafficStore.setOperatorsObject(this.props.appstore);
		this.filterTrafficMonitorData();

		if (this.props.appstore.helpTourStarted) {
			this.trafficStore.setTrafficTourStep(this.props.appstore);
			this.trafficStore.trafficTourStep = true;
		}
		else
			this.trafficStore.trafficTourStep = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.totalVisitors !== this.props.appstore.trafficVisitorStatusChanged) {
			this.setState({ totalVisitors: this.props.appstore.trafficVisitorStatusChanged })
			this.filterTrafficMonitorData();
		}

	}

	handleShowRows = (selectedOption) => {
		this.trafficStore.selectedRows = selectedOption;
		//this.trafficStore.start_offset = Math.ceil(1 * this.trafficStore.selectedRows.value);
		this.trafficStore.start_offset = 0;
		this.filterTrafficMonitorData();
	}

	handleWebsiteFilter = (selectedOption) => {
		this.trafficStore.selectedWebsite = selectedOption;
		this.trafficStore.start_offset = 0;
		this.filterTrafficMonitorData();
	}

	handlePagination = data => {
		let selected = data.selected;
		this.trafficStore.start_offset = Math.ceil(selected * this.trafficStore.selectedRows.value); //this.trafficStore.selectedRows=15 default
		this.filterTrafficMonitorData();
	};

 
	filterTrafficMonitorData = () => {
		let allvisitors = this.props.appstore.trafficVisitorStatus; 
		// allvisitors = [...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors,...allvisitors]
		const totalrow = this.trafficStore.selectedRows.value;
		const selectedWebsite="";
		if(this.trafficStore.selectedWebsite.website_uid){
		  selectedWebsite = this.trafficStore.selectedWebsite.website_uid.trim();
		}
		let websiteFilteredVisitors = [];
		if (allvisitors.length != 0) {
			if (selectedWebsite) {
				//if website is selected then filter for website
				for (let i in allvisitors) {
					if (allvisitors[i].website_uid === selectedWebsite)
						websiteFilteredVisitors.push(allvisitors[i]);
				}
				allvisitors = websiteFilteredVisitors;
			}

			//set number of page
			this.trafficStore.totalPages = Math.ceil(allvisitors.length / totalrow);
		} else
			this.trafficStore.totalPages = 0;
		//show list eqauls to show row selected
		this.trafficStore.trafficMonitorVisitor = allvisitors.slice(this.trafficStore.start_offset, this.trafficStore.start_offset + totalrow)

		for (let j = 0; j < this.trafficStore.trafficMonitorVisitor.length; j++) {
			const id = this.trafficStore.trafficMonitorVisitor[j].sourceStation;

			//increase timer by one second
			setInterval(() => {
				if(this.trafficStore.trafficMonitorVisitor.length!=0 && this.trafficStore.trafficMonitorVisitor.length>j){
					if (this.trafficStore.trafficMonitorVisitor[j]) {
					// 	try{
					// 	if(this.trafficStore.trafficMonitorVisitor[j].status=="Browsing" || this.trafficStore.trafficMonitorVisitor[j].status=="Auto-invited"
					// 	 || this.trafficStore.trafficMonitorVisitor[j].status=="Returned"){
					// 		let chattime = new Date();
					// 		var chatcurrenttime=Math.floor((chattime.getTime())/1000);
					// 		var chatcreationtime=this.trafficStore.trafficMonitorVisitor[j].creationTime+310;
					// 		if(chatcreationtime<=chatcurrenttime){
					// 			delete this.trafficStore.trafficMonitorVisitor[j];
					// 			return;
					// 		}
					// 	}
					// }catch(er){}

						this.trafficStore.trafficMonitorVisitor[j].time = this.trafficStore.timeSpentByVisitor(this.trafficStore.trafficMonitorVisitor[j])

						// if(document.getElementById("visitorArrivedtimer"+id) !=null) {
						// 	document.getElementById("visitorArrivedtimer"+id).innerHTML = this.trafficStore.trafficMonitorVisitor[j].time;
						// }
					}
				}
			}, 1000);

			if (this.trafficStore.trafficMonitorVisitor.length>=j + 1){
				if (this.trafficStore.trafficMonitorVisitor[j + 1]){
					if (this.trafficStore.trafficMonitorVisitor[j].creationTime > this.trafficStore.trafficMonitorVisitor[j + 1].creationTime) {
						// Swapping the elements. 

						let temp = this.trafficStore.trafficMonitorVisitor[j];
						this.trafficStore.trafficMonitorVisitor[j] = this.trafficStore.trafficMonitorVisitor[j + 1];
						this.trafficStore.trafficMonitorVisitor[j + 1] = temp;

						j = -1;
					}
				}
			}

		}
		this.trafficStore.trafficMonitorVisitor = [...this.trafficStore.trafficMonitorVisitor].reverse();
		// console.log(this.trafficStore.trafficMonitorVisitor)
		// alert("after")
	}

	handleInviteClick = (trafficVisitorSelected) => {

		if (!trafficVisitorSelected.accepted && !this.trafficStore.confirmInvite && (trafficVisitorSelected.status == 'Browsing' || trafficVisitorSelected.status == 'Refused invitation'))
			this.trafficStore.invite = true
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
	}
	restinvitepopupstatus = (appstore, websiteuid) => {
		if (appstore.trafficVisitorSelected.status == 'Refused invitation') {
			this.trafficStore.confirmInvite = false;
		}
	}


	render() {
		const { appstore } = this.props;
		var self = this;

		return (
			<React.Fragment>
			{
				this.trafficStore.trafficTourStep &&
				<TourGuide
					store={this.trafficStore}
					appStore={appstore}
					tourState={this.trafficStore.tourState}
				/>
					// <Tour
					// active={this.trafficStore.trafficTourStep}
					// step={this.state.tourStep}
					// onNext={(step) =>  this.setState({tourStep: step})}
					// onBack={(step) => this.setState({tourStep: step})}
					// onCancel={() => {appstore.helpTourStarted = false;this.trafficStore.trafficTourStep=false}}
					// steps = {appstore.tourstesps}
					// height={200} />
			}
			<div className="innercontent_wrapper w-100" key={appstore.trafficVisitorStatusChanged}>
 

				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.trafficPageIcon} alt="faq" className="img-fluid" />
					<h4 id="trafficTest123" className="text-left">Traffic Monitor</h4>
				</div>

				<div className="row traffic_wrapper">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<div className="row traffic_row_tour">
								<div className="col-12">
									<div className="row">
										<div className="col-12 col-sm-3">
											<div className="headerTitle">
												{/*<h4 className="text-left">Referrer</h4>*/}
											</div>
										</div>
										<div className="col-12 col-sm-9">
											<div className="dropDownWrapper">
												<div className="customDrpDwn">
													<span>{appstore.getLanguageTrans("Show")}</span>
													<DropDownComponent dropDownOptions={this.trafficStore.rowsToDisplay}
														store={this.trafficStore}
														callBack={(selectedOption) => this.handleShowRows(selectedOption)}
														selectedOption={this.trafficStore.selectedRows} />
													<DropDownComponent dropDownOptions={this.trafficStore.websites}
														store={this.trafficStore}
														key={Math.random()}
														callBack={(selectedOption) => this.handleWebsiteFilter(selectedOption)}
														selectedOption={this.trafficStore.selectedWebsite} />
												</div>
											</div>
										</div>
									</div>
									<div className="scroll_row row m-0">
										<div id="panelGroup" className="panelWrapper col-12 col-lg-12 p-0">
											<div className="row panelHeader align-items-center">
												<div className="col-6">
													<p>{appstore.getLanguageTrans("Page")}</p>
												</div>
												{(appstore.userInfo.websitesforOp && appstore.userInfo.websitesforOp.length > 1) &&
													<div className="col-2">
														<p>{appstore.getLanguageTrans("Website")}</p>
													</div>
												}
												<div className={(appstore.userInfo.websitesforOp && appstore.userInfo.websitesforOp.length > 1) ? "col-2" : "col-3"}>
													<p>{appstore.getLanguageTrans("Time")}</p>
												</div>
												<div className={(appstore.userInfo.websitesforOp && appstore.userInfo.websitesforOp.length > 1) ? "col-2" : "col-3"}>
													<p>{appstore.getLanguageTrans("Status")}</p>
												</div>
											</div>
											<div className="row">
												{
													(appstore.activetrafficIndex !== appstore.trafficVisitorStatus.length && appstore.trafficVisitorStatus.length != 0) &&
													<div className="col-12 formBtns">
														<div className="pullLeft">
															<Button className={"btn_clr " + ((appstore.userInfo.usersmodel.max_chats != 0 && appstore.maxconcurrentchats >= appstore.userInfo.usersmodel.max_chats) ? 'disable disable-bg' : '') + (appstore.trafficVisitorSelected.accepted ? 'disable disable-bg' : '') + ((appstore.userInfo.usersmodel.max_chats != 0 && appstore.maxconcurrentchats >= appstore.userInfo.usersmodel.max_chats) ? 'disable disable-bg' : ((appstore.trafficVisitorSelected.status != 'Browsing' && appstore.trafficVisitorSelected.status != 'Refused invitation') || (appstore.trafficVisitorSelected.op_invite && appstore.trafficVisitorSelected.reinvite)) ? 'disabled disable-bg' : this.trafficStore.confirmInvite ? 'disabled disable-bg' : '')} variant="light" onClick={() => self.handleInviteClick(appstore.trafficVisitorSelected)}>
																<p>{appstore.getLanguageTrans("Invite")}</p>
															</Button>
															<Button className={"btn_clr " + ((appstore.userInfo.usersmodel.max_chats != 0 && appstore.maxconcurrentchats >= appstore.userInfo.usersmodel.max_chats) ? 'disable disable-bg' : '') + (appstore.trafficVisitorSelected.accepted ? 'disable disable-bg' : '') + (((appstore.trafficVisitorSelected.status != 'Browsing' && appstore.trafficVisitorSelected.status != 'Refused invitation') || (appstore.trafficVisitorSelected.op_invite && appstore.trafficVisitorSelected.reinvite)) ? 'disabled disable-bg' : !this.trafficStore.confirmInvite ? 'disabled disable-bg' : '')} variant="light" onClick={() => this.trafficStore.confirmInvite = false}>
																<p>{appstore.getLanguageTrans("cancel")}</p>
															</Button>
															<Button className={"btn_clr " + (((appstore.userInfo.usersmodel.max_chats != 0 && appstore.maxconcurrentchats >= appstore.userInfo.usersmodel.max_chats) || (appstore.userInfo.accountsmodel.monthly_chats_limit!=0 && appstore.monthly_chat_enable_count>=appstore.userInfo.accountsmodel.monthly_chats_limit)) ? 'disable disable-bg' : '') + (appstore.trafficVisitorSelected.status == "Waiting in queue" ? '' : 'disabled disable-bg')} style={{ pointerEvents: appstore.trafficVisitorSelected.status == "Waiting in queue" ? (appstore.userInfo.accountsmodel.monthly_chats_limit!=0 && appstore.monthly_chat_enable_count>=appstore.userInfo.accountsmodel.monthly_chats_limit) ? 'none' : 'auto' : 'none' }} onClick={() => appstore.acceptChatRequest(appstore.trafficVisitorSelected.sourceStation)} variant="light">
																<p>{appstore.getLanguageTrans("Pick up")}</p>
															</Button>
															<Button className={"btn_clr " + ((appstore.trafficVisitorSelected.status == "chat" && appstore.trafficVisitorSelected.accepted_opuid == this.props.appstore.userInfo.usersmodel.user_uid) ? '' : 'disabled disable-bg')} style={{ pointerEvents: appstore.trafficVisitorSelected.status == "chat" ? 'auto' : 'none' }} onClick={() => appstore.continueOngoingChats()} variant="light">
																<p>{appstore.getLanguageTrans("Continue Chat")}</p>
															</Button>
														</div>
													</div>
												}
											</div>
											<div className="scrollWrapper row m-0">
												<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
													<div className="col-12 scriptPanel">
														{
															this.trafficStore.trafficMonitorVisitor.map(function (data, dataIndex) {
																// console.log(data)
																if (data.status != "removeVisitor" && data.status != "hideVisitor")
																	return (
																		<div className={"row action panelContent cursor-pointer align-items-center traffic_row " + (appstore.activetrafficIndex < dataIndex ? ((dataIndex + self.trafficStore.rowLength) % 2 === 0 ? 'even' : 'odd') : (dataIndex % 2 === 0 ? 'even' : 'odd'))} key={dataIndex} >
																			<div className="dropdown-toggle col-12" 
																				onClick={() => { appstore.setTrafficIndex(data, dataIndex); self.restinvitepopupstatus(appstore, data.website_uid) }} >
																				<div className={"row parent_toggle" + (appstore.activetrafficIndex === dataIndex ? ' active' : '')}>
																					<div className="col-6 collapse_wrapper with-image">
																						{data.child.length>0 &&
																						<p className={ "col-1 iconshow collapsed"}  data-toggle="collapse"
																							data-target={"#referrer" + dataIndex} aria-expanded="false" aria-controls={"referrer" + dataIndex}></p>
																						}
																						{data.child.length==0 &&
																						<p className="col-1 "></p>
																						}																						
																						<p className="col-11 text-left">
																							<div className="row">
																								<div className="col-12">
																									<div className="row link">
																										<div className="col pr-0">
																											<img src={self.trafficStore.getBrowserIcon(appstore, data)} classNmae="img-fluid" style={{ marginRight: 7 }} />
																											{data.address && 
																											<img title={(data.address ? data.address.ipAddress : "null") + " ," + (data.address ? data.address.city : "null")} src={appstore.otherIcons + "op/images/cc/" + (data.address ? data.address.iso_code : "") + ".png"} onClick={() => self.trafficStore.getAddressforIp(data)} className="img-fluid" />	
																											}																											
																											<img onClick={() => self.trafficStore.openWindow = true} style={{ marginRight: -16 }} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.boxlink_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.boxlink_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.boxlink_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.boxlink_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.boxlink_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.boxlink_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.boxlink_voilet :
																												"#039be6" == appstore.selectedColor.color ? imageURLs.boxlink_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.boxlink_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.boxlink_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.boxlink_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.boxlink_skyblue : imageURLs.boxlink_steelblue} className="img-fluid" />
																										</div>

																										<div className="col" title={data.website + "::" + data.page} style={{ paddingLeft: 30 }}>
																											<a onClick={() => self.trafficStore.openWindow = !self.trafficStore.openWindow}>{data.page ? data.page : 'Untitled Page'}</a>
																										</div>

																									</div>
																								</div>
																							</div>

																						</p>
																					</div>
																					{self.trafficStore.openWindow &&
																						<div key={self.trafficStore.openWindow}>
																							<NewWindow
																								center
																								url={data.website}
																								name={"www.google.com"}
																								title={data.page}
																							>
																							</NewWindow>
																						</div>
																					}
																					{(appstore.userInfo.websitesforOp && appstore.userInfo.websitesforOp.length > 1) &&
																						<div className="col-2">
																							<p className="text-left">{self.trafficStore.allwebsiteObject[data.website_uid]} </p>
																						</div>
																					}
																					<div className={(appstore.userInfo.websitesforOp && appstore.userInfo.websitesforOp.length > 1) ? "col-2" : "col-3"}>
																						<p id={"visitorArrivedtimer" + data.sourceStation} className="text-left">{data.time} {data.undersupervision} </p>
																					</div>
																					<div className={(appstore.userInfo.websitesforOp && appstore.userInfo.websitesforOp.length > 1) ? "col-2" : "col-3"}>

																						<p className="text-left">
																							{data.status!='Waiting in queue' && (data.acceptedBy && data.acceptedBy !== appstore.userInfo.usersmodel.user_uid && appstore.isAdmin && appstore.userInfo.accountplan.supervision==1) &&
																								// <img title={appstore.getLanguageTrans("View this chat as a supervisor")} style={{ opacity: (data.undersupervision ? 0.3 : 1) }} onClick={() => { self.trafficStore.getChatforSupervision(data, appstore) }} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.eye_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.eye_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.eye_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.eye_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.eye_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.eye_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.eye_voilet :
																								// 	"#039be6" == appstore.selectedColor.color ? imageURLs.eye_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.eye_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.eye_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.eye_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.eye_skyblue : imageURLs.eye_steelblue} className="link_img img-fluid" />
																								<img title={appstore.getLanguageTrans("View this chat as a supervisor")} style={{ opacity: (self.trafficStore.ChatforSupervisionhide(data, appstore) ? 0 : 1) }} onClick={() => self.trafficStore.ChatforSupervisionhide(data, appstore) ? {} : self.trafficStore.getChatforSupervision(data, appstore)} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.eye_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.eye_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.eye_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.eye_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.eye_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.eye_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.eye_voilet :
																									"#039be6" == appstore.selectedColor.color ? imageURLs.eye_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.eye_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.eye_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.eye_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.eye_skyblue : imageURLs.eye_steelblue} className="link_img img-fluid" />
																							}
																							{/* {" "+ data.status + (data.acceptedBy.trim() && Object.keys(self.trafficStore.operatorsObject).length != 0 ? "ting with " + self.trafficStore.getOperatorName(data.acceptedBy) : "")} */}
																							{" "+ (data.status=='Waiting in queue'? data.status : data.status=='chat' ? "" :data.status=='chatted' ? "Chatted" :data.status) + (data.status!="chatted" && data.acceptedBy.trim() && Object.keys(self.trafficStore.operatorsObject).length != 0 && data.status!='Waiting in queue' ? "Chatting with "+ self.trafficStore.getOperatorName(data.acceptedBy) : "")}
																						</p>
																					</div>
																				</div>
																				{/* <div id={"referrer" + dataIndex} className="child_wrapper collapse row p-0" data-parent="#panelGroup" > */}
																				<div id={"referrer" + dataIndex} className="child_wrapper collapse p-0" data-parent="#panelGroup" >
																					{
																						data.child.map(function (link, linkIndex) {
																							return (
																								<div className="row">
																								<div className="col-12 link_row" key={linkIndex}>
																									<div className="row">
																										<div className="col-6 with-image">
																											<p className="col-1"></p>
																											<p className="col-11 text-left">
																												<div className="row">
																													<div className="col-12">
																														<div className="row link">
																															<div className="col pr-0">
																																<img onClick={() => self.trafficStore.openchildWindow = !self.trafficStore.openchildWindow} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.boxlink_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.boxlink_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.boxlink_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.boxlink_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.boxlink_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.boxlink_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.boxlink_voilet :
																																	"#039be6" == appstore.selectedColor.color ? imageURLs.boxlink_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.boxlink_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.boxlink_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.boxlink_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.boxlink_skyblue : imageURLs.boxlink_steelblue} className="img-fluid child-img" />
																															</div>
																															<div className="col pl-0">
																																<a onClick={() => self.trafficStore.openchildWindow = !self.trafficStore.openchildWindow} title={link.link} >{link.page}</a>
																															</div>
																														</div>
																													</div>
																												</div>
																											</p>
																										</div>
																										<div className="col-2">
																											<p className="text-left"> </p>
																										</div>
																										<div className="col-2">
																											<p className="text-left">{link.time} </p>
																										</div>
																										<div className="col-2">
																										</div>
																									</div>
																									{self.trafficStore.openchildWindow &&
																										<div key={self.trafficStore.openchildWindow}>
																											<NewWindow
																												center
																												url={link.link}
																												name={"www.google.com"}
																												title={link.page}
																											>
																											</NewWindow>
																										</div>
																									}
																								</div>
																								</div>
																							)
																						})
																					}
																				</div>
																			</div>

																		</div>
																	);
															})
														}
													</div>
												</CustomScroll>
											</div>


										</div>
									</div>
								</div>
							</div>
							{this.trafficStore.totalPages != 0 &&
								<div className="row" id="paginationRow">
									<div className="col-lg-4 col-sm-2"></div>
									<div className="col-lg-4 col-sm-6 traffic_pagination">
										{
											<ReactPaginate
												previousLabel={'previous'}
												nextLabel={'next'}
												breakLabel={'...'}
												breakClassName={'break-me'}
												pageCount={this.trafficStore.totalPages}
												marginPagesDisplayed={2}
												pageRangeDisplayed={5}
												onPageChange={this.handlePagination}
												containerClassName={'pagination'}
												subContainerClassName={'pages pagination'}
												activeClassName={'active'}
											/>
										}
									</div>
								</div>
							}
						</div>

					</div>
				</div>
				{
					this.trafficStore.invite &&
					<ConfirmationComponent show={this.trafficStore.invite}
						store={this.trafficStore}
						title="Invited Selected Visitor"
						body_html="<p>Are you sure you want to invite the selected vistor?</p>"
						cancel_btn="Cancel"
						save_btn="Invite"
						callBack={() => this.trafficStore.confirmAction(appstore)}
						closePopup={() => this.trafficStore.invite = false}
						appStore={appstore} />
				}
				{
					this.trafficStore.errorMessageComponent &&
					<ErrorMessageComponent show={this.trafficStore.errorMessageComponent}
						store={this.trafficStore}
						title={"<p style='color:red'>Error Message</p>"}
						body_html={"<p>"+appstore.getLanguageTrans("OOPS! something went wrong.!")+"</p>"}
						cancel_btn="Ok" 
						// callBack={(event) => this.confirmDeleteRule(event)}
						closePopup={() => {this.trafficStore.errorMessageComponent = false;}}
						appStore={appstore} />
				}

				{
					this.trafficStore.showgeoMap &&
					<GeoMapModel appStore={appstore} store={this.trafficStore} show={this.trafficStore.showgeoMap} />
				}

			</div>
			</React.Fragment>
		)
	}
})
export default TrafficComponent;
