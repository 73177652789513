import { decorate, observable, extendObservable } from 'mobx';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState } from 'draft-js';
import axios from 'axios';
import imageURLs from '../../../../sharedFiles/ImagesURLs';


class WidgetStore {

  constructor() {
    this.toobaredit = false;
    this.widgetBuildertourStart = false;
    this.widgettourStart = false;
    this.selectedWidget = 0;
    this.websiteWidgetDetails = [];
    this.getImageFromLibrary = false;
    this.pageisLoaded = false;
    this.deptDrpDwnOptions = [{ "label": "All Departments", "value": "", "department_uid": "" }];
    this.widgetCode = "";
    this.uid = "";
    this.codeCopied = false;
    this.validateChatbot_VA_Enable=false;
    this.operatorDrpDwnOptions = [{ "label": "All Operators", "value": "", "user_uid": "" }];

    this.lanDropDownOptions = [];
    this.saveLoader = false;
    this.errorPopup = false;
    this.topBarColors = [
      { value: 'black', label: 'Black', colorCode: '#000000' },
      { value: 'blue', label: 'Blue', colorCode: '#0481B0' },
      { value: 'green', label: 'Green', colorCode: '#608706' },
      { value: 'orange', label: 'Orange', colorCode: '#D6680B' },
      { value: 'pink', label: 'Pink', colorCode: '#B0097D' },
      { value: 'red', label: 'Red', colorCode: '#9D0A09' },
      { value: 'teal', label: 'Teal', colorCode: '#434E55' },
      { value: 'custom', label: 'Custom', colorCode: '#000000' }
    ];

    this.topBarTextColors = [
      { value: 'black', label: 'Black', colorCode: '#000000' },
      { value: 'white', label: 'White', colorCode: '#FFFFFF' },
      { value: 'custom', label: 'Custom', colorCode: '#000000' }
    ];

    this.textFonts = [
      { value: 1, label: 'Arial, Helvetica, sans-serif' },
      { value: 2, label: '"Arial Black", Gadget, sans-serif' },
      { value: 3, label: '"Comic Sans MS", sans-serif' },
      { value: 4, label: '"Lucida Sans Unicode", "Lucida Grande", sans-serif' },
      { value: 5, label: 'Tahoma, Geneva, sans-serif' },
      { value: 6, label: '"Trebuchet MS", Helvetica, sans-serif' },
      { value: 7, label: 'Verdana, Geneva, sans-serif' }
    ];

    this.directLinkDetails = {
      installationName: "Direct Link",
      department: this.deptDrpDwnOptions[0],
      operator: this.operatorDrpDwnOptions[0],
      language: this.lanDropDownOptions[0],
      preChatEnabled: true,
      preChatToUsers: false,
      topBarColor: this.topBarColors[1],
      topBarColorCustom: this.topBarColors[7].colorCode,
      topBarTextColor: this.topBarTextColors[1],
      topBarTextColorCustom: this.topBarTextColors[2].colorCode,
      textFont: this.textFonts[0],
      enableVirtualAgent: false,
      enableChatBot: false,
    }

    this.topBarColorSelector = false;
    this.topBarTextColorSelector = false;

    this.bgColorSelector = false;
    this.textColorSelector = false;

    // this.widgetThemes = [
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/blue/preview.png', theme: 'blue' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/green/preview.png', theme: 'green' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/orange/preview.png', theme: 'orange' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/pink/preview.png', theme: 'pink' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/red/preview.png', theme: 'red' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/teal/preview.png', theme: 'teal' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/dark_blue/preview.png', theme: 'dark blue' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/dark_green/preview.png', theme: 'dark green' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/dark_orange/preview.png', theme: 'dark orange' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/dark_pink/preview.png', theme: 'dark pink' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/dark_red/preview.png', theme: 'dark red' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/dark_teal/preview.png', theme: 'dark teal' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/light_blue/preview.png', theme: 'light blue' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/light_green/preview.png', theme: 'light green' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/light_grey/preview.png', theme: 'light grey' },
    //   { image: 'http://traffic8.helponclick.com/v6.1-46/static/assist/themes/light_teal/preview.png', theme: 'light teal' }
    // ];

    this.widgetThemes = [];
    this.widgetDetails = {}

    // this.icons = [
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/10_online.gif", id: 1 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/10_offline.gif", id: 2 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/11_online.png", id: 3 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/11_offline.png", id: 4 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/12_online.png", id: 5 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/12_offline.png", id: 6 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/13_online.png", id: 7 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/13_offline.png", id: 8 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/14_online.png", id: 9 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/14_offline.png", id: 10 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/15_online.png", id: 11 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/15_offline.png", id: 12 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/16_online.png", id: 13 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/16_offline.png", id: 14 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/17_online.png", id: 15 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/17_offline.png", id: 16 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/18_online.gif", id: 17 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/18_offline.gif", id: 18 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/19_online.gif", id: 19 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/19_offline.gif", id: 20 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/20_online.gif", id: 21 },
    //   { image: "http://traffic8.helponclick.com/v6.1-46/static/assist/icons/20_offline.gif", id: 22 },
    // ]

    this.icons = [];

    this.iconDetails = {
      installationName: "Icon",
      department: this.deptDrpDwnOptions[0],
      operator: this.operatorDrpDwnOptions[0],
      language: this.lanDropDownOptions[0],
      preChatEnabled: true,
      preChatToUsers: false,
      offlineHidden: false,
      enableVirtualAgent: false,
      enableChatBot: false,
    }

    this.widgetIconDetails = {
      installationName: "Widget+Icon",
      department: this.deptDrpDwnOptions[0],
      operator: this.operatorDrpDwnOptions[0],
      language: this.lanDropDownOptions[0],
      preChatEnabled: true,
      preChatToUsers: false,
      offlineHidden: false,
      enableVirtualAgent: false,
      enableChatBot: false,
    }

    this.chatWindowOptions = [
      { value: 'default', label: 'Default' },
      { value: 'custom', label: 'Choose (height & width)' }
    ];

    this.toolBarGeneralSettings = {
      installationName: "Toolbar",
      department: this.deptDrpDwnOptions[0],
      operator: this.operatorDrpDwnOptions[0],
      language: this.lanDropDownOptions[0],
      chatWindowSize: this.chatWindowOptions[0].value,
      customChatWindowSize: {
        height: 400,
        width: 365
      },
      preChatEnabled: true,
      preChatToUsers: false,
      enableVirtualAgent: false,
      enableChatBot: false,
    }

    this.toolBarButtonIcons = [
      { value: 1, label: 'Default', id: "default" },
      { value: 2, label: 'None', id: "none" },
      { value: 3, label: 'From Library', id: "font" },
      { value: 4, label: 'Custom', id: "custom" }
    ];
    this.toolBarCustomButtonIcons = [
      { value: 2, label: 'None', id: "none" },
      { value: 3, label: 'From Library', id: "font" },
      { value: 4, label: 'Custom', id: "custom" }
    ];
    this.toolBarButtonWidth = [
      { value: 1, label: 'Auto', width: 0, id: "auto" },
      { value: 2, label: 'Custom', width: 0, id: "custom" }
    ];

    this.buttonSettingsTimes = [
      { value: 1, label: 'Always', id: "always" },
      { value: 2, label: 'Mon–Fri 09:00–17:00', id: "monFri" },
      { value: 3, label: 'Custom', id: "custom" }
    ];
    this.callScheduleText = [
      { value: 1, label: "We are available always" },
      { value: 2, label: "We are available Mon–Fri 09:00–17:00" },
      { value: 3, label: "We are available Mon–Wed 09:00–17:00, Thu–Fri 09:00–15:00" }
    ]

    this.fbOptions = [
      { value: 1, label: 'Get from settings' },
      { value: 2, label: 'Override' }
    ];

    this.toolBarPreviewButtonSettings = [
      {
        id: 1,
        type: "chat",
        label: 'Chat',
        show: true,
        hiddenOffline: false,
        caption: 'Live Chat With Us',
        toolTip: 'Live Chat With Us',
        iconType: this.toolBarButtonIcons[0],
        iconClass: 'HelpOnClick-Icon-chat',
        libraryIconClass: '',
        iconUrl: '',
        width: this.toolBarButtonWidth[0],
        defaultOpen: false,
        croppedImgUrl: ''

      },
      {
        id: 2,
        type: "email",
        label: 'Email',
        show: true,
        hiddenOffline: true,
        caption: 'Leave us a message',
        toolTip: 'Leave us a message',
        iconType: this.toolBarButtonIcons[0],
        iconClass: 'HelpOnClick-Icon-email',
        libraryIconClass: '',
        iconUrl: '',
        width: this.toolBarButtonWidth[0],
        defaultOpen: false,
        croppedImgUrl: ''

      },
      {
        id: 3,
        label: 'Call',
        show: false,
        //hiddenOffline: false,
        type: "call",
        caption: '',
        toolTip: 'Call me back',
        iconType: this.toolBarButtonIcons[0],
        iconClass: 'HelpOnClick-Icon-call',
        libraryIconClass: '',
        iconUrl: '',
        width: this.toolBarButtonWidth[0],
        skypeLogin: '',
        showWhen: this.buttonSettingsTimes[1],
        useCustomText: false,
        customText: this.callScheduleText[2].label,
        defaultOpen: false,
        croppedImgUrl: ''

      },
      {
        id: 4,
        type: "social_facebook",
        label: 'Facebook',
        show: false,
        // hiddenOffline: false,
        caption: '',
        toolTip: 'Like on Facebook',
        iconType: this.toolBarButtonIcons[0],
        iconClass: 'HelpOnClick-Icon-facebook',
        libraryIconClass: '',
        iconUrl: '',
        width: this.toolBarButtonWidth[0],
        faceBookId: this.fbOptions[0],
        fbid: '',
        defaultOpen: false,
        croppedImgUrl: ''

      },
      {
        id: 5,
        type: "social_twitter",
        label: 'Twitter',
        show: false,
        //hiddenOffline: false,
        caption: '',
        toolTip: 'Follow on Twitter',
        iconType: this.toolBarButtonIcons[0],
        iconClass: 'HelpOnClick-Icon-twitter',
        libraryIconClass: '',
        iconUrl: '',
        width: this.toolBarButtonWidth[0],
        widgetHtmlCode: '',
        accountId: '',
        widgetId: '',
        defaultOpen: false,
        croppedImgUrl: ''

      }
    ];
    this.resttoolBarPreviewButtonSettings = this.toolBarPreviewButtonSettings;

    this.btnContentTypes = [
      { value: 1, label: 'Text', data: '', id: "text" },
      { value: 2, label: 'Link to page', data: '', id: "url" },
      { value: 3, label: 'Inline frame', data: '', id: "link" }
    ]
    this.toolBarAddButton = {
      id: 6,
      label: '',
      show: false,
      hiddenOffline: false,
      caption: 'Custom',
      toolTip: '',
      iconType: this.toolBarCustomButtonIcons[0],
      iconClass: '',
      libraryIconClass: '',
      iconUrl: '',
      width: this.toolBarButtonWidth[0],
      contentType: this.btnContentTypes[0],
      defaultOpen: true,
      croppedImgUrl: ''

    }
    this.resttoolBarAddButton = this.toolBarAddButton;

    this.invitationTypes = [
      { value: 1, label: 'Pop-up with image and text', id: "popup" },
      { value: 2, label: 'Custom image', id: "customImage" }
    ];
    this.invitationImageTypes = [
      { value: 1, label: 'Default', id: "default" },
      { value: 2, label: 'Image URL (150×150)', id: "imgUrl" },
      { value: 3, label: 'None', id: "none" }

    ];
    this.invitationStyles = [
      { value: 1, label: 'Default', id: "default" },
      { value: 2, label: 'Custom', id: "custom" }
    ];

    this.toolBarInvitationSettings = {
      invitationType: this.invitationTypes[0].value,
      invitationImage: this.invitationImageTypes[0],
      url: '',
      customImageUrl: '',
      margin: 2,
      leftMargin: 20,
      styles: this.invitationStyles[0].value,
      bgColor: '#eeeeee',
      bgTextColor: '#272727',
      buttonBgColor: '#dddddd',
      buttonBgTextColor: '#272727',
      croppedCustomImgUrl: '',
      croppedInvUrl: ''
    }
    this.resttoolBarInvitationSettings = this.toolBarInvitationSettings;



    this.toolBarThickness = [
      { value: 1, label: 'Default', height: 50, id: "default" },
      { value: 2, label: 'Small', height: 30, id: "small" },
      { value: 3, label: 'Custom', height: 50, id: "custom" }
    ];
    this.toolTipStyle = [
      { value: 1, label: 'Use toolbar settings' },
      { value: 2, label: 'Use custom settings' }
    ];

    this.toolBarStyleSettings_bgColorerror = false;
    this.toolBarStyleSettings_bgtextColorerror = false;
    this.toolBartooltip_bgColorerror = false;
    this.toolBartooltip_bgtextColorerror = false;
    this.inv_bgColorerror = false;
    this.inv_bgtextColorerror = false;
    this.inv_tootltipbgColorerror = false;
    this.inv_tootltipbgtxtColorerror = false;
    this.toolBarStyleSettings = {
      bgColor: this.topBarColors[1],
      bgColorCustom: this.topBarColors[7].colorCode,
      textColor: this.topBarTextColors[1],
      textColorCustom: this.topBarTextColors[2].colorCode,
      gradient: false,
      roundedCorners: true,
      barThickness: this.toolBarThickness[0],
      textFont: this.textFonts[0],
      toolTipSettings: this.toolTipStyle[0]
    }

    this.toolBarToolTipStyleSettings = {
      bgColor: this.topBarColors[1],
      bgColorCustom: this.topBarColors[7].colorCode,
      textColor: this.topBarTextColors[1],
      textColorCustom: this.topBarTextColors[2].colorCode,
      gradient: false,
      roundedCorners: true,
    }

    this.toolBarPreviewSettings = {
      position: "right bottom",
      orientation: "horizontal",
      thickness: 50,
      bgColor: this.toolBarStyleSettings.bgColor.colorCode,
      borderRadius: this.toolBarStyleSettings.roundedCorners ? 5 : 0,
      textColor: this.toolBarStyleSettings.textColor.colorCode,
      font: this.textFonts[0].label,
      gradient: this.toolBarStyleSettings.gradient,
      bottom: 0,
      top: 'auto',
      left: 'auto',
      right: 0,
      margin: 0,
      marginValue: 0,
      toolBarHeight: this.toolBarStyleSettings.barThickness.height,
      toolBarWidth: 'fit-content',
      toolTip: {
        bgColor: this.toolBarToolTipStyleSettings.bgColor.colorCode,
        borderRadius: this.toolBarToolTipStyleSettings.roundedCorners ? 5 : 0,
        textColor: this.toolBarToolTipStyleSettings.textColor.colorCode,
        gradient: false,
      }
    }



    this.selectedToolBarSetting = 1;
    this.iconScroll = 0;
    this.iconLibraryFiles = [
      { value: 1, icon: "glass" },
      { value: 2, icon: "music" },
      { value: 3, icon: "search" },
      { value: 4, icon: "envelope-o" },
      { value: 5, icon: "heart" },
      { value: 6, icon: "star" },
      { value: 7, icon: "star-o" },
      { value: 8, icon: "user" },
      { value: 9, icon: "film" },
      { value: 10, icon: "th-large" },
      { value: 11, icon: "th" },
      { value: 12, icon: "th-list" },
      { value: 13, icon: "check" },
      { value: 14, icon: "remove" },
      { value: 15, icon: "close" },
      { value: 16, icon: "times" },
      { value: 17, icon: "search-plus" },
      { value: 18, icon: "search-minus" },
      { value: 19, icon: "power-off" },
      { value: 20, icon: "signal" },
      { value: 21, icon: "gear" },
      { value: 22, icon: "cog" },
      { value: 23, icon: "trash-o" },
      { value: 24, icon: "home" },
      { value: 25, icon: "file-o" },
      { value: 26, icon: "clock-o" },
      { value: 27, icon: "road" },
      { value: 28, icon: "download" },
      { value: 29, icon: "arrow-circle-o-down" },
      { value: 30, icon: "arrow-circle-o-up" },
      { value: 31, icon: "inbox" },
      { value: 32, icon: "play-circle-o" },
      { value: 33, icon: "rotate-right" },
      { value: 34, icon: "repeat" },
      { value: 35, icon: "refresh" },
      { value: 36, icon: "list-alt" },
      { value: 37, icon: "lock" },
      { value: 38, icon: "flag" },
      { value: 39, icon: "headphones" },
      { value: 40, icon: "volume-off" },
      { value: 41, icon: "volume-down" },
      { value: 42, icon: "volume-up" },
      { value: 43, icon: "qrcode" },
      { value: 44, icon: "barcode" },
      { value: 45, icon: "tag" },
      { value: 46, icon: "tags" },
      { value: 47, icon: "book" },
      { value: 48, icon: "bookmark" },
      { value: 49, icon: "print" },
      { value: 50, icon: "camera" },
      { value: 51, icon: "font" },
      { value: 52, icon: "bold" },
      { value: 53, icon: "italic" },
      { value: 54, icon: "text-height" },
      { value: 55, icon: "text-width" },
      { value: 56, icon: "align-left" },
      { value: 57, icon: "align-center" },
      { value: 58, icon: "align-right" },
      { value: 59, icon: "align-justify" },
      { value: 60, icon: "list" },
      { value: 61, icon: "dedent" },
      { value: 62, icon: "outdent" },
      { value: 63, icon: "indent" },
      { value: 64, icon: "video-camera" },
      { value: 65, icon: "photo" },
      { value: 66, icon: "image" },
      { value: 67, icon: "picture-o" },
      { value: 68, icon: "pencil" },
      { value: 69, icon: "map-marker" },
      { value: 70, icon: "adjust" },
      { value: 71, icon: "tint" },
      { value: 72, icon: "edit" },
      { value: 73, icon: "pencil-square-o" },
      { value: 74, icon: "share-square-o" },
      { value: 75, icon: "check-square-o" },
      { value: 76, icon: "arrows" },
      { value: 77, icon: "step-backward" },
      { value: 78, icon: "fast-backward" },
      { value: 79, icon: "backward" },
      { value: 80, icon: "play" },
      { value: 81, icon: "pause" },
      { value: 82, icon: "stop" },
      { value: 83, icon: "forward" },
      { value: 84, icon: "fast-forward" },
      { value: 85, icon: "step-forward" },
      { value: 86, icon: "eject" },
      { value: 87, icon: "chevron-left" },
      { value: 88, icon: "chevron-right" },
      { value: 89, icon: "plus-circle" },
      { value: 90, icon: "minus-circle" },
      { value: 91, icon: "times-circle" },
      { value: 92, icon: "check-circle" },
      { value: 93, icon: "question-circle" },
      { value: 94, icon: "info-circle" },
      { value: 95, icon: "crosshairs" },
      { value: 96, icon: "times-circle-o" },
      { value: 97, icon: "check-circle-o" },
      { value: 98, icon: "ban" },
      { value: 99, icon: "arrow-left" },
      { value: 100, icon: "arrow-right" },
      { value: 101, icon: "arrow-up" },
      { value: 102, icon: "arrow-down" },
      { value: 103, icon: "mail-forward" },
      { value: 104, icon: "share" },
      { value: 105, icon: "expand" },
      { value: 106, icon: "compress" },
      { value: 107, icon: "plus" },
      { value: 108, icon: "minus" },
      { value: 109, icon: "asterisk" },
      { value: 110, icon: "exclamation-circle" },
      { value: 111, icon: "gift" },
      { value: 112, icon: "leaf" },
      { value: 113, icon: "fire" },
      { value: 114, icon: "eye" },
      { value: 115, icon: "eye-slash" },
      { value: 116, icon: "warning" },
      { value: 117, icon: "exclamation-triangle" },
      { value: 118, icon: "plane" },
      { value: 119, icon: "calendar" },
      { value: 120, icon: "random" },
      { value: 121, icon: "comment" },
      { value: 122, icon: "magnet" },
      { value: 123, icon: "chevron-up" },
      { value: 124, icon: "chevron-down" },
      { value: 125, icon: "retweet" },
      { value: 126, icon: "shopping-cart" },
      { value: 127, icon: "folder" },
      { value: 128, icon: "folder-open" },
      { value: 129, icon: "arrows-v" },
      { value: 130, icon: "arrows-h" },
      { value: 131, icon: "bar-chart-o" },
      { value: 132, icon: "bar-chart" },
      { value: 133, icon: "twitter-square" },
      { value: 134, icon: "facebook-square" },
      { value: 135, icon: "camera-retro" },
      { value: 136, icon: "key" },
      { value: 137, icon: "gears" },
      { value: 138, icon: "cogs" },
      { value: 139, icon: "comments" },
      { value: 140, icon: "thumbs-o-up" },
      { value: 141, icon: "thumbs-o-down" },
      { value: 142, icon: "star-half" },
      { value: 143, icon: "heart-o" },
      { value: 144, icon: "sign-out" },
      { value: 145, icon: "linkedin-square" },
      { value: 146, icon: "thumb-tack" },
      { value: 147, icon: "external-link" },
      { value: 148, icon: "sign-in" },
      { value: 149, icon: "trophy" },
      { value: 150, icon: "github-square" },
      { value: 151, icon: "upload" },
      { value: 152, icon: "lemon-o" },
      { value: 153, icon: "phone" },
      { value: 154, icon: "square-o" },
      { value: 155, icon: "bookmark-o" },
      { value: 156, icon: "phone-square" },
      { value: 157, icon: "twitter" },
      { value: 158, icon: "facebook" },
      { value: 159, icon: "github" },
      { value: 160, icon: "unlock" },
      { value: 161, icon: "credit-card" },
      { value: 162, icon: "rss" },
      { value: 163, icon: "hdd-o" },
      { value: 164, icon: "bullhorn" },
      { value: 165, icon: "bell" },
      { value: 166, icon: "certificate" },
      { value: 167, icon: "hand-o-right" },
      { value: 168, icon: "hand-o-left" },
      { value: 169, icon: "hand-o-up" },
      { value: 170, icon: "hand-o-down" },
      { value: 171, icon: "arrow-circle-left" },
      { value: 172, icon: "arrow-circle-right" },
      { value: 173, icon: "arrow-circle-up" },
      { value: 174, icon: "arrow-circle-down" },
      { value: 175, icon: "globe" },
      { value: 176, icon: "wrench" },
      { value: 177, icon: "tasks" },
      { value: 178, icon: "filter" },
      { value: 179, icon: "briefcase" },
      { value: 180, icon: "arrows-alt" },
      { value: 181, icon: "group" },
      { value: 182, icon: "users" },
      { value: 183, icon: "chain" },
      { value: 184, icon: "link" },
      { value: 185, icon: "cloud" },
      { value: 186, icon: "flask" },
      { value: 187, icon: "cut" },
      { value: 188, icon: "scissors" },
      { value: 189, icon: "copy" },
      { value: 190, icon: "files-o" },
      { value: 191, icon: "paperclip" },
      { value: 192, icon: "save" },
      { value: 193, icon: "floppy-o" },
      { value: 194, icon: "square" },
      { value: 195, icon: "navicon" },
      { value: 196, icon: "reorder" },
      { value: 197, icon: "bars" },
      { value: 198, icon: "list-ul" },
      { value: 199, icon: "list-ol" },
      { value: 200, icon: "strikethrough" },
      { value: 201, icon: "underline" },
      { value: 202, icon: "table" },
      { value: 203, icon: "magic" },
      { value: 204, icon: "truck" },
      { value: 205, icon: "pinterest" },
      { value: 206, icon: "pinterest-square" },
      { value: 207, icon: "google-plus-square" },
      { value: 208, icon: "google-plus" },
      { value: 209, icon: "money" },
      { value: 210, icon: "caret-down" },
      { value: 211, icon: "caret-up" },
      { value: 212, icon: "caret-left" },
      { value: 213, icon: "caret-right" },
      { value: 214, icon: "columns" },
      { value: 215, icon: "unsorted" },
      { value: 216, icon: "sort" },
      { value: 217, icon: "sort-down" },
      { value: 218, icon: "sort-desc" },
      { value: 219, icon: "sort-up" },
      { value: 220, icon: "sort-asc" },
      { value: 221, icon: "envelope" },
      { value: 222, icon: "linkedin" },
      { value: 223, icon: "rotate-left" },
      { value: 224, icon: "undo" },
      { value: 225, icon: "legal" },
      { value: 226, icon: "gavel" },
      { value: 227, icon: "dashboard" },
      { value: 228, icon: "tachometer" },
      { value: 229, icon: "comment-o" },
      { value: 230, icon: "comments-o" },
      { value: 231, icon: "flash" },
      { value: 232, icon: "bolt" },
      { value: 233, icon: "sitemap" },
      { value: 234, icon: "umbrella" },
      { value: 235, icon: "paste" },
      { value: 236, icon: "clipboard" },
      { value: 237, icon: "lightbulb-o" },
      { value: 238, icon: "exchange" },
      { value: 239, icon: "cloud-download" },
      { value: 240, icon: "cloud-upload" },
      { value: 241, icon: "user-md" },
      { value: 242, icon: "stethoscope" },
      { value: 243, icon: "suitcase" },
      { value: 244, icon: "bell-o" },
      { value: 245, icon: "coffee" },
      { value: 246, icon: "cutlery" },
      { value: 247, icon: "file-text-o" },
      { value: 248, icon: "building-o" },
      { value: 249, icon: "hospital-o" },
      { value: 250, icon: "ambulance" },
      { value: 251, icon: "medkit" },
      { value: 252, icon: "fighter-jet" },
      { value: 253, icon: "beer" },
      { value: 254, icon: "h-square" },
      { value: 255, icon: "plus-square" },
      { value: 256, icon: "angle-double-left" },
      { value: 257, icon: "angle-double-right" },
      { value: 258, icon: "angle-double-up" },
      { value: 259, icon: "angle-double-down" },
      { value: 260, icon: "angle-left" },
      { value: 261, icon: "angle-right" },
      { value: 262, icon: "angle-up" },
      { value: 263, icon: "angle-down" },
      { value: 264, icon: "desktop" },
      { value: 265, icon: "laptop" },
      { value: 266, icon: "tablet" },
      { value: 267, icon: "mobile-phone" },
      { value: 268, icon: "mobile" },
      { value: 269, icon: "circle-o" },
      { value: 270, icon: "quote-left" },
      { value: 271, icon: "quote-right" },
      { value: 272, icon: "spinner" },
      { value: 273, icon: "circle" },
      { value: 274, icon: "mail-reply" },
      { value: 275, icon: "reply" },
      { value: 276, icon: "github-alt" },
      { value: 277, icon: "folder-o" },
      { value: 278, icon: "folder-open-o" },
      { value: 279, icon: "smile-o" },
      { value: 280, icon: "frown-o" },
      { value: 281, icon: "meh-o" },
      { value: 282, icon: "gamepad" },
      { value: 283, icon: "keyboard-o" },
      { value: 284, icon: "flag-o" },
      { value: 285, icon: "flag-checkered" },
      { value: 286, icon: "terminal" },
      { value: 287, icon: "code" },
      { value: 288, icon: "mail-reply-all" },
      { value: 289, icon: "reply-all" },
      { value: 290, icon: "star-half-empty" },
      { value: 291, icon: "star-half-full" },
      { value: 292, icon: "star-half-o" },
      { value: 293, icon: "location-arrow" },
      { value: 294, icon: "crop" },
      { value: 295, icon: "code-fork" },
      { value: 296, icon: "unlink" },
      { value: 297, icon: "chain-broken" },
      { value: 298, icon: "question" },
      { value: 299, icon: "info" },
      { value: 300, icon: "exclamation" },
      { value: 301, icon: "superscript" },
      { value: 302, icon: "subscript" },
      { value: 303, icon: "eraser" },
      { value: 304, icon: "puzzle-piece" },
      { value: 305, icon: "microphone" },
      { value: 306, icon: "microphone-slash" },
      { value: 307, icon: "shield" },
      { value: 308, icon: "calendar-o" },
      { value: 309, icon: "fire-extinguisher" },
      { value: 310, icon: "rocket" },
      { value: 311, icon: "maxcdn" },
      { value: 312, icon: "chevron-circle-left" },
      { value: 313, icon: "chevron-circle-right" },
      { value: 314, icon: "chevron-circle-up" },
      { value: 315, icon: "chevron-circle-down" },
      { value: 316, icon: "html5" },
      { value: 317, icon: "css3" },
      { value: 318, icon: "anchor" },
      { value: 319, icon: "unlock-alt" },
      { value: 320, icon: "bullseye" },
      { value: 321, icon: "ellipsis-h" },
      { value: 322, icon: "ellipsis-v" },
      { value: 323, icon: "rss-square" },
      { value: 324, icon: "play-circle" },
      { value: 325, icon: "ticket" },
      { value: 326, icon: "minus-square" },
      { value: 327, icon: "minus-square-o" },
      { value: 328, icon: "level-up" },
      { value: 329, icon: "level-down" },
      { value: 330, icon: "check-square" },
      { value: 331, icon: "pencil-square" },
      { value: 332, icon: "external-link-square" },
      { value: 333, icon: "share-square" },
      { value: 334, icon: "compass" },
      { value: 335, icon: "toggle-down" },
      { value: 336, icon: "caret-square-o-down" },
      { value: 337, icon: "toggle-up" },
      { value: 338, icon: "caret-square-o-up" },
      { value: 339, icon: "toggle-right" },
      { value: 340, icon: "caret-square-o-right" },
      { value: 341, icon: "euro" },
      { value: 342, icon: "eur" },
      { value: 343, icon: "gbp" },
      { value: 344, icon: "dollar" },
      { value: 345, icon: "usd" },
      { value: 346, icon: "rupee" },
      { value: 347, icon: "inr" },
      { value: 348, icon: "cny" },
      { value: 349, icon: "rmb" },
      { value: 350, icon: "yen" },
      { value: 351, icon: "jpy" },
      { value: 352, icon: "ruble" },
      { value: 353, icon: "rouble" },
      { value: 354, icon: "rub" },
      { value: 355, icon: "won" },
      { value: 356, icon: "krw" },
      { value: 357, icon: "bitcoin" },
      { value: 358, icon: "btc" },
      { value: 359, icon: "file" },
      { value: 360, icon: "file-text" },
      { value: 361, icon: "sort-alpha-asc" },
      { value: 362, icon: "sort-alpha-desc" },
      { value: 363, icon: "sort-amount-asc" },
      { value: 364, icon: "sort-amount-desc" },
      { value: 365, icon: "sort-numeric-asc" },
      { value: 366, icon: "sort-numeric-desc" },
      { value: 367, icon: "thumbs-up" },
      { value: 368, icon: "thumbs-down" },
      { value: 369, icon: "youtube-square" },
      { value: 370, icon: "youtube" },
      { value: 371, icon: "xing" },
      { value: 372, icon: "xing-square" },
      { value: 373, icon: "youtube-play" },
      { value: 374, icon: "dropbox" },
      { value: 375, icon: "stack-overflow" },
      { value: 376, icon: "instagram" },
      { value: 377, icon: "flickr" },
      { value: 378, icon: "adn" },
      { value: 379, icon: "bitbucket" },
      { value: 380, icon: "bitbucket-square" },
      { value: 381, icon: "tumblr" },
      { value: 382, icon: "tumblr-square" },
      { value: 383, icon: "long-arrow-down" },
      { value: 384, icon: "long-arrow-up" },
      { value: 385, icon: "long-arrow-left" },
      { value: 386, icon: "long-arrow-right" },
      { value: 387, icon: "apple" },
      { value: 388, icon: "windows" },
      { value: 389, icon: "android" },
      { value: 390, icon: "linux" },
      { value: 391, icon: "dribbble" },
      { value: 392, icon: "skype" },
      { value: 393, icon: "foursquare" },
      { value: 394, icon: "trello" },
      { value: 395, icon: "female" },
      { value: 396, icon: "male" },
      { value: 397, icon: "gittip" },
      { value: 398, icon: "sun-o" },
      { value: 399, icon: "moon-o" },
      { value: 400, icon: "archive" },
      { value: 401, icon: "bug" },
      { value: 402, icon: "vk" },
      { value: 403, icon: "weibo" },
      { value: 404, icon: "renren" },
      { value: 405, icon: "pagelines" },
      { value: 406, icon: "stack-exchange" },
      { value: 407, icon: "arrow-circle-o-right" },
      { value: 408, icon: "arrow-circle-o-left" },
      { value: 409, icon: "toggle-left" },
      { value: 410, icon: "caret-square-o-left" },
      { value: 411, icon: "dot-circle-o" },
      { value: 412, icon: "wheelchair" },
      { value: 413, icon: "vimeo-square" },
      { value: 414, icon: "turkish-lira" },
      { value: 415, icon: "try" },
      { value: 416, icon: "plus-square-o" },
      { value: 417, icon: "space-shuttle" },
      { value: 418, icon: "slack" },
      { value: 419, icon: "envelope-square" },
      { value: 420, icon: "wordpress" },
      { value: 421, icon: "openid" },
      { value: 422, icon: "institution" },
      { value: 423, icon: "bank" },
      { value: 424, icon: "university" },
      { value: 425, icon: "mortar-board" },
      { value: 426, icon: "graduation-cap" },
      { value: 427, icon: "yahoo" },
      { value: 428, icon: "google" },
      { value: 429, icon: "reddit" },
      { value: 430, icon: "reddit-square" },
      { value: 431, icon: "stumbleupon-circle" },
      { value: 432, icon: "stumbleupon" },
      { value: 433, icon: "delicious" },
      { value: 434, icon: "digg" },
      { value: 435, icon: "pied-piper" },
      { value: 436, icon: "pied-piper-alt" },
      { value: 437, icon: "drupal" },
      { value: 438, icon: "joomla" },
      { value: 439, icon: "language" },
      { value: 440, icon: "fax" },
      { value: 441, icon: "building" },
      { value: 442, icon: "child" },
      { value: 443, icon: "paw" },
      { value: 444, icon: "spoon" },
      { value: 445, icon: "cube" },
      { value: 446, icon: "cubes" },
      { value: 447, icon: "behance" },
      { value: 448, icon: "behance-square" },
      { value: 449, icon: "steam" },
      { value: 450, icon: "steam-square" },
      { value: 451, icon: "recycle" },
      { value: 452, icon: "automobile" },
      { value: 453, icon: "car" },
      { value: 454, icon: "cab" },
      { value: 455, icon: "taxi" },
      { value: 456, icon: "tree" },
      { value: 457, icon: "spotify" },
      { value: 458, icon: "deviantart" },
      { value: 459, icon: "soundcloud" },
      { value: 460, icon: "database" },
      { value: 461, icon: "file-pdf-o" },
      { value: 462, icon: "file-word-o" },
      { value: 463, icon: "file-excel-o" },
      { value: 464, icon: "file-powerpoint-o" },
      { value: 465, icon: "file-photo-o" },
      { value: 466, icon: "file-picture-o" },
      { value: 467, icon: "file-image-o" },
      { value: 468, icon: "file-zip-o" },
      { value: 469, icon: "file-archive-o" },
      { value: 470, icon: "file-sound-o" },
      { value: 471, icon: "file-audio-o" },
      { value: 472, icon: "file-movie-o" },
      { value: 473, icon: "file-video-o" },
      { value: 474, icon: "file-code-o" },
      { value: 475, icon: "vine" },
      { value: 476, icon: "codepen" },
      { value: 477, icon: "jsfiddle" },
      { value: 478, icon: "life-bouy" },
      { value: 479, icon: "life-buoy" },
      { value: 480, icon: "life-saver" },
      { value: 481, icon: "support" },
      { value: 482, icon: "life-ring" },
      { value: 483, icon: "circle-o-notch" },
      { value: 484, icon: "ra" },
      { value: 485, icon: "rebel" },
      { value: 486, icon: "ge" },
      { value: 487, icon: "empire" },
      { value: 488, icon: "git-square" },
      { value: 489, icon: "git" },
      { value: 490, icon: "hacker-news" },
      { value: 491, icon: "tencent-weibo" },
      { value: 492, icon: "qq" },
      { value: 493, icon: "wechat" },
      { value: 494, icon: "weixin" },
      { value: 495, icon: "send" },
      { value: 496, icon: "paper-plane" },
      { value: 497, icon: "send-o" },
      { value: 498, icon: "paper-plane-o" },
      { value: 499, icon: "history" },
      { value: 500, icon: "circle-thin" },
      { value: 501, icon: "header" },
      { value: 502, icon: "paragraph" },
      { value: 503, icon: "sliders" },
      { value: 504, icon: "share-alt" },
      { value: 505, icon: "share-alt-square" },
      { value: 506, icon: "bomb" },
      { value: 507, icon: "soccer-ball-o" },
      { value: 508, icon: "futbol-o" },
      { value: 509, icon: "tty" },
      { value: 510, icon: "binoculars" },
      { value: 511, icon: "plug" },
      { value: 512, icon: "slideshare" },
      { value: 513, icon: "twitch" },
      { value: 514, icon: "yelp" },
      { value: 515, icon: "newspaper-o" },
      { value: 516, icon: "wifi" },
      { value: 517, icon: "calculator" },
      { value: 518, icon: "paypal" },
      { value: 519, icon: "google-wallet" },
      { value: 520, icon: "cc-visa" },
      { value: 521, icon: "cc-mastercard" },
      { value: 522, icon: "cc-discover" },
      { value: 523, icon: "cc-amex" },
      { value: 524, icon: "cc-paypal" },
      { value: 525, icon: "cc-stripe" },
      { value: 526, icon: "bell-slash" },
      { value: 527, icon: "bell-slash-o" },
      { value: 528, icon: "trash" },
      { value: 529, icon: "copyright" },
      { value: 530, icon: "at" },
      { value: 531, icon: "eyedropper" },
      { value: 532, icon: "paint-brush" },
      { value: 533, icon: "birthday-cake" },
      { value: 534, icon: "area-chart" },
      { value: 535, icon: "pie-chart" },
      { value: 536, icon: "line-chart" },
      { value: 537, icon: "lastfm" },
      { value: 538, icon: "lastfm-square" },
      { value: 539, icon: "toggle-off" },
      { value: 540, icon: "toggle-on" },
      { value: 541, icon: "bicycle" },
      { value: 542, icon: "bus" },
      { value: 543, icon: "ioxhost" },
      { value: 544, icon: "angellist" },
      { value: 545, icon: "cc" },
      { value: 546, icon: "shekel" },
      { value: 547, icon: "sheqel" },
      { value: 548, icon: "ils" },
      { value: 549, icon: "meanpath" }];
    // this.invitationImages = [
    //   { value: 1, img: "//app.helponclick.com/files/resources/resourcesPreset/b67ccc7e-be3e-49a7-a885-2b0247090e68.100x100xfit.png" },
    //   { value: 2, img: "//app.helponclick.com/files/resources/resourcesPreset/4374d52d-3e7a-4cae-9c9e-c7b58be13e32.100x100xfit.png" },
    //   { value: 3, img: "http://app.helponclick.com/files/resources/resourcesPreset/559248a2-98d2-478b-a4eb-92d09e3813dc.100x100xfit.png" },
    //   { value: 4, img: "http://app.helponclick.com/files/resources/resourcesPreset/f37336e8-7707-4af9-bf75-95cd01b5e06a.100x100xfit.png" },
    //   { value: 5, img: "http://app.helponclick.com/files/resources/resourcesPreset/aac005ab-8643-49dc-aa24-6163619b9ede.100x100xfit.png" },
    //   { value: 6, img: "http://app.helponclick.com/files/resources/resourcesPreset/282cd7e0-e014-45f3-be44-cdd17e5039af.100x100xfit.png" },
    //   { value: 7, img: "http://app.helponclick.com/files/resources/resourcesPreset/761c5a5e-2887-45a8-8e3a-a5463a395493.100x100xfit.png" },
    //   { value: 8, img: "http://app.helponclick.com/files/resources/resourcesPreset/3fe02015-95a6-41ce-8277-65078be20ab7.100x100xfit.png" },

    // ]
    this.invitationImages = [];


    this.imagewarning = false;
    this.warning_image_name = "";
    this.selectedofflinefile = "";
    this.selectedonlinefile = "";
    this.storedonlineFileName = "";
    this.storediconuid = "";
    this.storedofflineFileName = "";
    this.conformationpopup = false;
    this.confirm = false;
    this.icon_uid = "";
    this.id = "";
    this.mode = "";
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.imageCropModel = false;
    this.imageRef = null;
    this.deleteImg = false;
    this.iconnotLoaded = false;
    this.resourceid = "";
    this.chatresourceid = "";
    this.emailresourceid = "";
    this.callresourceid = "";
    this.faccebookresourceid = "";
    this.twitterresourceid = "";
    this.customeOneresourceid = "";
    this.customeTworesourceid = "";
    this.customeThreeresourceid = "";
    this.invitationimgUrl = "";
    this.invitationCustUrl = "";

    this.weekDays = [
      { label: 'Sunday', value: "sunday", selected: true, startTime: "", EndTime: "" },
      { label: 'Monday', value: "monday", selected: true, startTime: "", EndTime: "" },
      { label: 'Tuesday', value: "tuesday", selected: true, startTime: "", EndTime: "" },
      { label: 'Wednesday', value: "wednesday", selected: true, startTime: "", EndTime: "" },
      { label: 'Thursday', value: "thursday", selected: true, startTime: "", EndTime: "" },
      { label: 'Friday', value: "friday", selected: true, startTime: "", EndTime: "" },
      { label: 'Saturday', value: "saturday", selected: true, startTime: "", EndTime: "" },
    ];
    this.clearweekDays = [
      { label: 'Sunday', value: "sunday", selected: true, startTime: "", EndTime: "" },
      { label: 'Monday', value: "monday", selected: true, startTime: "", EndTime: "" },
      { label: 'Tuesday', value: "tuesday", selected: true, startTime: "", EndTime: "" },
      { label: 'Wednesday', value: "wednesday", selected: true, startTime: "", EndTime: "" },
      { label: 'Thursday', value: "thursday", selected: true, startTime: "", EndTime: "" },
      { label: 'Friday', value: "friday", selected: true, startTime: "", EndTime: "" },
      { label: 'Saturday', value: "saturday", selected: true, startTime: "", EndTime: "" },
    ];;
    this.customeschedule = false;
    this.customescheduleset = false;
    this.weekDaysschedule = {};
    this.clearweekDaysschedule = {};
    this.invitationprieviewimg = "";
    this.customselected = {
      onlineimage: "",
      offlineimage: ""
    }

    this.errorMessageComponent = false;
    this.errorMessage = "";
    this.toolBarPreviewButtonposition = "right bottom";
    this.toolBarFbButtonid = false;
    this.toolBarcustomButtonconform = false;
    this.toolBarcustomButton = {};
    this.colorpickerevent = "";
    this.ToolTipcolornew = "";
    this.ToolTiptextnew = "";
    this.bgcolornew = "";
    this.bgtextnew = "";
    this.widgetRedirectThemes = [
      { redirect_theme: "dark blue", theme: 'dark_blue' },
      { redirect_theme: "dark green", theme: 'dark_green' },
      { redirect_theme: "dark orange", theme: 'dark_orange' },
      { redirect_theme: "dark pink", theme: 'dark_pink' },
      { redirect_theme: "dark red", theme: 'dark_red' },
      { redirect_theme: "dark teal", theme: 'dark_teal' },
      { redirect_theme: "light blue", theme: 'light_blue' },
      { redirect_theme: "light green", theme: 'light_green' },
      { redirect_theme: "light teal", theme: 'light_teal' },
    ];
  }
  setcolorpickervalue = (color) => {
    //alert(color +"==="+this.colorpickerevent);
    if (this.colorpickerevent == "bgcolor") {
      this.toolBarStyleSettings.bgColor.colorCode = color;
      this.bgcolornew = color;
      this.toolBarStyleSettings.bgColorCustom = color;
      this.colorpickerevent = "";
    } else if (this.colorpickerevent == "bgtext") {
      this.toolBarStyleSettings.textColor.colorCode = color;
      this.toolBarStyleSettings.textColorCustom = color;
      this.colorpickerevent = "";
      this.bgtextnew = color;
    } else if (this.colorpickerevent == "tooltipcolor") {
      this.toolBarToolTipStyleSettings.bgColor.colorCode = color;
      this.toolBarToolTipStyleSettings.bgColorCustom = color;
      this.colorpickerevent = "";
      this.ToolTipcolornew = color;
    } else if (this.colorpickerevent == "tooltiptext") {
      this.toolBarToolTipStyleSettings.textColor.colorCode = color;
      this.toolBarToolTipStyleSettings.textColorCustom = color;
      this.colorpickerevent = "";
      this.ToolTiptextnew = color;
    }
  }

  checkhexapattern = (color, no) => {
    //return /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color);  
    var valid = /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{6})$/.test(color);
    if (no == 1) {
      this.toolBarStyleSettings_bgColorerror = valid ? false : true;
    }
    if (no == 2) {
      this.toolBarStyleSettings_bgtextColorerror = valid ? false : true;
    }
    if (no == 3) {
      this.toolBartooltip_bgColorerror = valid ? false : true;
    }
    if (no == 4) {
      this.toolBartooltip_bgtextColorerror = valid ? false : true;
    }
    if (no == 5) {
      this.inv_bgColorerror = valid ? false : true;
    }
    if (no == 6) {
      this.inv_bgtextColorerror = valid ? false : true;
    }
    if (no == 7) {
      this.inv_tootltipbgColorerror = valid ? false : true;
    }
    if (no == 8) {
      this.inv_tootltipbgtxtColorerror = valid ? false : true;
    }

  }
  loadCustomlibrary(appstore) {
    this.widgetThemes = [
      { image: appstore.imgpath + "themes/blue/preview.png", theme: 'blue' },
      { image: appstore.imgpath + "themes/green/preview.png", theme: 'green' },
      { image: appstore.imgpath + "themes/orange/preview.png", theme: 'orange' },
      { image: appstore.imgpath + "themes/pink/preview.png", theme: 'pink' },
      { image: appstore.imgpath + "themes/red/preview.png", theme: 'red' },
      { image: appstore.imgpath + "themes/teal/preview.png", theme: 'teal' },
      { image: appstore.imgpath + "themes/dark_blue/preview.png", theme: 'dark blue' },
      { image: appstore.imgpath + "themes/dark_green/preview.png", theme: 'dark green' },
      { image: appstore.imgpath + "themes/dark_orange/preview.png", theme: 'dark orange' },
      { image: appstore.imgpath + "themes/dark_pink/preview.png", theme: 'dark pink' },
      { image: appstore.imgpath + "themes/dark_red/preview.png", theme: 'dark red' },
      { image: appstore.imgpath + "themes/dark_teal/preview.png", theme: 'dark teal' },
      { image: appstore.imgpath + "themes/light_blue/preview.png", theme: 'light blue' },
      { image: appstore.imgpath + "themes/light_green/preview.png", theme: 'light green' },
      { image: appstore.imgpath + "themes/light_grey/preview.png", theme: 'light grey' },
      { image: appstore.imgpath + "themes/light_teal/preview.png", theme: 'light teal' }
    ];
    this.widgetDetails = {
      uid: "",
      accountUid: "",
      installationName: "Widget",
      department: this.deptDrpDwnOptions[0],
      operator: this.operatorDrpDwnOptions[0],
      language: this.lanDropDownOptions[0],
      preChatEnabled: true,
      preChatToUsers: false,
      showInvitation: false,
      offlineHidden: false,
      themeChoosen: this.widgetThemes[0],
      widgetPosition: 'Bottom Right',
      enableVirtualAgent: false,
      enableChatBot: false,
    }
    this.icons = [
      { image: appstore.imgpath + "icons/1_offline.png", id: 1 },
      { image: appstore.imgpath + "icons/1_online.png", id: 2 },
      { image: appstore.imgpath + "icons/2_offline.png", id: 3 },
      { image: appstore.imgpath + "icons/2_online.png", id: 4 },
      { image: appstore.imgpath + "icons/3_offline.png", id: 5 },
      { image: appstore.imgpath + "icons/3_online.png", id: 6 },
      { image: appstore.imgpath + "icons/4_offline.png", id: 7 },
      { image: appstore.imgpath + "icons/4_online.png", id: 8 },
      { image: appstore.imgpath + "icons/5_offline.gif", id: 9 },
      { image: appstore.imgpath + "icons/5_online.gif", id: 10 },
      { image: appstore.imgpath + "icons/6_offline.png", id: 11 },
      { image: appstore.imgpath + "icons/6_online.png", id: 12 },
      { image: appstore.imgpath + "icons/7_offline.png", id: 13 },
      { image: appstore.imgpath + "icons/7_online.png", id: 14 },
      { image: appstore.imgpath + "icons/8_offline.gif", id: 15 },
      { image: appstore.imgpath + "icons/8_online.gif", id: 16 },
      { image: appstore.imgpath + "icons/9_offline.gif", id: 17 },
      { image: appstore.imgpath + "icons/9_online.gif", id: 18 },
      { image: appstore.imgpath + "icons/10_offline.gif", id: 19 },
      { image: appstore.imgpath + "icons/10_online.gif", id: 20 },
      { image: appstore.imgpath + "icons/11_offline.png", id: 21 },
      { image: appstore.imgpath + "icons/11_online.png", id: 22 },
      { image: appstore.imgpath + "icons/12_offline.png", id: 23 },
      { image: appstore.imgpath + "icons/12_online.png", id: 24 },
      { image: appstore.imgpath + "icons/13_offline.png", id: 25 },
      { image: appstore.imgpath + "icons/13_online.png", id: 26 },
      { image: appstore.imgpath + "icons/14_offline.png", id: 27 },
      { image: appstore.imgpath + "icons/14_online.png", id: 28 },
      { image: appstore.imgpath + "icons/15_offline.png", id: 29 },
      { image: appstore.imgpath + "icons/15_online.png", id: 30 },
      { image: appstore.imgpath + "icons/16_offline.png", id: 31 },
      { image: appstore.imgpath + "icons/16_online.png", id: 32 },
      { image: appstore.imgpath + "icons/17_offline.png", id: 33 },
      { image: appstore.imgpath + "icons/17_online.png", id: 34 },
      { image: appstore.imgpath + "icons/18_offline.gif", id: 35 },
      { image: appstore.imgpath + "icons/18_online.gif", id: 36 },
      { image: appstore.imgpath + "icons/19_offline.gif", id: 37 },
      { image: appstore.imgpath + "icons/19_online.gif", id: 38 },
      { image: appstore.imgpath + "icons/20_offline.gif", id: 39 },
      { image: appstore.imgpath + "icons/20_online.gif", id: 40 },
      { image: appstore.imgpath + "icons/21_offline.gif", id: 41 },
      { image: appstore.imgpath + "icons/21_online.gif", id: 42 },
      { image: appstore.imgpath + "icons/22_offline.gif", id: 43 },
      { image: appstore.imgpath + "icons/22_online.gif", id: 44 },
      { image: appstore.imgpath + "icons/23_offline.png", id: 45 },
      { image: appstore.imgpath + "icons/23_online.png", id: 46 },
      { image: appstore.imgpath + "icons/24_offline.png", id: 47 },
      { image: appstore.imgpath + "icons/24_online.png", id: 48 },
      { image: appstore.imgpath + "icons/25_offline.png", id: 49 },
      { image: appstore.imgpath + "icons/25_online.png", id: 50 },
      { image: appstore.imgpath + "icons/26_offline.png", id: 51 },
      { image: appstore.imgpath + "icons/26_online.png", id: 52 },
      { image: appstore.imgpath + "icons/27_offline.png", id: 53 },
      { image: appstore.imgpath + "icons/27_online.png", id: 54 },
      { image: appstore.imgpath + "icons/28_offline.png", id: 55 },
      { image: appstore.imgpath + "icons/28_online.png", id: 56 },
    ];

    this.invitationImages = [
      { value: 1, img: appstore.resourceurl + "resourcesPreset/02d711e0-6429-424e-be8a-04544bf14dab.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/02d711e0-6429-424e-be8a-04544bf14dab.png" },
      { value: 2, img: appstore.resourceurl + "resourcesPreset/cf07c355-2d59-4fbb-b1b7-b5aa2b37f9f9.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/cf07c355-2d59-4fbb-b1b7-b5aa2b37f9f9.png" },
      { value: 3, img: appstore.resourceurl + "resourcesPreset/0d712896-e835-42f8-8bf4-e16fc06a8593.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0d712896-e835-42f8-8bf4-e16fc06a8593.png" },
      { value: 4, img: appstore.resourceurl + "resourcesPreset/d8e40b96-2ffb-4ad2-8566-babbd0451da9.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/d8e40b96-2ffb-4ad2-8566-babbd0451da9.png" },
      { value: 5, img: appstore.resourceurl + "resourcesPreset/8ce4224e-f864-4f17-99b3-706f858ebe30.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/8ce4224e-f864-4f17-99b3-706f858ebe30.png" },
      { value: 6, img: appstore.resourceurl + "resourcesPreset/bb1a6a97-2bc4-4f4f-a35d-f4450181c881.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/bb1a6a97-2bc4-4f4f-a35d-f4450181c881.png" },
      { value: 7, img: appstore.resourceurl + "resourcesPreset/bf9131a9-a954-491c-8cab-2ea9dbf8c171.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/bf9131a9-a954-491c-8cab-2ea9dbf8c171.png" },
      { value: 8, img: appstore.resourceurl + "resourcesPreset/00ab47c5-8d08-429d-be87-1f86c2fb78e7.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/00ab47c5-8d08-429d-be87-1f86c2fb78e7.png" },
      { value: 9, img: appstore.resourceurl + "resourcesPreset/be0390c2-a840-4fb1-9ab0-161d07b076ae.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/be0390c2-a840-4fb1-9ab0-161d07b076ae.png" },
      { value: 10, img: appstore.resourceurl + "resourcesPreset/b02a5005-6120-443d-8c63-a5d986e193b3.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b02a5005-6120-443d-8c63-a5d986e193b3.png" },
      { value: 11, img: appstore.resourceurl + "resourcesPreset/dea178f5-198b-4c4f-b86c-39c8f40c8600.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/dea178f5-198b-4c4f-b86c-39c8f40c8600.png" },
      { value: 12, img: appstore.resourceurl + "resourcesPreset/941e2cea-40ce-4e9b-956a-4bca6a54a714.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/941e2cea-40ce-4e9b-956a-4bca6a54a714.png" },
      { value: 13, img: appstore.resourceurl + "resourcesPreset/a106ec38-0008-4d01-b3fd-eeb4e77a5603.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/a106ec38-0008-4d01-b3fd-eeb4e77a5603.png" },
      { value: 14, img: appstore.resourceurl + "resourcesPreset/e33aa745-f201-48e4-8919-d7f7ac3a09c2.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e33aa745-f201-48e4-8919-d7f7ac3a09c2.png" },
      { value: 15, img: appstore.resourceurl + "resourcesPreset/9b6f1ef1-d985-406b-93e1-166f853385bc.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/9b6f1ef1-d985-406b-93e1-166f853385bc.png" },
      { value: 16, img: appstore.resourceurl + "resourcesPreset/53ed4f2d-dc7d-4210-aaed-2bf42ffbf054.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/53ed4f2d-dc7d-4210-aaed-2bf42ffbf054.png" },
      { value: 17, img: appstore.resourceurl + "resourcesPreset/3c921f1d-515b-4b06-a77d-f5204c817496.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/3c921f1d-515b-4b06-a77d-f5204c817496.png" },
      { value: 18, img: appstore.resourceurl + "resourcesPreset/33d32217-967f-40f0-ba46-2551f318b887.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/33d32217-967f-40f0-ba46-2551f318b887.png" },
      { value: 19, img: appstore.resourceurl + "resourcesPreset/e56718fb-5dd5-4f3c-9e52-53fae85fa9f1.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e56718fb-5dd5-4f3c-9e52-53fae85fa9f1.png" },
      { value: 20, img: appstore.resourceurl + "resourcesPreset/7ab5caee-84f1-44e3-8c56-0a8ab1a2e8a7.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/7ab5caee-84f1-44e3-8c56-0a8ab1a2e8a7.png" },
      { value: 21, img: appstore.resourceurl + "resourcesPreset/cd4c85b3-2cb3-4377-9e5f-435680db9406.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/cd4c85b3-2cb3-4377-9e5f-435680db9406.png" },
      { value: 22, img: appstore.resourceurl + "resourcesPreset/b1dffe55-bed2-4c51-aaa8-b01d15580c8d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b1dffe55-bed2-4c51-aaa8-b01d15580c8d.png" },
      { value: 23, img: appstore.resourceurl + "resourcesPreset/39b438c6-a633-40ba-a4b4-453be32b6ae3.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/39b438c6-a633-40ba-a4b4-453be32b6ae3.png" },
      { value: 24, img: appstore.resourceurl + "resourcesPreset/2f1cbdd9-2f70-4a39-bec1-85c4b25c9262.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/2f1cbdd9-2f70-4a39-bec1-85c4b25c9262.png" },
      { value: 25, img: appstore.resourceurl + "resourcesPreset/0d62893f-7d5f-4100-a2f4-32c6dc7ba8b6.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0d62893f-7d5f-4100-a2f4-32c6dc7ba8b6.png" },
      { value: 26, img: appstore.resourceurl + "resourcesPreset/e63d24c1-e577-4b4b-928e-18ba074bc526.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e63d24c1-e577-4b4b-928e-18ba074bc526.png" },
      { value: 27, img: appstore.resourceurl + "resourcesPreset/56c04d11-a9de-410e-88b1-6c31e8dbefd5.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/56c04d11-a9de-410e-88b1-6c31e8dbefd5.png" },
      { value: 28, img: appstore.resourceurl + "resourcesPreset/ec5803e8-4f3d-430c-9c6c-35ece887cbec.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/ec5803e8-4f3d-430c-9c6c-35ece887cbec.png" },
      { value: 29, img: appstore.resourceurl + "resourcesPreset/bb633426-1cd9-42fa-b14a-760bc757c21f.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/bb633426-1cd9-42fa-b14a-760bc757c21f.png" },
      { value: 30, img: appstore.resourceurl + "resourcesPreset/8d09d5e5-1a58-4806-9734-2ce551cf851c.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/8d09d5e5-1a58-4806-9734-2ce551cf851c.png" },
      { value: 31, img: appstore.resourceurl + "resourcesPreset/0574f1be-1104-4c35-886a-b11b19a6b55b.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0574f1be-1104-4c35-886a-b11b19a6b55b.png" },
      { value: 32, img: appstore.resourceurl + "resourcesPreset/0a6a9e5f-e741-4ce1-b56b-a1a1a30ce0b6.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0a6a9e5f-e741-4ce1-b56b-a1a1a30ce0b6.png" },
      { value: 33, img: appstore.resourceurl + "resourcesPreset/d9e513a7-3409-4c54-b2f3-d3af02b78a20.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/d9e513a7-3409-4c54-b2f3-d3af02b78a20.png" },
      { value: 34, img: appstore.resourceurl + "resourcesPreset/c0b460ce-f622-4f0f-8a70-dce2e928476c.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/c0b460ce-f622-4f0f-8a70-dce2e928476c.png" },
      { value: 35, img: appstore.resourceurl + "resourcesPreset/f536034d-07a6-45f4-bc9b-52b67af188af.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/f536034d-07a6-45f4-bc9b-52b67af188af.png" },
      { value: 36, img: appstore.resourceurl + "resourcesPreset/1d0e9fca-f355-485a-a787-c1bb67d8e1a9.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/1d0e9fca-f355-485a-a787-c1bb67d8e1a9.png" },
      { value: 37, img: appstore.resourceurl + "resourcesPreset/0fc9c315-9849-468b-9f2d-cd700e212bbf.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0fc9c315-9849-468b-9f2d-cd700e212bbf.png" },
      { value: 38, img: appstore.resourceurl + "resourcesPreset/bd7e8868-6a58-4402-aafb-1b1db2424220.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/bd7e8868-6a58-4402-aafb-1b1db2424220.png" },
      { value: 39, img: appstore.resourceurl + "resourcesPreset/431c5784-49e2-44fb-8c22-e63004968b92.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/431c5784-49e2-44fb-8c22-e63004968b92.png" },
      { value: 40, img: appstore.resourceurl + "resourcesPreset/0977925c-915f-420c-ae41-66dc91575f9d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0977925c-915f-420c-ae41-66dc91575f9d.png" },
      { value: 41, img: appstore.resourceurl + "resourcesPreset/1668eefd-6557-43c5-97d4-40f3ca9813c4.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/1668eefd-6557-43c5-97d4-40f3ca9813c4.png" },
      { value: 42, img: appstore.resourceurl + "resourcesPreset/8660c6c2-11df-4340-8bad-d164dc106138.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/8660c6c2-11df-4340-8bad-d164dc106138.png" },
      { value: 43, img: appstore.resourceurl + "resourcesPreset/eacc64ae-c634-4945-87a5-7666a23e8d64.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/eacc64ae-c634-4945-87a5-7666a23e8d64.png" },
      { value: 44, img: appstore.resourceurl + "resourcesPreset/8273aa2c-8b29-47e4-a7d1-e74a91a4747c.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/8273aa2c-8b29-47e4-a7d1-e74a91a4747c.png" },
      { value: 45, img: appstore.resourceurl + "resourcesPreset/b239d4e9-2568-4695-adee-5b7be4059edf.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b239d4e9-2568-4695-adee-5b7be4059edf.png" },
      { value: 46, img: appstore.resourceurl + "resourcesPreset/1c6a9203-130a-4d3f-819e-023fa9640e12.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/1c6a9203-130a-4d3f-819e-023fa9640e12.png" },
      { value: 47, img: appstore.resourceurl + "resourcesPreset/75a07a2a-b3cb-4677-a3b4-0670d42c3c90.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/75a07a2a-b3cb-4677-a3b4-0670d42c3c90.png" },
      { value: 48, img: appstore.resourceurl + "resourcesPreset/7beed0fa-0ce4-4a91-a315-b31fc2d43dda.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/7beed0fa-0ce4-4a91-a315-b31fc2d43dda.png" },
      { value: 49, img: appstore.resourceurl + "resourcesPreset/1d3f08af-b823-484b-81f6-24637e1088de.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/1d3f08af-b823-484b-81f6-24637e1088de.png" },
      { value: 50, img: appstore.resourceurl + "resourcesPreset/699c68cd-1341-44bd-8b13-a7a49bcc29b1.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/699c68cd-1341-44bd-8b13-a7a49bcc29b1.png" },
      { value: 51, img: appstore.resourceurl + "resourcesPreset/ab0ffe1f-125b-42a4-b26e-08703e8ce217.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/ab0ffe1f-125b-42a4-b26e-08703e8ce217.png" },
      { value: 52, img: appstore.resourceurl + "resourcesPreset/47917ac0-ee9f-4c99-acee-80d8c3b39192.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/47917ac0-ee9f-4c99-acee-80d8c3b39192.png" },
      { value: 53, img: appstore.resourceurl + "resourcesPreset/7c00604a-09cf-4879-939d-f34a24a7f2f3.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/7c00604a-09cf-4879-939d-f34a24a7f2f3.png" },
      { value: 54, img: appstore.resourceurl + "resourcesPreset/22f2d907-30cf-4305-997e-3ca32681cb19.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/22f2d907-30cf-4305-997e-3ca32681cb19.png" },
      { value: 55, img: appstore.resourceurl + "resourcesPreset/e52b5d4c-84a0-48b1-ae3c-7130a1cb4d63.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e52b5d4c-84a0-48b1-ae3c-7130a1cb4d63.png" },
      { value: 56, img: appstore.resourceurl + "resourcesPreset/06fa965c-a316-482a-9bc0-7cc0c772c787.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/06fa965c-a316-482a-9bc0-7cc0c772c787.png" },
      { value: 57, img: appstore.resourceurl + "resourcesPreset/a0bc538a-cee1-4523-9ef4-0d80b1769d6d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/a0bc538a-cee1-4523-9ef4-0d80b1769d6d.png" },
      { value: 58, img: appstore.resourceurl + "resourcesPreset/64a275c2-3ac2-41a0-86a9-0aac7ec093a9.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/64a275c2-3ac2-41a0-86a9-0aac7ec093a9.png" },
      { value: 59, img: appstore.resourceurl + "resourcesPreset/8c925aae-1cf2-4dbe-9687-79a31a50fe7e.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/8c925aae-1cf2-4dbe-9687-79a31a50fe7e.png" },
      { value: 60, img: appstore.resourceurl + "resourcesPreset/666ae23f-ecc1-45c9-9979-15dea4ad3f48.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/666ae23f-ecc1-45c9-9979-15dea4ad3f48.png" },
      { value: 61, img: appstore.resourceurl + "resourcesPreset/19bb7202-ec12-455b-9484-f764def995a8.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/19bb7202-ec12-455b-9484-f764def995a8.png" },
      { value: 62, img: appstore.resourceurl + "resourcesPreset/e998bc41-54eb-4308-825c-c787ce908108.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e998bc41-54eb-4308-825c-c787ce908108.png" },
      { value: 63, img: appstore.resourceurl + "resourcesPreset/faabaf9c-04e9-4752-b8a2-18a050dae645.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/faabaf9c-04e9-4752-b8a2-18a050dae645.png" },
      { value: 64, img: appstore.resourceurl + "resourcesPreset/9eea23eb-c79f-48dd-b38a-6c74db11a29f.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/9eea23eb-c79f-48dd-b38a-6c74db11a29f.png" },
      { value: 65, img: appstore.resourceurl + "resourcesPreset/98f30e73-ff2f-405e-bf25-b0acbd968d03.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/98f30e73-ff2f-405e-bf25-b0acbd968d03.png" },
      { value: 66, img: appstore.resourceurl + "resourcesPreset/9b11c6b9-f8c1-4f3a-b72b-61e1bc1e7b1b.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/9b11c6b9-f8c1-4f3a-b72b-61e1bc1e7b1b.png" },
      { value: 67, img: appstore.resourceurl + "resourcesPreset/86b9556d-9757-4602-ae02-a4ff4bbc6d4d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/86b9556d-9757-4602-ae02-a4ff4bbc6d4d.png" },
      { value: 68, img: appstore.resourceurl + "resourcesPreset/0560c174-7ab5-40b0-8430-9d6f0dafa199.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0560c174-7ab5-40b0-8430-9d6f0dafa199.png" },
      { value: 69, img: appstore.resourceurl + "resourcesPreset/9d1d4c3a-a2fb-4561-9293-fd061893ae0f.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/9d1d4c3a-a2fb-4561-9293-fd061893ae0f.png" },
      { value: 70, img: appstore.resourceurl + "resourcesPreset/e3867368-cbb7-4931-9c06-a67e32641ed5.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e3867368-cbb7-4931-9c06-a67e32641ed5.png" },
      { value: 71, img: appstore.resourceurl + "resourcesPreset/3d45a7a6-4fe4-43a9-bc1f-10bc5875a216.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/3d45a7a6-4fe4-43a9-bc1f-10bc5875a216.png" },
      { value: 72, img: appstore.resourceurl + "resourcesPreset/b925967b-f839-4059-b958-bb4ba6fb9f10.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b925967b-f839-4059-b958-bb4ba6fb9f10.png" },
      { value: 73, img: appstore.resourceurl + "resourcesPreset/48b0643a-6fa7-4610-af20-46f0dd782aa5.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/48b0643a-6fa7-4610-af20-46f0dd782aa5.png" },
      { value: 74, img: appstore.resourceurl + "resourcesPreset/0dd74927-8ddb-43ff-b30b-91c5b04736c1.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0dd74927-8ddb-43ff-b30b-91c5b04736c1.png" },
      { value: 75, img: appstore.resourceurl + "resourcesPreset/7daf627f-72e4-46dd-95df-e2319bb3219d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/7daf627f-72e4-46dd-95df-e2319bb3219d.png" },
      { value: 76, img: appstore.resourceurl + "resourcesPreset/b93affc6-83ae-4ba0-b357-4fdb5c6577ad.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b93affc6-83ae-4ba0-b357-4fdb5c6577ad.png" },
      { value: 77, img: appstore.resourceurl + "resourcesPreset/641fee3e-8002-4de7-9ec9-0c702241629e.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/641fee3e-8002-4de7-9ec9-0c702241629e.png" },
      { value: 78, img: appstore.resourceurl + "resourcesPreset/8f43d1c1-598b-49da-92e2-d9e3a2bd03e6.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/8f43d1c1-598b-49da-92e2-d9e3a2bd03e6.png" },
      { value: 79, img: appstore.resourceurl + "resourcesPreset/3142157f-5e17-4e42-8306-89673a156ade.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/3142157f-5e17-4e42-8306-89673a156ade.png" },
      { value: 80, img: appstore.resourceurl + "resourcesPreset/934da3c0-eec3-4ea1-8983-63effd024bbd.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/934da3c0-eec3-4ea1-8983-63effd024bbd.png" },
      { value: 81, img: appstore.resourceurl + "resourcesPreset/43238aea-66b2-4568-a83f-03cb1b5e1a41.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/43238aea-66b2-4568-a83f-03cb1b5e1a41.png" },
      { value: 82, img: appstore.resourceurl + "resourcesPreset/f5bc0033-9c57-4716-894e-7f6963955d90.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/f5bc0033-9c57-4716-894e-7f6963955d90.png" },
      { value: 83, img: appstore.resourceurl + "resourcesPreset/6e333601-5792-4cd7-bcfb-8c3da3eecd5a.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/6e333601-5792-4cd7-bcfb-8c3da3eecd5a.png" },
      { value: 84, img: appstore.resourceurl + "resourcesPreset/142316f9-6a6a-47bf-8177-b67b0527b8be.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/142316f9-6a6a-47bf-8177-b67b0527b8be.png" },
      { value: 85, img: appstore.resourceurl + "resourcesPreset/bed49364-2512-476f-bb9c-1f9314d9375a.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/bed49364-2512-476f-bb9c-1f9314d9375a.png" },
      { value: 86, img: appstore.resourceurl + "resourcesPreset/f9e5fe16-747b-4dce-90e5-34d0ea70c6cf.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/f9e5fe16-747b-4dce-90e5-34d0ea70c6cf.png" },
      { value: 87, img: appstore.resourceurl + "resourcesPreset/49dad026-b5d6-4b1e-9e4c-539bf6045ea0.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/49dad026-b5d6-4b1e-9e4c-539bf6045ea0.png" },
      { value: 88, img: appstore.resourceurl + "resourcesPreset/e2548001-ec83-4d7a-a4b7-74fe9277306e.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e2548001-ec83-4d7a-a4b7-74fe9277306e.png" },
      { value: 89, img: appstore.resourceurl + "resourcesPreset/b02ec098-45f3-41cd-9fa5-2a7361b73674.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b02ec098-45f3-41cd-9fa5-2a7361b73674.png" },
      { value: 90, img: appstore.resourceurl + "resourcesPreset/c388be83-be3a-4fdd-8d22-23f544746703.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/c388be83-be3a-4fdd-8d22-23f544746703.png" },
      { value: 91, img: appstore.resourceurl + "resourcesPreset/ab18ae7a-b64c-48bb-bced-cf5696434d3b.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/ab18ae7a-b64c-48bb-bced-cf5696434d3b.png" },
      { value: 92, img: appstore.resourceurl + "resourcesPreset/69fad056-9b58-4eca-baf2-87e872da14e4.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/69fad056-9b58-4eca-baf2-87e872da14e4.png" },
      { value: 93, img: appstore.resourceurl + "resourcesPreset/d02f7dee-9dbe-44b4-a17d-3b8eb1052e8e.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/d02f7dee-9dbe-44b4-a17d-3b8eb1052e8e.png" },
      { value: 94, img: appstore.resourceurl + "resourcesPreset/43ca5337-33e8-49b0-b913-58a6cdc48973.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/43ca5337-33e8-49b0-b913-58a6cdc48973.png" },
      { value: 95, img: appstore.resourceurl + "resourcesPreset/d3eda512-40fc-4105-8182-a89afd259e23.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/d3eda512-40fc-4105-8182-a89afd259e23.png" },
      { value: 96, img: appstore.resourceurl + "resourcesPreset/941d188f-bce8-472b-bd94-599b28827bce.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/941d188f-bce8-472b-bd94-599b28827bce.png" },
      { value: 97, img: appstore.resourceurl + "resourcesPreset/6690a933-78d5-458a-adf8-0ef206327377.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/6690a933-78d5-458a-adf8-0ef206327377.png" },
      { value: 98, img: appstore.resourceurl + "resourcesPreset/acaebacb-ddba-4d53-b376-1f67f81c397c.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/acaebacb-ddba-4d53-b376-1f67f81c397c.png" },
      { value: 99, img: appstore.resourceurl + "resourcesPreset/cadf013a-d828-442f-8fcd-0cdb21fff004.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/cadf013a-d828-442f-8fcd-0cdb21fff004.png" },
      { value: 100, img: appstore.resourceurl + "resourcesPreset/559493c6-7140-40e9-900b-3f71bf486c75.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/559493c6-7140-40e9-900b-3f71bf486c75.png" },
      { value: 101, img: appstore.resourceurl + "resourcesPreset/6b7b41ef-3d17-42dc-b44a-cccbd6d1c346.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/6b7b41ef-3d17-42dc-b44a-cccbd6d1c346.png" },
      { value: 102, img: appstore.resourceurl + "resourcesPreset/27e5e151-a2ee-4b55-93a7-e8750154c789.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/27e5e151-a2ee-4b55-93a7-e8750154c789.png" },
      { value: 103, img: appstore.resourceurl + "resourcesPreset/3d5e3bb3-8782-40c9-a3af-8e8f49732cac.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/3d5e3bb3-8782-40c9-a3af-8e8f49732cac.png" },
      { value: 104, img: appstore.resourceurl + "resourcesPreset/514fdf99-ff11-4c72-bb4b-fd91ab32562a.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/514fdf99-ff11-4c72-bb4b-fd91ab32562a.png" },
      { value: 105, img: appstore.resourceurl + "resourcesPreset/7c289064-a563-483c-8d2c-31177b472b2d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/7c289064-a563-483c-8d2c-31177b472b2d.png" },
      { value: 106, img: appstore.resourceurl + "resourcesPreset/b21acfaa-9159-490e-a7a4-712e990f6ea8.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b21acfaa-9159-490e-a7a4-712e990f6ea8.png" },
      { value: 107, img: appstore.resourceurl + "resourcesPreset/20458fce-8432-48ff-8326-2e5e5c0c9b3f.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/20458fce-8432-48ff-8326-2e5e5c0c9b3f.png" },
      { value: 108, img: appstore.resourceurl + "resourcesPreset/cadae489-8e3d-4a59-a68c-b3cf8a03ceed.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/cadae489-8e3d-4a59-a68c-b3cf8a03ceed.png" },
      { value: 109, img: appstore.resourceurl + "resourcesPreset/86aa04a5-c61b-42c2-9b44-07523f0ebd9a.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/86aa04a5-c61b-42c2-9b44-07523f0ebd9a.png" },
      { value: 110, img: appstore.resourceurl + "resourcesPreset/64fc60fa-de57-4dad-9ff3-77aeb71cc303.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/64fc60fa-de57-4dad-9ff3-77aeb71cc303.png" },
      { value: 111, img: appstore.resourceurl + "resourcesPreset/5c0c9011-8064-46a7-abb5-c15439418962.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/5c0c9011-8064-46a7-abb5-c15439418962.png" },
      { value: 112, img: appstore.resourceurl + "resourcesPreset/6cdbb264-784e-4adc-a6cc-9293e72a7418.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/6cdbb264-784e-4adc-a6cc-9293e72a7418.png" },
      { value: 113, img: appstore.resourceurl + "resourcesPreset/4bba2850-2e27-4cd3-aa5d-f8ccc1664f2b.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/4bba2850-2e27-4cd3-aa5d-f8ccc1664f2b.png" },
      { value: 114, img: appstore.resourceurl + "resourcesPreset/e4fa1673-593e-4f54-92bd-81715a37be61.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e4fa1673-593e-4f54-92bd-81715a37be61.png" },
      { value: 115, img: appstore.resourceurl + "resourcesPreset/c71fdef2-ed0e-4b95-ae32-8e9722a907ad.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/c71fdef2-ed0e-4b95-ae32-8e9722a907ad.png" },
      { value: 116, img: appstore.resourceurl + "resourcesPreset/0e876ea5-5814-43e9-9dc5-f8a65d719532.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/0e876ea5-5814-43e9-9dc5-f8a65d719532.png" },
      { value: 117, img: appstore.resourceurl + "resourcesPreset/fb2fb236-f02f-4d6d-a4af-8a516ca38c4d.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/fb2fb236-f02f-4d6d-a4af-8a516ca38c4d.png" },
      { value: 118, img: appstore.resourceurl + "resourcesPreset/cf0e8b0b-03e4-4137-a070-3a32c38b07c3.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/cf0e8b0b-03e4-4137-a070-3a32c38b07c3.png" },
      { value: 119, img: appstore.resourceurl + "resourcesPreset/e93d2c38-f407-4c0a-b652-5c86a4ea9e50.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/cf0e8b0b-03e4-4137-a070-3a32c38b07c3.png" },
      { value: 120, img: appstore.resourceurl + "resourcesPreset/55294bbd-bf9e-4350-8f14-45cd82835deb.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/55294bbd-bf9e-4350-8f14-45cd82835deb.png" },
      { value: 121, img: appstore.resourceurl + "resourcesPreset/4e1462d5-39f9-483e-ac7b-684b75b311b9.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/4e1462d5-39f9-483e-ac7b-684b75b311b9.png" },
      { value: 122, img: appstore.resourceurl + "resourcesPreset/e0d88aa6-2f17-4244-a354-3c6b03a59ed6.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e0d88aa6-2f17-4244-a354-3c6b03a59ed6.png" },
      { value: 123, img: appstore.resourceurl + "resourcesPreset/1e3e17c0-f4f9-4048-8c75-f92f118bdab1.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/1e3e17c0-f4f9-4048-8c75-f92f118bdab1.png" },
      { value: 124, img: appstore.resourceurl + "resourcesPreset/e3b51d42-f5ff-45b2-a60f-1fd01b06d4ff.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e3b51d42-f5ff-45b2-a60f-1fd01b06d4ff.png" },
      { value: 125, img: appstore.resourceurl + "resourcesPreset/37f029ea-2157-4ec4-b668-a13d0948d629.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/37f029ea-2157-4ec4-b668-a13d0948d629.png" },
      { value: 126, img: appstore.resourceurl + "resourcesPreset/87e574ef-a2ec-41e0-b0ca-be33d156dd94.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/87e574ef-a2ec-41e0-b0ca-be33d156dd94.png" },
      { value: 127, img: appstore.resourceurl + "resourcesPreset/e0717c4e-088e-4957-bf89-4468e7d55474.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/e0717c4e-088e-4957-bf89-4468e7d55474.png" },
      { value: 128, img: appstore.resourceurl + "resourcesPreset/799481f8-34e7-4c70-b11d-8c54fa43c8ad.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/799481f8-34e7-4c70-b11d-8c54fa43c8ad.png" },
      { value: 129, img: appstore.resourceurl + "resourcesPreset/3848ca56-ebdc-40f1-83fb-ec76e8349eeb.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/3848ca56-ebdc-40f1-83fb-ec76e8349eeb.png" },
      { value: 130, img: appstore.resourceurl + "resourcesPreset/493e0e3a-bd01-4b4b-9dba-2c51d8ef2c06.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/493e0e3a-bd01-4b4b-9dba-2c51d8ef2c06.png" },
      { value: 131, img: appstore.resourceurl + "resourcesPreset/a960a4d6-822f-4166-b9d1-c50f33c3fcca.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/a960a4d6-822f-4166-b9d1-c50f33c3fcca.png" },
      { value: 132, img: appstore.resourceurl + "resourcesPreset/209565e8-e031-4ed5-9199-53497113534b.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/209565e8-e031-4ed5-9199-53497113534b.png" },
      { value: 133, img: appstore.resourceurl + "resourcesPreset/db363e58-2cc5-44cb-8d8b-8ccceefa8724.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/db363e58-2cc5-44cb-8d8b-8ccceefa8724.png" },
      { value: 134, img: appstore.resourceurl + "resourcesPreset/23655a6b-5b64-48aa-836e-315e421d6331.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/23655a6b-5b64-48aa-836e-315e421d6331.png" },
      { value: 135, img: appstore.resourceurl + "resourcesPreset/2fb2be5f-12e3-46b3-9095-b4060428baf7.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/2fb2be5f-12e3-46b3-9095-b4060428baf7.png" },
      { value: 136, img: appstore.resourceurl + "resourcesPreset/4d828644-86f7-4201-8b9e-49658179a0a6.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/4d828644-86f7-4201-8b9e-49658179a0a6.png" },
      { value: 137, img: appstore.resourceurl + "resourcesPreset/3fe02015-95a6-41ce-8277-65078be20ab7.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/3fe02015-95a6-41ce-8277-65078be20ab7.png" },
      { value: 138, img: appstore.resourceurl + "resourcesPreset/761c5a5e-2887-45a8-8e3a-a5463a395493.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/761c5a5e-2887-45a8-8e3a-a5463a395493.png" },
      { value: 139, img: appstore.resourceurl + "resourcesPreset/282cd7e0-e014-45f3-be44-cdd17e5039af.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/282cd7e0-e014-45f3-be44-cdd17e5039af.png" },
      { value: 140, img: appstore.resourceurl + "resourcesPreset/aac005ab-8643-49dc-aa24-6163619b9ede.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/aac005ab-8643-49dc-aa24-6163619b9ede.png" },
      { value: 141, img: appstore.resourceurl + "resourcesPreset/f37336e8-7707-4af9-bf75-95cd01b5e06a.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/f37336e8-7707-4af9-bf75-95cd01b5e06a.png" },
      { value: 142, img: appstore.resourceurl + "resourcesPreset/559248a2-98d2-478b-a4eb-92d09e3813dc.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/559248a2-98d2-478b-a4eb-92d09e3813dc.png" },
      { value: 143, img: appstore.resourceurl + "resourcesPreset/4374d52d-3e7a-4cae-9c9e-c7b58be13e32.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/4374d52d-3e7a-4cae-9c9e-c7b58be13e32.png" },
      { value: 144, img: appstore.resourceurl + "resourcesPreset/b67ccc7e-be3e-49a7-a885-2b0247090e68.100x100xfit.png", value: appstore.resourceurl + "resourcesPreset/b67ccc7e-be3e-49a7-a885-2b0247090e68.png" },

    ]
  }


  setwidgetTourStep(appStore) {
    this.tourState = {
      steps: [
        {
          target: '.addwidget_tour',
          content: appStore.getLanguageTrans("Here you can select type for your website. Let's setup a widget."),
          disableBeacon: true,
          title: "HelpOnClik Guide",
          placement: "top",
          stepNumber: 4,
          offset: -10,
        },
      ],
      stepIndex: 0,
      overlaydisplay: false,
      isHomePage: false,
      isWebsitePage: false,
      isWidgetPage: true,
    };
  }

  setwidgetBuildertourSteps = (appStore) => {
    this.widgetBuildertourSteps = {
      steps: [

        {
          target: '.installation_namewidget_tour',
          // content: appStore.getLanguageTrans('Enter installation name, turn on pre-chat fields and select a color for your widget. Then click Save and Get Code button'),
          content: appStore.getLanguageTrans('Enter installation name, turn on pre-chat fields and select a color for your widget.'),
          disableBeacon: true,
          title: "HelpOnClik Guide",
          placement: "bottom",
          stepNumber: 5,
          offset: -10,
        },

        {
          target: '.btn_inv_widget_tour',
          content: appStore.getLanguageTrans('Seems that all setup is done now. Click on the “Save & Get Code” button.'),
          disableBeacon: true,
          title: "HelpOnClik Guide",
          placement: "auto",
          stepNumber: 6,
          offset: -10,
        },
        {
          target: '.copy_code_tour',
          //text: '<div style="padding:3px 10px"'+appStore.getLanguageTrans('Copy the code and add it to your website anywhere between the <body></body> tag (best to place just before </body>).')+'</div>',
          content: appStore.getLanguageTrans('Copy the code and add it to your website anywhere between the <body></body> tag (best to place just before </body>).'),
          disableBeacon: true,
          title: "HelpOnClik Guide",
          placement: "auto",
          stepNumber: 7,
          offset: -10,
        },
      ],
      stepIndex: 0,
      overlaydisplay: false,
      isHomePage: false,
      isWebsitePage: false,
      isWidgetPage: false,
      isWidgetBuilderOpen: true,
    };
  }


  getallCustomIconByAccountId(appstore) {
    axios({
      method: 'GET',
      url: appstore.url + 'getAllCustomIcon.json',
      headers: { 'content-type': 'application/json' }
    }).then(result => {
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      let index = 56;
      let newIcons = JSON.parse(JSON.stringify(this.icons));
      for (let i in result.data) {
        index = index + 1;
        let customonline = {
          image: result.data[i].online != "" ? appstore.customIconurl + result.data[i].online : "",
          id: index,
          icon_uid: result.data[i].icon_uid, updateimageURLs: ""
        }
        newIcons.push(customonline);
        index = index + 1;
        let customofline = {
          image: result.data[i].offline != "" ? appstore.customIconurl + result.data[i].offline : "",
          id: index,
          icon_uid: result.data[i].icon_uid,
          updateimageURLs: ""
        }
        newIcons.push(customofline);
      }
      this.icons = newIcons;

      this.setwidgetDataforEdit(appstore);

      // this.setwidgetDataforEdit(appstore);
      this.pageisLoaded = false;
      appstore.global_errorcount=0;
    }).catch(error => {
      // console.log(error);
      // appstore.logout();
      this.pageisLoaded = false;
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }

    });
  }


  onEditorStateChange(editorState) {
    this.editorState = editorState;
    this.editorEditedValue = draftToHtml(convertToRaw(this.editorState.getCurrentContent()));
    this.editing = true;
  }

  checkForEnteredNumer(event) {
    let val;

    var key = window.event ? event.keyCode : event.which;

    if (event.keyCode == 8 || event.keyCode == 46
      || event.keyCode == 37 || event.keyCode == 39) {
      val = true;
    }
    // else if (key < 48 || key > 57) {
    //   val = false;
    // }
    else if (((key < 48 || (key <96 && key>57) ) || key > 105) || event.shiftKey) {
			    val = false;
			}
    else val = true;
    if (!val) {
      event.preventDefault();
    }
  }

  setOpsDropdown(operators) {
    let opt = operators;
    let allopt = { "label": "All Operators", "value": "", "user_uid": "" };
    opt.splice(0, 0, allopt);
    let ind = 1;
    for (let x in opt) {
      if (x > 0) {
        opt[x].label = opt[x].nick_name;
        opt[x].value = opt[x].user_uid;
        opt[x].user_uid = opt[x].user_uid;
        ind++;
      }
    }
    return opt;
  }

  saveWidget(appstore) {
    if (appstore.helpTourStarted && appstore.selectedWebisiteUid == "") {
      this.widgetBuildertourSteps.stepIndex = 2;
      return;
    }
    this.saveLoader = true;
    this.errorPopup = false;
    let va_enabled = this.widgetDetails.enableVirtualAgent;
    let ai_chatbot = this.widgetDetails.enableChatBot;
    let configJson = {
      color: this.widgetDetails.themeChoosen.theme.toLowerCase(),
      widget: {
        position: this.widgetDetails.widgetPosition.toLowerCase(),
        enablePopupInvitation: this.widgetDetails.showInvitation,
        hideWhenOffline: this.widgetDetails.offlineHidden
      },
    }

    // enablePrechat: this.widgetDetails.preChatEnabled ? 1 : 0,
    // alwaysShowPrechat: this.widgetDetails.preChatToUsers ? 1 : 0,
    let newWidgetDetails = {
      uid: this.widgetDetails.uid ? this.widgetDetails.uid : appstore.generateUid(),
      accountUid: this.widgetDetails.accountUid,
      title: this.widgetDetails.installationName,
      websiteUid: appstore.selectedWebisiteUid,
      departmentUid: this.widgetDetails.department.department_uid,
      operatorUid: this.widgetDetails.operator.user_uid,
      language: this.widgetDetails.language.value,
      clientConfigJson: JSON.stringify(configJson),
      isDefault: null,
      enableVirtualAgent: va_enabled ? 1 : 0,
      enableChatBot: ai_chatbot ? 1 : 0,
      modesString: "widget" + (!this.widgetDetails.showInvitation ? ",popupInvitation" : ""),
    }
    //rest call
    this.saveWidgetRestcall(appstore, newWidgetDetails, this.widgetDetails);
  }


  saveToolbar(appstore) {

    this.saveLoader = true;
    this.errorPopup = false;
    let va_enabled = this.toolBarGeneralSettings.enableVirtualAgent; 
    let ai_chatbot = this.toolBarGeneralSettings.enableChatBot;
    let configJson = {
      toolbar: {
        position: this.toolBarPreviewSettings.position,
        margin: this.toolBarPreviewSettings.marginValue,
        orientation: this.toolBarPreviewSettings.orientation,
        enablePopupInvitation: this.widgetDetails.showInvitation,
      },
    }

    //check default values, if avlues are not changed then dont push key value in object
    if (this.toolBarGeneralSettings.chatWindowSize === "custom") {
      configJson.toolbar.size = {
        width: this.toolBarGeneralSettings.customChatWindowSize.width,
        height: this.toolBarGeneralSettings.customChatWindowSize.height
      }
    }
    // alert(this.toolBarStyleSettings.barThickness.height);
    if (this.toolBarStyleSettings.barThickness.id !== "default") {
      if (this.toolBarStyleSettings.barThickness.id !== "custom") {
        configJson.toolbar.thickness = this.toolBarStyleSettings.barThickness.id;
      } else {
        //for small thickness
        configJson.toolbar.thickness = this.toolBarStyleSettings.barThickness.height;
      }
    } else {
      configJson.toolbar.thickness = this.toolBarStyleSettings.barThickness.height;
    }

    configJson.toolbar.style = {};
    configJson.toolbar.style.gradient = this.toolBarStyleSettings.gradient;
    configJson.toolbar.style.rounded = this.toolBarStyleSettings.roundedCorners;
    configJson.toolbar.style.fontFamily = this.toolBarStyleSettings.textFont.value;


    //////this.bgcolornew  this.ToolTipcolornew  this.ToolTiptextnew  this.bgtextnew

    // if (this.toolBarStyleSettings.bgColor.value !== "custom")
    //   configJson.toolbar.style.color = this.toolBarStyleSettings.bgColor.value;
    // else
    //   configJson.toolbar.style.color = this.toolBarStyleSettings.bgColor.colorCode;

    // if (this.toolBarStyleSettings.textColor.value !== "custom")
    //   configJson.toolbar.style.textColor = this.toolBarStyleSettings.textColor.value;
    // else
    //   configJson.toolbar.style.textColor = this.toolBarStyleSettings.textColor.colorCode;


    if (this.toolBarStyleSettings.bgColor.value !== "custom")
      configJson.toolbar.style.color = this.bgcolornew;
    else
      configJson.toolbar.style.color = this.bgcolornew;

    if (this.toolBarStyleSettings.textColor.value !== "custom")
      configJson.toolbar.style.textColor = this.bgtextnew;
    else
      configJson.toolbar.style.textColor = this.bgtextnew;

    configJson.toolbar.style.tooltip = {};
    if (this.toolBarStyleSettings.toolTipSettings.value == 2) {
      configJson.toolbar.style.tooltip.useCustom = true;
      configJson.toolbar.style.tooltip.gradient = this.toolBarToolTipStyleSettings.gradient;
      configJson.toolbar.style.tooltip.rounded = this.toolBarToolTipStyleSettings.roundedCorners;

      // if (this.toolBarToolTipStyleSettings.bgColor.value !== "custom")
      //   configJson.toolbar.style.tooltip.backgroundColor = this.toolBarToolTipStyleSettings.bgColor.value;
      // else
      //   configJson.toolbar.style.tooltip.backgroundColor = this.toolBarToolTipStyleSettings.bgColor.colorCode;

      // if (this.toolBarToolTipStyleSettings.textColor.value !== "custom")
      //   configJson.toolbar.style.tooltip.textColor = this.toolBarToolTipStyleSettings.textColor.value;
      // else
      //   configJson.toolbar.style.tooltip.textColor = this.toolBarToolTipStyleSettings.textColor.colorCode;
      if (this.toolBarToolTipStyleSettings.bgColor.value !== "custom")
        configJson.toolbar.style.tooltip.backgroundColor = this.ToolTipcolornew;
      else
        configJson.toolbar.style.tooltip.backgroundColor = this.ToolTipcolornew;

      if (this.toolBarToolTipStyleSettings.textColor.value !== "custom")
        configJson.toolbar.style.tooltip.textColor = this.ToolTiptextnew;
      else
        configJson.toolbar.style.tooltip.textColor = this.ToolTiptextnew;

    }
    //set button data 
    configJson.toolbar.buttons = {};
    let buttonType = ["chat", "email", "call", "social_facebook", "social_twitter"];
    let i = 0;
    let customIndex = 1;
    for (let button of this.toolBarPreviewButtonSettings) {
      //for chat button
      //from id=6 custom button starts
      if (button.id < 6) {

        configJson.toolbar.buttons[buttonType[i]] = {
          visible: button.show,
          caption: button.caption,
          tooltip: button.toolTip,
          hideWhenOnline: button.hiddenOffline,
        };
        if (button.id < 2)
          configJson.toolbar.buttons[buttonType[i]]["hideWhenOffline"] = button.hiddenOffline;

        if (button.iconType.id !== "default")
          configJson.toolbar.buttons[buttonType[i]]["iconType"] = button.iconType.id;

        if (button.iconType.id === "custom")
          configJson.toolbar.buttons[buttonType[i]]["iconUrl"] = this.deleteEditedImages(button.iconUrl, button.croppedImgUrl, appstore);

        else if (button.iconType.id == "font")
          configJson.toolbar.buttons[buttonType[i]]["iconFont"] = button.libraryIconClass;

        if (button.width.id === "custom")
          configJson.toolbar.buttons[buttonType[i]]["width"] = parseInt(button.width.width);
        else
          configJson.toolbar.buttons[buttonType[i]]["width"] = 0;

        //type changed images deleteing
        if (button.iconType.id === "default" || button.iconType.id === "none" || button.iconType.id === "font") {
          this.deleteTypeChangedImages(button.croppedImgUrl, appstore)
        }

        // for call button
        if (button.id == 3) {
          configJson.toolbar.buttons[buttonType[i]]["skypeLogin"] = button.skypeLogin;
          // if (button.showWhen.value != 2) {
          configJson.toolbar.buttons[buttonType[i]]["available"] = {};
          if (button.showWhen.id === "custom") {
            // configJson.toolbar.buttons[buttonType[i]]["available"]["text"] = this.weekDaysschedule.text;
            configJson.toolbar.buttons[buttonType[i]]["available"]["type"] = button.showWhen.id;
            configJson.toolbar.buttons[buttonType[i]]["available"]["schedule"] = this.weekDaysschedule.schedule;
            let dummy = " schedule isnt working in live app";
          }
          else
            configJson.toolbar.buttons[buttonType[i]]["available"]["type"] = button.showWhen.id;

          configJson.toolbar.buttons.call.available.text = button.customText;
          // }
          // if custom text is selected
          // if(button.useCustomText){
          //   if(button.customText !== this.callScheduleText)
          //     { 
          //      configJson.toolbar.buttons.call.available =  configJson.toolbar.buttons.call.hasOwnProperty("available") ? configJson.toolbar.buttons.call.available:{};  
          //      configJson.toolbar.buttons.call.available.text = button.customText;
          //     }else{

          //     } 
          //    }
        }
        //for facebook
        if (button.id == 4) {
          if (button.faceBookId.value == 2)
            configJson.toolbar.buttons[buttonType[i]]["social"] = {
              type: "custom",
              accountId: button.fbid,
            };
        }

        //for twitter button
        if (button.id == 5) {
          if (button.widgetHtmlCode) {
            //not woring in live
          }
          configJson.toolbar.buttons[buttonType[i]]["social"] = {};
          configJson.toolbar.buttons[buttonType[i]]["social"]["accountId"] = button.accountId;
          configJson.toolbar.buttons[buttonType[i]]["social"]["widgetId"] = button.widgetId;
        }

      } else {

        let contentType = { type: button.contentType.id }
        if (button.contentType.id === "text") {
          contentType.text = this.editorEditedValue ? this.editorEditedValue : "";
        } else {
          contentType.url = button.contentType.data;
        }

        const custom = "custom_" + customIndex;
        configJson.toolbar.buttons[custom] = {
          visible: button.show,
          caption: button.caption,
          tooltip: button.toolTip,
          content: contentType
        };

        if (button.iconType.id !== "default")
          configJson.toolbar.buttons[custom]["iconType"] = button.iconType.id;

        if (button.iconType.id === "custom")
          configJson.toolbar.buttons[custom]["iconUrl"] = this.deleteEditedImages(button.iconUrl, button.croppedImgUrl, appstore);

        else if (button.iconType.id == "font")
          configJson.toolbar.buttons[custom]["iconFont"] = button.libraryIconClass;

        configJson.toolbar.buttons[custom]["width"] = button.width.id === "custom" ? parseInt(button.width.width) : 0;

        //type changed images deleteing
        if (button.iconType.id === "default" || button.iconType.id === "none" || button.iconType.id === "font")
          this.deleteTypeChangedImages(button.croppedImgUrl, appstore)


        customIndex++;
      }
      i++;
    }

    //for invitation tab
    if (this.toolBarInvitationSettings.invitationType == 1) {
      if (this.toolBarInvitationSettings.invitationImage.value == 2) {
        configJson.toolbar.invitation = {
          image: { url: this.deleteEditedImages(this.toolBarInvitationSettings.url, this.toolBarInvitationSettings.croppedInvUrl, appstore) },

        };
        this.deleteTypeChangedImages(this.toolBarInvitationSettings.croppedCustomImgUrl, appstore)
      }
      else {
        let imagetype = this.toolBarInvitationSettings.invitationImage.value;
        configJson.toolbar.invitation = {
          image: {
            type: imagetype == 1 && imagetype != 2 ? "default" : "none"
          }
        };
      }
      if (this.toolBarInvitationSettings.styles == 2) {
        configJson.toolbar.invitation.styles = {
          bodyBackground: { "background-color": this.toolBarInvitationSettings.bgColor },
          bodyText: { color: this.toolBarInvitationSettings.bgTextColor },
          acceptButton: { background: this.toolBarInvitationSettings.buttonBgColor, color: this.toolBarInvitationSettings.buttonBgTextColor }
        }
      }
    }
    //for custom selection
    else {
      configJson.toolbar.invitation = {
        type: "customImage",
        image: { url: this.deleteEditedImages(this.toolBarInvitationSettings.customImageUrl, this.toolBarInvitationSettings.croppedCustomImgUrl, appstore) },
        marginToolbar: this.toolBarInvitationSettings.margin,
        margin: this.toolBarInvitationSettings.leftMargin,
      }
      this.deleteTypeChangedImages(this.toolBarInvitationSettings.croppedInvUrl, appstore)
    }
    // enablePrechat: this.toolBarGeneralSettings.preChatEnabled ? 1 : 0,
    // alwaysShowPrechat: this.toolBarGeneralSettings.preChatToUsers ? 1 : 0,

    const newToolbar = {
      uid: this.toolBarGeneralSettings.uid ? this.toolBarGeneralSettings.uid : appstore.generateUid(),
      websiteUid: appstore.selectedWebisiteUid,
      departmentUid: this.toolBarGeneralSettings.department.department_uid,
      operatorUid: this.toolBarGeneralSettings.operator.user_uid,
      language: this.toolBarGeneralSettings.language.value,
      title: this.toolBarGeneralSettings.installationName,
      enableVirtualAgent: va_enabled ? 1 : 0,
      enableChatBot : ai_chatbot ? 1 : 0,
      isDefault: null,
      clientConfigJson: JSON.stringify(configJson),
      modesString: "toolbar",
    }

    this.saveWidgetRestcall(appstore, newToolbar, this.toolBarGeneralSettings);
  }
  deleteEditedImages(img, savedimg, appstore) {
    let file = img.split("/");
    let saveimg = savedimg.split("/");
    if ((img != "" && img != undefined) && (saveimg != "" && saveimg != undefined)) {
      if (file[6] != saveimg[6]) {
        // console.log(saveimg[6]);
        this.deletefiles(saveimg[6], appstore);
      }
    }
    return img;
  }
  deleteTypeChangedImages(savedimg, appstore) {
    let saveimg = savedimg.split("/");
    if (saveimg != "" && saveimg != undefined) {
      // console.log(saveimg[6]);
      this.deletefiles(saveimg[6], appstore);
    }
  }

  setwidgetDataforEdit = (appstore) => {
    //window.scrollTo(0, 350);
    if (!appstore.widgetForEdit) {
      return;
    }

    let widgetforEdit = appstore.widgetForEdit;
    let allwidgets = appstore.storedWidgets;

    if (widgetforEdit) {
      //show data for editing widget
      widgetforEdit = this.findOption(widgetforEdit.uid, allwidgets, "uid")
      //set theme
      if (widgetforEdit.clientConfigJson != null)
        widgetforEdit.clientConfigJson = JSON.parse(widgetforEdit.clientConfigJson);
      else
        widgetforEdit.clientConfigJson = "";

      //check widget type
      const typeArray = widgetforEdit.modesString.split(',');
      const type = typeArray[0];

      if (type === "widget" && typeArray[1] !== "icon")
        this.setWidget(widgetforEdit, appstore);
      else if (type === "toolbar")
        this.setToolbar(widgetforEdit, appstore);
      else if (type === "icon")
        this.setIcon(widgetforEdit, appstore)
      else if (type === "help")
        this.setDirectLink(widgetforEdit, appstore)
      else if ((type === "widget" && typeArray[1] === "icon"))
        this.setWidgetIcon(widgetforEdit, appstore);
      else {
        //for iframe or button
      }
    }

    appstore.widgetForEdit = "";
  }

  savecustomeiceon(appstore, formData) {
    axios({
      method: 'POST',
      url: appstore.url + 'saveWidgetCustomIcon.json',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData,
    }).then(result => {
      let files = result.data.split("/");
      this.storedofflineFileName = files[1] != "null" ? files[1] : "";
      this.storedonlineFileName = files[0] != "null" ? files[0] : "";
      this.storediconuid = files[2];
      if (this.selectedWidget == 3)
        this.saveIcon(appstore);

      else if (this.selectedWidget == 5)
        this.saveWidgetIcon(appstore);

        appstore.global_errorcount=0;
    }).catch((error) => {
      // console.log(error);
      // appstore.logout();
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }

    });

  }
  deletecustomiconconform(id, icon_uid, icons, appstore) {
    this.conformationpopup = true;
    this.icon_uid = icon_uid;
    this.id = id;
    this.mode = icons;
  }
  deletecustomicon(appstore) {
    axios({
      method: 'POST',
      url: appstore.url + 'deleteWidgetCustomIcon.json',
      headers: { 'content-type': 'application/json' },
      data: { "icon_uid": this.icon_uid, "title": this.mode }
    }).then(result => {
      this.icons[this.id].image = "";
      this.icons[this.id].updateimageURLs = "";

      this.icon_uid = "";
      this.id = "";
      this.mode = "";
      this.conformationpopup = false;
    }).catch((error) => {
      // console.log(error);
      // appstore.logout();
      this.conformationpopup = false;
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    });
  }


  updatecustomimg(index, appstore, formData) {
    axios({
      method: 'POST',
      url: appstore.url + 'updateWidgetCustomIcon.json',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData
    }).then(result => {
      this.icons[index].image = appstore.customIconurl + result.data;
      appstore.global_errorcount=0;
    }).catch((error) => {
      // console.log(error);
      // appstore.logout();
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    });
  }
  saveIcon(appstore) {

    this.saveLoader = true;
    this.errorPopup = false;
    let va_enabled = this.iconDetails.enableVirtualAgent;
    let ai_chatbot = this.iconDetails.enableChatBot;
    let iconlink = {};
    if ((this.selectedonlinefile != "" && this.selectedonlinefile != undefined)
      || (this.selectedofflinefile != "" && this.selectedofflinefile != undefined)) {

      iconlink = {
        online: this.storedonlineFileName != "" ? appstore.customIconurl + this.storedonlineFileName : "",
        offline: this.storedofflineFileName != "" ? appstore.customIconurl + this.storedofflineFileName : ""
      }
    } else {
      if (this.selectedIcon) {
        if (this.selectedIcon.image.indexOf("static/assist/") != -1) {

          let array = this.selectedIcon.image.split("/");
          let name = array[array.length - 1]
          //set offline icon url
          name = name.replace("offline", "online")
          iconlink = {
            offline: this.selectedIcon.image,
            online: appstore.imgpath + "icons/" + name
          }
        } else {
          iconlink = {
            offline: this.customselected.offlineimage,
            online: this.customselected.onlineimage
          }
        }

      }
    }


    const clientJson = {
      icon: {
        hideWhenOffline: this.iconDetails.offlineHidden,
        iconLinks: iconlink
      }
    }
    // enablePrechat: this.iconDetails.preChatEnabled ? 1 : 0,
    // alwaysShowPrechat: this.iconDetails.preChatToUsers ? 1 : 0,
    const newIconDetails = {
      uid: this.iconDetails.uid ? this.iconDetails.uid : appstore.generateUid(),
      accountUid: this.iconDetails.accountUid,
      title: this.iconDetails.installationName,
      websiteUid: appstore.selectedWebisiteUid,
      departmentUid: this.iconDetails.department.department_uid,
      operatorUid: this.iconDetails.operator.user_uid,
      language: this.iconDetails.language.value,
      isDefault: null,
      enableVirtualAgent: va_enabled ? 1 : 0,
      enableChatBot : ai_chatbot ? 1 : 0,
      clientConfigJson: JSON.stringify(clientJson),
      modesString: "icon,popupInvitation",
    }

    //rest call
    this.saveWidgetRestcall(appstore, newIconDetails, this.iconDetails);

  }
  saveDirectLink(appstore) {

    this.saveLoader = true;
    this.errorPopup = false;
    let va_enabled = this.directLinkDetails.enableVirtualAgent;
    let ai_chatbot = this.directLinkDetails.enableChatBot;
    //check for custom color
    const styles = {
      toolbarDirect: {
        style: {
          color: this.directLinkDetails.topBarColor.label === "Custom" ? this.directLinkDetails.topBarColor.colorCode : this.directLinkDetails.topBarColor.value,
          textColor: this.directLinkDetails.topBarTextColor.label === "Custom" ? this.directLinkDetails.topBarTextColor.colorCode : this.directLinkDetails.topBarTextColor.value,
          fontFamily: this.directLinkDetails.textFont.value
        }
      }
    }

    // enablePrechat: this.directLinkDetails.preChatEnabled ? 1 : 0,
    // alwaysShowPrechat: this.directLinkDetails.preChatToUsers ? 1 : 0,
    const newdDirectLinkDetails = {
      uid: this.directLinkDetails.uid ? this.directLinkDetails.uid : appstore.generateUid(),
      accountUid: this.directLinkDetails.accountUid,
      title: this.directLinkDetails.installationName,
      websiteUid: appstore.selectedWebisiteUid,
      departmentUid: this.directLinkDetails.department.department_uid,
      operatorUid: this.directLinkDetails.operator.user_uid,
      language: this.directLinkDetails.language.value,
      isDefault: null,
      enableVirtualAgent: va_enabled ? 1 : 0,
      enableChatBot : ai_chatbot ? 1 : 0,
      modesString: "help",
      clientConfigJson: JSON.stringify(styles)
    }

    //rest call
    this.saveWidgetRestcall(appstore, newdDirectLinkDetails, this.directLinkDetails);
  }

  saveWidgetIcon(appstore) {
    this.saveLoader = true;
    this.errorPopup = false;
    let va_enabled = this.widgetIconDetails.enableVirtualAgent;
    let ai_chatbot = this.widgetIconDetails.enableChatBot;
    let iconlink = {};

    if ((this.selectedonlinefile != "" && this.selectedonlinefile != undefined)
      || (this.selectedofflinefile != "" && this.selectedofflinefile != undefined)) {
      iconlink = {
        online: this.storedonlineFileName != "" ? appstore.customIconurl + this.storedonlineFileName : "",
        offline: this.storedofflineFileName != "" ? appstore.customIconurl + this.storedofflineFileName : ""
      }
    } else {
      if (this.selectedIcon) {
        if (this.selectedIcon.image.indexOf("static/assist/") != -1) {

          let array = this.selectedIcon.image.split("/");
          let name = array[array.length - 1]
          //set offline icon url
          name = name.replace("offline", "online")
          iconlink = {
            offline: this.selectedIcon.image,
            online: appstore.imgpath + "icons/" + name
          }
        } else {
          iconlink = {
            offline: this.customselected.offlineimage,
            online: this.customselected.onlineimage
          }
        }

      }
    }


    const clientJson = {
      icon: {
        hideWhenOffline: this.widgetIconDetails.offlineHidden, //need to verify 
        iconLinks: iconlink
      },
      color: this.widgetDetails.themeChoosen.theme.toLowerCase(),
      widget: {
        position: this.widgetDetails.widgetPosition,
        enablePopupInvitation: true,//need to verify  //this.widgetIconDetails.showInvitation,
        hideWhenOffline: this.widgetIconDetails.offlineHidden
      },
    }

    // enablePrechat: this.widgetIconDetails.preChatEnabled ? 1 : 0,
    // alwaysShowPrechat: this.widgetIconDetails.preChatToUsers ? 1 : 0,
    let newWidgetIconDetails = {
      uid: this.widgetIconDetails.uid ? this.widgetIconDetails.uid : appstore.generateUid(),
      accountUid: this.widgetIconDetails.accountUid,
      title: this.widgetIconDetails.installationName,
      websiteUid: appstore.selectedWebisiteUid,
      departmentUid: this.widgetIconDetails.department.department_uid,
      operatorUid: this.widgetIconDetails.operator.user_uid,
      language: this.widgetIconDetails.language.value,
      enableVirtualAgent: va_enabled ? 1 : 0,
      enableChatBot : ai_chatbot ? 1 : 0,
      clientConfigJson: JSON.stringify(clientJson),
      isDefault: null,
      //this.widgetIconDetails.showInvitation radio button is missing need to verify
      modesString: "widget,icon" + (!this.widgetIconDetails.showInvitation ? ",popupInvitation" : ""),
    }

    //rest call
    this.saveWidgetRestcall(appstore, newWidgetIconDetails, this.widgetIconDetails);

  }


  setWidget(widgetforEdit, appstore) {
    //set widget data for edit
     
    for(let i in this.widgetRedirectThemes){
      if(this.widgetRedirectThemes[i].theme==widgetforEdit.clientConfigJson.color){ 
        widgetforEdit.clientConfigJson.color= this.widgetRedirectThemes[i].redirect_theme;
        break;
      }
    }    
    this.widgetDetails = {
      uid: widgetforEdit.uid,
      accountUid: widgetforEdit.accountUid,
      websiteUid: widgetforEdit.websiteUid,
      department: this.findOption(widgetforEdit.departmentUid, this.deptDrpDwnOptions, "department_uid"),
      operator: this.findOption(widgetforEdit.operatorUid, this.operatorDrpDwnOptions, "user_uid"),
      installationName: widgetforEdit.title,
      language: this.findOption(widgetforEdit.language, appstore.languages, "value"),
      themeChoosen: this.findOption(widgetforEdit.clientConfigJson.color, this.widgetThemes, "theme"),
      preChatEnabled: widgetforEdit.enablePrechat == 1 ? true : false,
      preChatToUsers: widgetforEdit.alwaysShowPrechat == 1 ? true : false,
      enableVirtualAgent: widgetforEdit.enableVirtualAgent == 1 ? true : false,
      enableChatBot : widgetforEdit.enableChatBot == 1 ? true : false,
    }

    if (widgetforEdit.clientConfigJson.widget) {
      this.widgetDetails.showInvitation = widgetforEdit.clientConfigJson.widget.enablePopupInvitation;
      this.widgetDetails.offlineHidden = widgetforEdit.clientConfigJson.widget.hideWhenOffline;
      if (widgetforEdit.clientConfigJson.widget.position) {
        this.widgetDetails.widgetPosition = widgetforEdit.clientConfigJson.widget.position === 'bottom right' ? 'Bottom Right' : 'Bottom Left';
      }
    } else {
      this.widgetDetails.showInvitation = false;
      this.widgetDetails.offlineHidden = false;
      this.widgetDetails.widgetPosition = 'Bottom Right';
    }

    //set selected langauge
    appstore.setLanguage(this.widgetDetails.language);
    //this.selectedLanguage=this.languages[0];    
  }
  setschedulecustomtext = (data) => {
    if (data.hasOwnProperty("available")) {
      if (data.available.type == "custom") {
        return this.callScheduleText[2].label != data.available.text ? false : true;
      } else if (data.available.type == "always") {
        return this.callScheduleText[0].label != data.available.text ? false : true;
      } else {
        return this.callScheduleText[1].label != data.available.text ? false : true;
      }
    } else {
      return false;
    }
  }


  setscheduleedit = (data) => {
    if (data.hasOwnProperty("available")) {
      let index;
      if (data.available != undefined) {
        if (data.available.type === "custom") {
          this.customeschedule = true;
        } else {
          this.customeschedule = false;
        }
        for (index in this.weekDays) {
          if (data.available.schedule != undefined) {
            if (data.available.schedule.hasOwnProperty(this.weekDays[index].value)) {
              let times = data.available.schedule[this.weekDays[index].value].split("-");
              this.weekDays[index].startTime = times[0];
              this.weekDays[index].EndTime = times[1];
              this.weekDays[index].selected = true;
              this.clearweekDays[index].startTime = times[0];
              this.clearweekDays[index].EndTime = times[1];
              this.clearweekDays[index].selected = true;
            }
          }
        }
        let keys = { text: "xc", type: "custom", schedule: {} }
        let schedule = {}
        for (let i = 0; i < this.weekDays.length; i++) {
          if (this.weekDays[i].selected) {
            schedule[this.weekDays[i].value] = this.weekDays[i].startTime + "-" + this.weekDays[i].EndTime;
          }
        }
        keys.schedule = schedule;
        this.weekDaysschedule = keys;
        this.clearweekDaysschedule = keys;
        for (let a in this.buttonSettingsTimes) {
          if (this.buttonSettingsTimes[a].id === data.available.type) {
            return this.buttonSettingsTimes[a]
          }
        }
      } else {
        return this.buttonSettingsTimes[1];
      }
    } else {
      return this.buttonSettingsTimes[1];
    }
  }

  setToolbar(widgetforEdit, appstore) {
    this.toobaredit = true;
    this.toolBarGeneralSettings = {
      uid: widgetforEdit.uid,
      accountUid: widgetforEdit.accountUid,
      websiteUid: widgetforEdit.websiteUid,
      department: this.findOption(widgetforEdit.departmentUid, this.deptDrpDwnOptions, "department_uid"),
      operator: this.findOption(widgetforEdit.operatorUid, this.operatorDrpDwnOptions, "user_uid"),
      installationName: widgetforEdit.title,
      language: this.findOption(widgetforEdit.language, appstore.languages, "value"),
      chatWindowSize: this.chatWindowOptions[0].value,
      preChatEnabled: widgetforEdit.enablePrechat ? true : false,
      preChatToUsers: widgetforEdit.alwaysShowPrechat ? true : false,
      enableVirtualAgent: widgetforEdit.enableVirtualAgent == 0 ? false : true,
      enableChatBot : widgetforEdit.enableChatBot == 1 ? true : false,
    }
    // enableVirtualAgent:va_enabled ? 1 : 0, 

    this.toolBarPreviewButtonposition = widgetforEdit.clientConfigJson.toolbar.position;
    if (widgetforEdit.clientConfigJson.toolbar.size) {
      this.toolBarGeneralSettings.chatWindowSize = "custom";
      this.toolBarGeneralSettings.customChatWindowSize = {
        height: widgetforEdit.clientConfigJson.toolbar.size.height,
        width: widgetforEdit.clientConfigJson.toolbar.size.width
      }
    } else {
      this.toolBarGeneralSettings.customChatWindowSize = {
        height: 400,
        width: 365
      }
    }
 
      this.bgcolornew = this.topBarColors[1].colorCode; 

      this.bgtextnew =  this.topBarTextColors[1].colorCode;
      
      this.ToolTipcolornew = this.topBarColors[1].colorCode;
      
      this.ToolTiptextnew = this.topBarTextColors[1].colorCode;
       

    //set style section of toolbar
    if (widgetforEdit.clientConfigJson.toolbar.style) {
      //let color = widgetforEdit.clientConfigJson.toolbar.style.color ? widgetforEdit.clientConfigJson.toolbar.style.color : this.topBarColors[1];
      let color = (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style') && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('color')) ? widgetforEdit.clientConfigJson.toolbar.style.color : this.topBarColors[1];
      // let textcolor = widgetforEdit.clientConfigJson.toolbar.style.textColor ? widgetforEdit.clientConfigJson.toolbar.style.textColor : this.topBarTextColors[1];

      let textcolor = (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style') && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('textColor')) ? widgetforEdit.clientConfigJson.toolbar.style.textColor : this.topBarTextColors[1];
      let bgandtextColor = this.getColorforValue(color, textcolor);
      // let tooltipcolor= widgetforEdit.clientConfigJson.toolbar.style.tooltip.backgroundColor ? widgetforEdit.clientConfigJson.toolbar.style.tooltip.backgroundColor : this.topBarColors[1];
      // let tooltiptextcolor= widgetforEdit.clientConfigJson.toolbar.style.tooltip.textColor ? widgetforEdit.clientConfigJson.toolbar.style.tooltip.textColor : this.topBarTextColors[1];
      let txtcolorcode = textcolor;
      if (typeof (textcolor) == "object") {
        txtcolorcode = textcolor.colorCode;
      } 
      this.bgcolornew = bgandtextColor.foundcolor;
      if (typeof (bgandtextColor.foundcolor) == "object") {
        this.bgcolornew = bgandtextColor.foundcolor.colorCode;
      }

      this.bgtextnew = bgandtextColor.foundtextcolor;
      if (typeof (bgandtextColor.foundtextcolor) == "object") {
        this.bgtextnew = bgandtextColor.foundtextcolor.colorCode;
      }


      let tooltipcolor = (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style')
        && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('tooltip')
        && widgetforEdit.clientConfigJson.toolbar.style.tooltip.hasOwnProperty('backgroundColor'))
        ? widgetforEdit.clientConfigJson.toolbar.style.tooltip.backgroundColor :
        (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style') && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('color')) ? widgetforEdit.clientConfigJson.toolbar.style.color : this.topBarColors[1];
      let tooltiptextcolor = (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style')
        && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('tooltip')
        && widgetforEdit.clientConfigJson.toolbar.style.tooltip.hasOwnProperty('textColor'))
        ? widgetforEdit.clientConfigJson.toolbar.style.tooltip.textColor :
        (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style') && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('textColor')) ? widgetforEdit.clientConfigJson.toolbar.style.textColor : this.topBarTextColors[1];
      let tooltip_bgandtextColor = this.getColorforValue(tooltipcolor, tooltiptextcolor);
      // this.toolBarPreviewSettings 
      this.toolBarToolTipStyleSettings.bgColor = tooltip_bgandtextColor.foundcolor;
      this.toolBarToolTipStyleSettings.bgColorCustom = tooltip_bgandtextColor.foundcolor.colorCode;
      this.toolBarToolTipStyleSettings.textColor = tooltip_bgandtextColor.foundtextcolor;
      this.toolBarToolTipStyleSettings.textColorCustom = tooltip_bgandtextColor.foundtextcolor.colorCode;
      this.ToolTipcolornew = tooltip_bgandtextColor.foundcolor;
      if (typeof (tooltip_bgandtextColor.foundcolor) == "object") {
        this.ToolTipcolornew = tooltip_bgandtextColor.foundcolor.colorCode;
      }
      this.ToolTiptextnew = tooltip_bgandtextColor.foundtextcolor;
      if (typeof (tooltip_bgandtextColor.foundtextcolor) == "object") {
        this.ToolTiptextnew = tooltip_bgandtextColor.foundtextcolor.colorCode;
      } 

      this.toolBarStyleSettings = {
        bgColor: bgandtextColor.foundcolor,
        bgColorCustom: color,
        textColor: bgandtextColor.foundtextcolor,
        textColorCustom: txtcolorcode,
        gradient: widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty("gradient") ? widgetforEdit.clientConfigJson.toolbar.style.gradient ? true : false : false,
        roundedCorners: widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty("rounded") ? widgetforEdit.clientConfigJson.toolbar.style.rounded ? true : false : true,
        toolTipSettings: this.toolTipStyle[0]
      }
      const thickness = widgetforEdit.clientConfigJson.toolbar.thickness ? widgetforEdit.clientConfigJson.toolbar.thickness : "default";
      if (thickness === "default") {
        this.toolBarStyleSettings.barThickness = this.findOption(thickness, this.toolBarThickness, "id")
      } else if (thickness === "small") {
        this.toolBarStyleSettings.barThickness = this.findOption(thickness, this.toolBarThickness, "id")
      } else {
        //custom
        this.toolBarStyleSettings.barThickness = this.findOption("custom", this.toolBarThickness, "id")
        this.toolBarStyleSettings.barThickness.height = thickness;
      }

      let font = widgetforEdit.clientConfigJson.toolbar.style.fontFamily ? widgetforEdit.clientConfigJson.toolbar.style.fontFamily : this.textFonts[0];
      this.toolBarStyleSettings.textFont = typeof (font) !== "object" ? this.findOption(font, this.textFonts, "value") : this.textFonts[0];

      const tooltip = widgetforEdit.clientConfigJson.toolbar.style.tooltip ? widgetforEdit.clientConfigJson.toolbar.style.tooltip : false;
      if (tooltip.useCustom) {

        this.toolBarStyleSettings.toolTipSettings = this.toolTipStyle[1];
        let tooltipcolor = tooltip.backgroundColor ? tooltip.backgroundColor : this.topBarColors[1];
        let tooltiptextcolor = tooltip.textColor ? tooltip.textColor : this.topBarTextColors[1];
        let tooltipbgandtextColor = this.getColorforValue(color, textcolor);

        this.toolBarToolTipStyleSettings = {
          bgColor: tooltipbgandtextColor.foundcolor,
          bgColorCustom: tooltipbgandtextColor.foundcolor.colorCode,
          textColor: tooltipbgandtextColor.foundtextcolor,
          textColorCustom: tooltipbgandtextColor.foundtextcolor.colorCode,
          // gradient: widgetforEdit.clientConfigJson.toolbar.style.tooltip.gradient ? true : false,
          // roundedCorners: widgetforEdit.clientConfigJson.toolbar.style.tooltip.rounded ? true : false,
          gradient: (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style') && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('tooltip') && widgetforEdit.clientConfigJson.toolbar.style.tooltip.hasOwnProperty('gradient')) ? widgetforEdit.clientConfigJson.toolbar.style.tooltip.gradient ? true :false : false,
          roundedCorners: (widgetforEdit.clientConfigJson.toolbar.hasOwnProperty('style') && widgetforEdit.clientConfigJson.toolbar.style.hasOwnProperty('tooltip') && widgetforEdit.clientConfigJson.toolbar.style.tooltip.hasOwnProperty('rounded')) ? widgetforEdit.clientConfigJson.toolbar.style.tooltip.rounded ? true :false : true,
        }
      }
    }
 
    //set buttons
    const buttonsdata = widgetforEdit.clientConfigJson.toolbar.buttons;
    // console.log(buttonsdata);
    let buttons = [];
    let index = 1;
    let customId = 6;
    for (let key in buttonsdata) {

      const buttonType = ["chat", "email", "call", "social_facebook", "social_twitter"];
      let notcustomButton = buttonType.find(function (button) {
        return button === key;
      });

      if (notcustomButton) {
        let button = {
          id: index,
          type: key,
          label: key === "social_facebook" ? "Facebook" : key === "social_twitter" ? "Twitter" : key.charAt(0).toUpperCase() + key.slice(1),
          iconType: this.toolBarButtonIcons[0],
          iconClass: 'chat',
          libraryIconClass: '',
          iconUrl: '',
          defaultOpen: false,
          croppedImgUrl: ""
        }

        index++;
        button.width = buttonsdata[key].width != 0 ? this.toolBarButtonWidth[1] : this.toolBarButtonWidth[0];
        button.width.width = buttonsdata[key].width;

        if (key === "chat" || key === "email") {
          button.show = buttonsdata[key].hasOwnProperty("visible") ? buttonsdata[key].visible : true;
          button.hiddenOffline = buttonsdata[key].hasOwnProperty("hideWhenOffline") ? buttonsdata[key].hideWhenOffline : key === "chat" ? true:true;
          button.caption = buttonsdata[key].caption ? buttonsdata[key].caption : key === "chat" ? "Live Chat with us" : "Leave us a message";
          button.toolTip = buttonsdata[key].toolTip ? buttonsdata[key].toolTip : key === "chat" ? "Live Chat with us" : "Leave us a message";
          button.iconType = buttonsdata[key].iconType ? this.findOption(buttonsdata[key].iconType, this.toolBarButtonIcons, "id") : this.toolBarButtonIcons[0];

          if (button.iconType.id == "font") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-" + buttonsdata[key].iconFont;
            button.libraryIconClass = buttonsdata[key].iconFont;
          }
          //buttonsdata[key].iconFont.slice(15) : 
          else if (button.iconType.id == "custom") {
            //for custom 
            button.iconUrl = buttonsdata[key].iconUrl;
            button.croppedImgUrl = buttonsdata[key].iconUrl;
          } else if (button.iconType.id == "none") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-";
            button.libraryIconClass = "HelpOnClick-";
          } else {
            let icons = key === "chat" ? "chat" : "envelope-o";
            buttonsdata[key].libraryIconClass = icons;
            button.libraryIconClass = icons;
          }
        }
        else if (key === "social_facebook" || key === "social_twitter") {
          button.show = buttonsdata[key].visible ? buttonsdata[key].visible : false;
          button.caption = buttonsdata[key].caption;
          button.toolTip = buttonsdata[key].tooltip ? buttonsdata[key].tooltip : key === "social_facebook" ? "Like on Facebook" : "Follow on Twitter";
          button.iconType = buttonsdata[key].iconType ? this.findOption(buttonsdata[key].iconType, this.toolBarButtonIcons, "id") : buttonsdata[key].hasOwnProperty("iconType") ? this.toolBarButtonIcons[1] : this.toolBarButtonIcons[0];

          if (button.iconType === "font") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-" + buttonsdata[key].iconFont;
            button.libraryIconClass = buttonsdata[key].iconFont;
          }
          else if (button.iconType.id == "custom") {
            //for custom 
            button.iconUrl = buttonsdata[key].iconUrl;
            button.croppedImgUrl = buttonsdata[key].iconUrl;
          } else if (button.iconType.id == "none") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-";
            button.libraryIconClass = "HelpOnClick-";
          } else {
            let icons = key === "social_facebook" ? "facebook" : "twitter";
            buttonsdata[key].libraryIconClass = icons;
            button.libraryIconClass = icons;
          }
        }
        else if (key === "call") {
          button.show = buttonsdata[key].visible ? buttonsdata[key].visible : false;
          button.caption = buttonsdata[key].caption;
          button.toolTip = buttonsdata[key].tooltip ? buttonsdata[key].tooltip : "Call me back";
          button.iconType = buttonsdata[key].iconType ? this.findOption(buttonsdata[key].iconType, this.toolBarButtonIcons, "id") : buttonsdata[key].hasOwnProperty("iconType") ? this.toolBarButtonIcons[1] : this.toolBarButtonIcons[0];
          button.skypeLogin = buttonsdata[key].skypeLogin;
          //down code is temperory, functionality in live isnt working 
          button.showWhen = this.setscheduleedit(buttonsdata[key]);
          button.useCustomText = this.setschedulecustomtext(buttonsdata[key]);
          button.customText = buttonsdata[key].hasOwnProperty("available") ?
            buttonsdata[key].available.hasOwnProperty("text")
              ? buttonsdata[key].available.text : this.callScheduleText[2].label : this.callScheduleText[2].label;
          if (button.iconType.id == "font") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-" + buttonsdata[key].iconFont;
            button.libraryIconClass = buttonsdata[key].iconFont;
          }
          //buttonsdata[key].iconFont.slice(15) : 
          else if (button.iconType.id == "custom") {
            //for custom 
            button.iconUrl = buttonsdata[key].iconUrl;
            button.croppedImgUrl = buttonsdata[key].iconUrl;
          } else if (button.iconType.id == "none") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-";
            button.libraryIconClass = "HelpOnClick-";
          } else {
            let icons = "phone";
            buttonsdata[key].libraryIconClass = icons;
            button.libraryIconClass = icons;
          }
        }

        if (key === "social_facebook") {
          button.faceBookId = buttonsdata[key]["social"] ? this.fbOptions[1] : this.fbOptions[0];
          button.fbid = buttonsdata[key].social ? buttonsdata[key]["social"]["accountId"] : '';
          if (button.iconType.id == "font") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-" + buttonsdata[key].iconFont;
            button.libraryIconClass = buttonsdata[key].iconFont;
          }
          //buttonsdata[key].iconFont.slice(15) : 
          else if (button.iconType.id == "custom") {
            //for custom 
            button.iconUrl = buttonsdata[key].iconUrl;
            button.croppedImgUrl = buttonsdata[key].iconUrl;
          }
        } else if (key === "social_twitter") {
          button.widgetHtmlCode = '';
          button.accountId = buttonsdata[key].social ? buttonsdata[key]["social"]["accountId"] : '';
          button.widgetId = buttonsdata[key].social ? buttonsdata[key]["social"]["widgetId"] : ''
          if (button.iconType.id == "font") {
            buttonsdata[key].libraryIconClass = "HelpOnClick-" + buttonsdata[key].iconFont;
            button.libraryIconClass = buttonsdata[key].iconFont;
          }
          //buttonsdata[key].iconFont.slice(15) : 
          else if (button.iconType.id == "custom") {
            //for custom 
            button.iconUrl = buttonsdata[key].iconUrl;
            button.croppedImgUrl = buttonsdata[key].iconUrl;
          }
        }

        buttons.push(button);
      }
      else {
        let customBtn = {
          id: customId,
          label: buttonsdata[key].caption ? buttonsdata[key].caption : key.charAt(0).toUpperCase() + key.slice(1),
          show: buttonsdata[key].visible ? buttonsdata[key].visible : false,
          //hiddenOffline: false,
          caption: buttonsdata[key].caption ? buttonsdata[key].caption : 'Custom',
          toolTip: buttonsdata[key].tooltip ? buttonsdata[key].tooltip : 'Custom Tab',
          iconClass: '',
          iconUrl: '',
          contentType: this.btnContentTypes[0],
          defaultOpen: false,
          croppedImgUrl: ''
        }
        customId++;
        customBtn.width = buttonsdata[key].width != 0 ? this.toolBarButtonWidth[1] : this.toolBarButtonWidth[0];
        customBtn.width.width = buttonsdata[key].width;

        customBtn.iconType = buttonsdata[key].iconType ? this.findOption(buttonsdata[key].iconType, this.toolBarCustomButtonIcons, "id") : this.toolBarCustomButtonIcons[0];

        if (customBtn.iconType.id == "font") {
          buttonsdata[key].libraryIconClass = "HelpOnClick-" + buttonsdata[key].iconFont;
          customBtn.libraryIconClass = buttonsdata[key].iconFont;
        }
        //buttonsdata[key].iconFont.slice(15) : 
        else if (customBtn.iconType.id == "custom") {
          //for custom
          customBtn.iconUrl = buttonsdata[key].iconUrl;
          customBtn.croppedImgUrl = buttonsdata[key].iconUrl;
        } else if (customBtn.iconType.id == "none") {
          buttonsdata[key].libraryIconClass = "HelpOnClick-";
          customBtn.libraryIconClass = "HelpOnClick-";
        } else {
          let icons = "HelpOnClick-";
          buttonsdata[key].libraryIconClass = icons;
          customBtn.libraryIconClass = icons
        }

        if ((buttonsdata[key].content && buttonsdata[key].content.type) && buttonsdata[key].content.type !== "text") {
          customBtn.contentType = this.findOption(buttonsdata[key].content.type, this.btnContentTypes, "id");
          customBtn.contentType.data = buttonsdata[key].content.url;
        } else if (buttonsdata[key].content) {
          customBtn.contentType = this.findOption("text", this.btnContentTypes, "id");
          customBtn.contentType.data = buttonsdata[key].content.text ? buttonsdata[key].content.text : "";
        }

        buttons.push(customBtn);
        // this.toolBarPreviewButtonSettings.push(Object.assign({},this.toolBarAddButton));
      }
    }

    this.toolBarAddButton.id = customId;
    this.toolBarPreviewButtonSettings = buttons;
    //for invitation tab
    if (widgetforEdit.clientConfigJson.toolbar.invitation) {
      let invitation = widgetforEdit.clientConfigJson.toolbar.invitation;
      let invitationImg = this.invitationImageTypes[0];
      let imgurl = '';
      let customImageUrl = '';
      this.invitationprieviewimg = appstore.imgpath + "op/images/default_logo.png";
      if (invitation.image != undefined) {
        if (invitation.type && invitation.type === "customImage") {

          customImageUrl = invitation.image.url ? invitation.image.url : '';
          this.invitationprieviewimg = invitation.image.url ? invitation.image.url : '';
        }
        else {
          if (invitation.image) {
            invitationImg = invitation.image.url ? this.findOption("imgUrl", this.invitationImageTypes, "id") : this.findOption(invitation.image.type, this.invitationImageTypes, "id");
            imgurl = invitation.image.url ? invitation.image.url : '';
            //this.invitationprieviewimg = invitation.image.url ? invitation.image.url : '';
            this.invitationprieviewimg = invitation.image.url ? invitation.image.url : this.invitationprieviewimg;
          }
        }
      }
      this.toolBarInvitationSettings = {
        invitationType: invitation.type ? invitation.type === "customImage" ? this.invitationTypes[1].value : this.invitationTypes[0].value : this.invitationTypes[0].value,
        invitationImage: invitationImg,
        url: imgurl,
        customImageUrl: customImageUrl,
        margin: invitation.hasOwnProperty("marginToolbar") ? invitation.marginToolbar : 2,
        leftMargin: invitation.hasOwnProperty("margin") ? invitation.margin : 20,
        styles: invitation.styles ? this.invitationStyles[1].value : this.invitationStyles[0].value,
        bgColor: invitation.styles ? invitation.styles.bodyBackground["background-color"] : '#eeeeee',
        bgTextColor: invitation.styles ? invitation.styles.bodyText.color : '#272727',
        buttonBgColor: invitation.styles ? invitation.styles.acceptButton.background : '#dddddd',
        buttonBgTextColor: invitation.styles ? invitation.styles.acceptButton.color : '#272727',
        croppedCustomImgUrl: customImageUrl,
        croppedInvUrl: imgurl
      }

    }
    let marginvalue = widgetforEdit.clientConfigJson.toolbar.margin ? widgetforEdit.clientConfigJson.toolbar.margin : 40;
    //set priview for toolbar 
    this.toolBarPreviewSettings = {
      bgColor: this.bgcolornew,
      // bgColor: this.toolBarStyleSettings.bgColor.colorCode,
      borderRadius: this.toolBarStyleSettings.roundedCorners ? 5 : 0,
      textColor: this.bgtextnew,
      font: this.toolBarStyleSettings.textFont,
      gradient: this.toolBarStyleSettings.gradient,
      bottom: 0,
      top: 'auto',
      left: 'auto',
      right: 0,
      // margin: this.setMargin(widgetforEdit.clientConfigJson.toolbar.margin,widgetforEdit.clientConfigJson.toolbar.position),
      // marginValue: widgetforEdit.clientConfigJson.toolbar.margin,
      margin: this.setMargin(marginvalue, widgetforEdit.clientConfigJson.toolbar.position),
      marginValue: marginvalue,
      toolBarHeight: this.toolBarStyleSettings.barThickness.height,
      toolBarWidth: 'fit-content',
      toolTip: {
        // bgColor: this.toolBarToolTipStyleSettings.bgColor.colorCode,
        bgColor: this.ToolTipcolornew,
        borderRadius: this.toolBarToolTipStyleSettings.roundedCorners ? 5 : 0,
        // textColor: this.toolBarToolTipStyleSettings.textColor.colorCode,
        textColor: this.ToolTiptextnew,
        gradient: true,
      }
    }

  }

  setMargin(margin, position) {
    if (position === "right top" || position === "left top") {
      return margin + "px 0 0 0";
    }
    else if (position === "left bottom") {
      return "0 0 0 " + margin + "px";
    }
    else if (position === "right bottom") {
      return "0 " + margin + "px 0 0";
    }
  }
  setIcon(widgetforEdit, appstore) {
    if (widgetforEdit.clientConfigJson.icon.iconLinks != null) {
      let iconimgUrl = widgetforEdit.clientConfigJson.icon.iconLinks.offline;
      let icononlineimgUrl = widgetforEdit.clientConfigJson.icon.iconLinks.online;
      if (iconimgUrl != "") {
        this.selectedIcon = this.findOption(iconimgUrl, this.icons, "image")
      } else if (icononlineimgUrl != "") {
        let custome = (this.findOption(icononlineimgUrl, this.icons, "image"));
        let indexid = custome.id - 1;
        this.selectedIcon = this.icons[indexid];
      }

    } else
      this.selectedIcon = undefined;

 
    //set widget data for edit
    this.iconDetails = {
      uid: widgetforEdit.uid,
      accountUid: widgetforEdit.accountUid,
      websiteUid: widgetforEdit.websiteUid,
      department: this.findOption(widgetforEdit.departmentUid, this.deptDrpDwnOptions, "department_uid"),
      operator: this.findOption(widgetforEdit.operatorUid, this.operatorDrpDwnOptions, "user_uid"),
      installationName: widgetforEdit.title,
      language: this.findOption(widgetforEdit.language, appstore.languages, "value"),
      //themeChoosen : this.findOption(widgetforEdit.clientConfigJson.color, this.widgetThemes,"theme"),
      preChatEnabled: widgetforEdit.enablePrechat == 1 ? true : false,
      preChatToUsers: widgetforEdit.alwaysShowPrechat == 1 ? true : false,
      offlineHidden: widgetforEdit.clientConfigJson ? widgetforEdit.clientConfigJson.icon.hideWhenOffline : false,
      enableVirtualAgent: widgetforEdit.enableVirtualAgent == 1 ? true : false,
      enableChatBot : widgetforEdit.enableChatBot == 1 ? true : false,
    }
    //set selected langauge
    appstore.setLanguage(this.iconDetails.language);
    //this.selectedLanguage=this.languages[0];    
  }

  setDirectLink(widgetforEdit, appstore) {
    const obj = widgetforEdit.clientConfigJson;

    if ((Object.keys(obj).length === 0 && obj.constructor === Object) || obj == null) {
      this.directLinkDetails.uid = widgetforEdit.uid;
      this.directLinkDetails.accountUid = widgetforEdit.accountUid;
      this.directLinkDetails.websiteUid = widgetforEdit.websiteUid;
      return;
    }
    //set widget data for edit
    const barColor = widgetforEdit.clientConfigJson.toolbarDirect.style.color;
    const textColor = widgetforEdit.clientConfigJson.toolbarDirect.style.textColor;
    const fontValue = widgetforEdit.clientConfigJson.toolbarDirect.style.fontFamily;
    //check for custom
    let topBarcolor;
    let topBarTextcolor;
    if (barColor[0] === "#") {
      topBarcolor = this.findOption("custom", this.topBarColors, "value");
      topBarcolor.colorCode = barColor;
    } else {
      topBarcolor = this.findOption(barColor, this.topBarColors, "value");
    }
    if (textColor[0] === "#") {
      topBarTextcolor = this.findOption("custom", this.topBarTextColors, "value");
      topBarTextcolor.colorCode = textColor;
    } else {
      topBarTextcolor = this.findOption(textColor, this.topBarTextColors, "value");
    }

    this.directLinkDetails = {
      uid: widgetforEdit.uid,
      accountUid: widgetforEdit.accountUid,
      websiteUid: widgetforEdit.websiteUid,
      department: this.findOption(widgetforEdit.departmentUid, this.deptDrpDwnOptions, "department_uid"),
      operator: this.findOption(widgetforEdit.operatorUid, this.operatorDrpDwnOptions, "user_uid"),
      installationName: widgetforEdit.title,
      language: this.findOption(widgetforEdit.language, appstore.languages, "value"),
      preChatEnabled: widgetforEdit.enablePrechat == 1 ? true : false,
      preChatToUsers: widgetforEdit.alwaysShowPrechat == 1 ? true : false,
      //down key value need to be set after verifying,
      //in live application style section isnt working
      topBarColor: topBarcolor,
      topBarColorCustom: topBarcolor.colorCode,
      topBarTextColor: topBarTextcolor,
      topBarTextColorCustom: topBarTextcolor.colorCode,
      textFont: this.findOption(fontValue, this.textFonts, "value"),
      enableVirtualAgent: widgetforEdit.enableVirtualAgent == 1 ? true : false,
      enableChatBot : widgetforEdit.enableChatBot == 1 ? true : false,
    }

    //set selected langauge
    appstore.setLanguage(this.iconDetails.language);
    //this.selectedLanguage=this.languages[0];  
  }

  setWidgetIcon(widgetforEdit, appstore) {
    this.widgetIconDetails = {
      uid: widgetforEdit.uid,
      accountUid: widgetforEdit.accountUid,
      websiteUid: widgetforEdit.websiteUid,
      department: this.findOption(widgetforEdit.departmentUid, this.deptDrpDwnOptions, "department_uid"),
      operator: this.findOption(widgetforEdit.operatorUid, this.operatorDrpDwnOptions, "user_uid"),
      installationName: widgetforEdit.title,
      language: this.findOption(widgetforEdit.language, appstore.languages, "value"),
      preChatEnabled: widgetforEdit.enablePrechat == 1 ? true : false,
      preChatToUsers: widgetforEdit.alwaysShowPrechat == 1 ? true : false,
      enableVirtualAgent: widgetforEdit.enableVirtualAgent == 1 ? true : false,
      enableChatBot : widgetforEdit.enableChatBot == 1 ? true : false,
    }
    for(let i in this.widgetRedirectThemes){
      if(this.widgetRedirectThemes[i].theme==widgetforEdit.clientConfigJson.color){ 
        widgetforEdit.clientConfigJson.color= this.widgetRedirectThemes[i].redirect_theme;
        break;
      }
    }
    //set theme 
    this.widgetDetails.themeChoosen = this.findOption(widgetforEdit.clientConfigJson.color, this.widgetThemes, "theme");


    if (widgetforEdit.clientConfigJson.icon.iconLinks != null) {
      let iconimgUrl = widgetforEdit.clientConfigJson.icon.iconLinks.offline;
      let icononlineimgUrl = widgetforEdit.clientConfigJson.icon.iconLinks.online;
      if (iconimgUrl != "") {
        this.selectedIcon = this.findOption(iconimgUrl, this.icons, "image")
      } else if (icononlineimgUrl != "") {
        let custome = (this.findOption(icononlineimgUrl, this.icons, "image"));
        let indexid = custome.id - 1;
        this.selectedIcon = this.icons[indexid];
      }

    } else{
      this.selectedIcon = this.icons[0];
    }
      // this.selectedIcon = undefined;

    if (widgetforEdit.clientConfigJson.widget) {
      this.widgetIconDetails.showInvitation = widgetforEdit.clientConfigJson.widget.enablePopupInvitation;
      this.widgetIconDetails.offlineHidden = widgetforEdit.clientConfigJson.widget.hideWhenOffline;
      if (widgetforEdit.clientConfigJson.widget.position) {
        this.widgetDetails.widgetPosition = widgetforEdit.clientConfigJson.widget.position === 'bottom right' ? 'Bottom Right' : 'Bottom Left';
      }
    } else {
      this.widgetIconDetails.showInvitation = false;
      this.widgetIconDetails.offlineHidden = false;
      this.widgetDetails.widgetPosition = 'Bottom Right';
    }

    //set selected langauge
    appstore.setLanguage(this.widgetIconDetails.language);
  }

  setLanguage(lang, appstore, widget) {
    appstore.setLanguage(lang);
    widget.language = appstore.selectedLanguage;
  }

  findOption(check, options, value, iconscustom) {
    if (check === "") {
      return options[0];
    }
    else {
      let found = options.find(function (option) {
        return option[value] == check;
      });

      if (found == undefined) {
        return options[0];
      }
      else {
        return found;
      }
    }
  }

  saveWidgetRestcall(appstore, newWidgetdata, originalWidget) {
    axios({
      method: 'POST',
      url: appstore.url + 'saveWidget.json',
      headers: { 'content-type': 'application/json' },
      data: newWidgetdata,
    }).then(result => {
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      if (result.data == 1) {
        originalWidget.uid = newWidgetdata.uid;
        this.uid = newWidgetdata.uid;
        this.widgetCode = appstore.generateAssistCode(newWidgetdata.uid);
        this.saveLoader = false;
        this.getCode = true;
        // alert(this.icons.length);
        // this.storedofflineFileName = files[1] != "null" ? files[1] : "";
        // this.storedonlineFileName = files[0] != "null" ? files[0] : "";
        let newIcons = JSON.parse(JSON.stringify(this.icons));
        if (this.storediconuid != "") {
          this.pageisLoaded = true;
          if (this.selectedonlinefile != "") {
            let newimages = {
              image: appstore.customIconurl + this.storedonlineFileName, id: this.icons.length + 1,
              icon_uid: this.storediconuid, updateimageURLs: ""
            }
            newIcons.push(newimages);
          } else {
            let newimages = { image: "", id: this.icons.length + 1, icon_uid: this.storediconuid, updateimageURLs: "" }
            newIcons.push(newimages);
          }
          if (this.selectedofflinefile != "") {
            let newimages = {
              image: appstore.customIconurl + this.storedofflineFileName, id: this.icons.length + 1,
              icon_uid: this.storediconuid, updateimageURLs: ""
            }
            newIcons.push(newimages);
          } else {
            let newimages = { image: "", id: this.icons.length + 2, icon_uid: this.storediconuid, updateimageURLs: "" }
            newIcons.push(newimages);
          }
          this.icons = newIcons;
          this.pageisLoaded = false;
        }

        this.selectedofflinefile = "";
        this.selectedonlinefile = "";
        this.storedonlineFileName = "";
        this.storedofflineFileName = "";
        // this.pageisLoaded = true;
        // this.getallCustomIconByAccountId(appstore);

      } else {
        //show error 
        this.saveLoader = false;
        this.errorPopup = true;

      }
      //  this.saveinProcess=false;
      if (appstore.helpTourStarted) {
        this.widgetBuildertourSteps.stepIndex = 2
      }
      appstore.global_errorcount=0;
    }).catch((error) => {
      this.pageisLoaded = false;
      // if (error.response) {
      //   console.log(error);
      //   appstore.logout();
      // }
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })
  }
  deletefiles(resourceid, appstore) {
    axios({
      method: 'POST',
      url: appstore.url + 'deleteToolbarIcon.json',
      headers: { 'content-type': 'application/json' },
      data: { "deleteresourceUid": resourceid },
    }).then(result => {
      // console.log(result.data);
      appstore.global_errorcount=0;
    }).catch((error) => {
      // appstore.logout();
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })
  }

  saveToolbarIcon(formdata, appstore, index) {
    // this.iconnotLoaded=true; 
    axios({
      method: 'POST',
      url: appstore.url + 'saveToolbarIcon.json',
      headers: { 'content-type': 'multipart/form-data' },
      data: formdata,
    }).then(result => {
      let file = result.data.split("&");
      if (this.selectedToolBarSetting == 3) {
        this.toolBarPreviewButtonSettings[index].iconUrl = "https:" + file[0];
        if (index == 0) {
          this.chatresourceid = file[1];
        } else if (index == 1) {
          this.emailresourceid = file[1];
        } else if (index == 2) {
          this.callresourceid = file[1];
        } else if (index == 3) {
          this.faccebookresourceid = file[1];
        } else if (index == 4) {
          this.twitterresourceid = file[1];
        } else if (index == 5) {
          this.customeOneresourceid = file[1];
        } else if (index == 6) {
          this.customeTworesourceid = file[1];
        } else if (index == 7) {
          this.customeThreeresourceid = file[1];
        }
      }
      if (this.selectedToolBarSetting == 4) {
        if (index == 1) {
          // this.toolBarInvitationSettings.url = "http:" + file[0];
          // this.invitationimgUrl = file[1]
          // this.invitationprieviewimg = "http:" + file[0]
          this.toolBarInvitationSettings.url = file[0];
          this.invitationimgUrl = file[1]
          this.invitationprieviewimg =  file[0]
        }
        if (index == 2) {
          // this.toolBarInvitationSettings.customImageUrl = "http:" + file[0];
          // this.invitationCustUrl = file[1]
          // this.invitationprieviewimg = "http:" + file[0]
          this.toolBarInvitationSettings.customImageUrl =  file[0];
          this.invitationCustUrl = file[1]
          this.invitationprieviewimg =  file[0]
        }
      }

      // this.iconnotLoaded=false;
      appstore.global_errorcount=0;
    }).catch((error) => {
      // console.log(error);
      // appstore.logout();
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })
  }

  getColorforValue(color, textcolor) {
    let foundcolor;
    let foundtextcolor;

    var topBarColorsdefult = { "blue": "#0481B0", "green": "#608706", "pink": "#B0097D", "orange": "#D6680B", "red": "#9D0A09", "teal": "#434E55", "custom": "#000000", "black": "#000000" }
    var topBarTextColorsdefult = { "black": "#000000", "white": "#FFFFFF", "custom": "#000000" };
    if (typeof (color) == "string") {
      if (!topBarColorsdefult.hasOwnProperty(color)) {
        if (/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color) == false) {
          color = "#" + color;
        }
      }
    }
    if (typeof (textcolor) == "string") {
      if (!topBarTextColorsdefult.hasOwnProperty(textcolor)) {
        if (/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(textcolor) == false) {
          textcolor = "#" + textcolor;
        }
      }
    }

    if (color[0] === "#") {
      foundcolor = this.findOption("custom", this.topBarColors, "value");
      foundcolor.colorCode = color;
    } else
      foundcolor = this.findOption(color, this.topBarColors, "value");

    // if (textcolor[0] === "#") {
    var conditionvalidate = true;
    if (typeof (textcolor) == "string") {
      conditionvalidate = textcolor.indexOf("#") != -1
    }
    if (textcolor != undefined && conditionvalidate) {
      foundtextcolor = this.findOption("custom", this.topBarTextColors, "value")
      foundtextcolor.colorCode = textcolor;
    } else
      foundtextcolor = this.findOption(textcolor, this.topBarTextColors, "value");


    if (typeof (foundcolor) == "object") {
      if (typeof (foundcolor.colorCode) == "object") {
        foundcolor = foundcolor.colorCode;
      }
    }

    if (typeof (foundtextcolor) == "object") {
      if (typeof (foundtextcolor.colorCode) == "object") {
        foundtextcolor = foundtextcolor.colorCode;
      }
    }
    return { "foundcolor": foundcolor, "foundtextcolor": foundtextcolor };
  }

  getOpforDept = (appstore, widget, selectedOption) => {
    widget.department = selectedOption;
    widget.operator = this.operatorDrpDwnOptions[0];
    if (widget.department.department_uid == "") {
      this.operatorDrpDwnOptions = appstore.deptOps.allOps;
      return;
    }
    const selectedOptions = {
      department_uid: widget.department.department_uid,
      website_uid: ""
    }
    axios({
      method: 'POST',
      url: appstore.url + "getDropdownDataforAdmin.json",
      headers: { 'content-type': 'application/json' },
      data: selectedOptions,
    }).then(result => {
      if(result.data.length==1){
        if(result.data.logoutstatus==1){
          appstore.logout();
        }
        }
      let opt = result.data.usersmodel;
      let allopt = { "label": "All Operators", "value": "", "user_uid": "" };
      opt.splice(0, 0, allopt);

      for (let x in opt) {
        if (x > 0) {
          opt[x].label = opt[x].nick_name;
          opt[x].value = opt[x].user_uid;
          opt[x].user_uid = opt[x].user_uid;
        }
      }
      this.operatorDrpDwnOptions = opt;
      appstore.global_errorcount=0;
    }).catch((error) => {
      // if (error.response) {
      //   console.log(error)
      //   appstore.logout();
      // }
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    });
  }

  setCustomCallScheduletext = () => {
    let weekDays = this.weekDays;
    let schedule = {}

    for (let i = 0; i < weekDays.length; i++) {
      let list = {}
      list["enabled"] = weekDays[i].selected;
      list["since"] = weekDays[i].startTime;
      list["till"] = weekDays[i].EndTime;
      if (weekDays[i].selected) {
        schedule[weekDays[i].value] = weekDays[i].startTime + "-" + weekDays[i].EndTime;
      }

    }

    let weeknames = { "sunday": ["Sun", 0], "monday": ["Mon", 1], "tuesday": ["Tue", 2], "wednesday": ["Wed", 3], "thursday": ["Thu", 4], "friday": ["Fri", 5], "saturday": ["Sat", 6] };
    let weekString = "";
    let previousValue = "";
    let previousDay = "";
    for (let day in schedule) {
      if (schedule[day] === previousValue && weeknames[day][1] - weeknames[previousDay][1] == 1) {

        let pos = weekString.indexOf(weeknames[previousDay][0]);
        if (pos && weekString[pos - 1] === "-") {
          weekString = weekString.replace(weeknames[previousDay][0], weeknames[day][0]);
        }
        else {
          weekString = weekString.replace(weeknames[previousDay][0], weeknames[previousDay][0] + "-" + weeknames[day][0])

        }

      }
      else
        weekString = weekString + weeknames[day][0] + " " + schedule[day] + ", ";

      previousValue = schedule[day];
      previousDay = day;

    }
    return weekString;
  }

}

decorate(WidgetStore, {
  websiteWidgetDetails: observable,
  directLinkDetails: observable,
  topBarColors: observable,
  topBarTextColors: observable,
  topBarColorSelector: observable,
  topBarTextColorSelector: observable,
  widgetDetails: observable,
  iconDetails: observable,
  widgetIconDetails: observable,
  selectedWidgetIcon: observable,
  selectedWidget: observable,
  getCode: observable,
  sendCode: observable,
  selectedToolBarSetting: observable,
  iconScroll: observable,
  toolBarGeneralSettings: observable,
  toolBarPreviewButtonSettings: observable,
  toolBarPreviewSettings: observable,
  toolBarStyleSettings: observable,
  toolBarThickness: observable,
  bgColorSelector: observable,
  textColorSelector: observable,
  selectedIcon: observable,
  toolTipBgSelector: observable,
  toolTipTextColorSelector: observable,
  toolBarToolTipStyleSettings: observable,
  getIconFromLibrary: observable,
  toolBarInvitationSettings: observable,
  selectedButtonIndex: observable,
  toolBarAddButton: observable,
  addedButtonCount: observable,
  editorState: observable,
  editing: observable,
  btnContentTypes: observable,
  buttonBgColorSelector: observable,
  buttonTextColorSelector: observable,
  getImageFromLibrary: observable,
  pageisLoaded: observable,
  widgetCode: observable,
  codeCopied: observable,
  saveLoader: observable,
  errorPopup: observable,
  callScheduleText: observable,
  operatorDrpDwnOptions: observable,
  selectedofflinefile: observable,
  selectedonlinefile: observable,
  imagewarning: observable,
  warning_image_name: observable,
  storedofflineFileName: observable,
  storedonlineFileName: observable,
  conformationpopup: observable,
  confirm: observable,
  icon_uid: observable,
  id: observable,
  mode: observable,
  icons: observable,
  cropimage: observable,
  imageCropModel: observable,
  imageRef: observable,
  deleteImg: observable,
  iconnotLoaded: observable,
  resourceid: observable,
  chatresourceid: observable,
  emailresourceid: observable,
  callresourceid: observable,
  faccebookresourceid: observable,
  twitterresourceid: observable,
  customeOneresourceid: observable,
  customeTworesourceid: observable,
  customeThreeresourceid: observable,
  resttoolBarAddButton: observable,
  resttoolBarPreviewButtonSettings: observable,
  resttoolBarInvitationSettings: observable,
  invitationCustUrl: observable,
  invitationimgUrl: observable,
  invitationImages: observable,
  widgetThemes: observable,
  storediconuid: observable,
  weekDays: observable,
  customeschedule: observable,
  customescheduleset: observable,
  weekDaysschedule: observable,
  clearweekDays: observable,
  clearweekDaysschedule: observable,
  invitationprieviewimg: observable,
  previewshow: observable,
  customselected: observable,
  errorMessageComponent: observable,
  errorMessage: observable,
  toolBarPreviewButtonposition: observable,
  toolBarCustomButtonIcons: observable,
  widgettourStart: observable,
  widgetBuildertourSteps: observable,
  widgetBuildertourStart: observable,
  uid: observable,
  toolBarFbButtonid: observable,
  toolBarcustomButton: observable,
  toolBarcustomButtonconform: observable,
  toobaredit: observable,
  toolBarStyleSettings_bgColorerror: observable,
  toolBarStyleSettings_bgtextColorerror: observable,
  toolBartooltip_bgColorerror: observable,
  toolBartooltip_bgtextColorerror: observable,
  inv_bgColorerror: observable,
  inv_bgtextColorerror: observable,
  inv_tootltipbgColorerror: observable,
  inv_tootltipbgtxtColorerror: observable,
  colorpickerevent: observable,
  bgcolornew: observable,
  ToolTipcolornew: observable,
  bgtextnew: observable,
  ToolTiptextnew: observable,
  validateChatbot_VA_Enable: observable,
})

export default WidgetStore;