import { observer } from "mobx-react";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import imageURLs from '../../../sharedFiles/ImagesURLs';

const SubpageModal = observer(({ show, handleClose, selectedMainUrl, subPages, appstore, scrapWebsiteStore }) => {
  const handleCheckboxChange = (url) => {
    scrapWebsiteStore.handleCheckboxChange(url, false);
  };

  const { subCheckboxStates } = scrapWebsiteStore;

  return (
    <Modal backdrop='static' className={(appstore ? appstore.selectedColor.value : '')} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedMainUrl}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "400px", overflow: "auto", scrollbarColor: appstore.selectedColor.color + " transparent", scrollbarWidth: "thin" }}>
        {subPages.map((subPage, index) => (
          <div className="row">
            <div key={index} className={`panelWrapper col-12 col-lg-12 p-0 ${index % 2 === 0 ? 'odd' : ' even'}`} style={{ marginBottom: "10px" }}>
              <div className="col-10" style={{width:'min-content',margin:'6px'}}>
                <a target="_blank" href={subPage.url}><span style={{cursor: 'pointer',wordWrap: 'break-word'}} >{subPage.url}</span></a>
                {/* {subPage.filePath && ( // Check if file path exists
                  <span> - File Path: {subPage.filePath}</span>
                )}  */}
              </div>
              <div className="col-2 subcheckboxalign">
                <label className="switch">
                  <input type="checkbox" checked={subCheckboxStates[subPage.url]} onChange={() => handleCheckboxChange(subPage.url)} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>          
        <div className="row m-0">
          <div className="col-12 formBtns">
            <div className="pullRight">
              <Button className="btn_clr" variant="light" onClick={handleClose}>
                <p>{appstore.getLanguageTrans("Close")}</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
});

export default SubpageModal;
