import { observer } from "mobx-react";
import React, { Component } from "react";
import ScrapWebsiteStore from "./ScrapWebsiteStore";
import imageURLs from '../../../sharedFiles/ImagesURLs';
import HomeComponentStore from "../../homeComponent/HomeComponentStore";
import { GridLoader } from 'react-spinners';
import { css } from '@emotion/core';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import ChatBotPreviewComponent from "./ChatBotPreviewComponent";
import ChatBotpreviewStore from "./ChatbotpreviewStore";

const override = css`
  position: absolute; 
  top: 289px;
  z-index: 999;
  left: 35%; 
  opacity: 0.6;
  position: relative;
`;


const ChatbotHomeComponent = observer(class ChatbotHomeComponent extends Component {
  constructor(props) {
    super(props);
    this.scrapWebsiteStore = new ScrapWebsiteStore();
    this.homeComponentStore = new HomeComponentStore(this.props.appstore);
    this.chatBotpreviewStore = new ChatBotpreviewStore(this.props.appstore);
    this.state = {
      websiteUid:this.props.appstore.selectedWebisiteUid,
      location :  window.location.pathname
    }
  }

  componentDidMount() {    
    this.props.appstore.menuChanged = window.location.pathname;
    this.scrapWebsiteStore.fetchWebsites(this.props.appstore);
  }

  addNewSite = () => {
    //this.props.appstore.addNewSite = true;
    this.props.appstore.navigate('/admin/ChatBot');
  }
  editSite = (ai_chatbotuid) => {
    this.props.appstore.ai_chatbotuid=ai_chatbotuid;
    
    this.props.appstore.navigate('/admin/ChatBot/edit');
  }

  checkchatbotdowngrade = () => {  
    
    if(this.props.appstore.userInfo.accountsmodel.chatbotusedcount > this.props.appstore.userInfo.accountplan.max_chatbot){      
      return true;
    }else{      
      return false;
    }    
  }

  validateEnablechatbot = () => {      
    if(this.props.appstore.userInfo.accountsmodel.enabled_chatbotcount > this.props.appstore.userInfo.accountplan.max_chatbot){      
      return true;
    }if(this.props.appstore.userInfo.accountsmodel.enabled_chatbotcount == this.props.appstore.userInfo.accountplan.max_chatbot){
      return true;
    }
    else{      
      return false;
    }    
  }

  render() {
    const { appstore } = this.props;   
    const { websitesdetails, websiteurlloading} = this.scrapWebsiteStore;  

    if (!this.scrapWebsiteStore.ispageLoaded) {
      return (
        <div className="adminloader">
          <GridLoader css={override} size={20} margin={2} color={appstore.selectedColor.color} />
        </div>
      );
    }

    return (
      <div className="innercontent_wrapper w-100">
        <div className="websitesWrapper">          
          <div className="websiteDetailsWrapper row">                   
              {websitesdetails.map((website, index) => (                
                 <div key={index} className="websitesDetails col-12 col-sm-6 col-md-4">
                      <div className="box col-12" style={{ paddingTop: 20, paddingBottom: 20}}>                        
                        <h4 style={{paddingBottom:"20px", wordWrap: "break-word", hyphens: "auto"}}>{website.websiteurl_name}</h4>                        
                          <div className="actionsWrapper col-12" style={{paddingBottom:"10px"}}>
                              <div className="actions row"> 
                              {(!this.checkchatbotdowngrade() && website.chatbot_active_status=='Yes') &&
                                <p className="col-12" style={{textAlign:"center"}}>                                 
                                    <span onClick={() => this.editSite(website.ai_chatbotuid)}>
                                      <img src={imageURLs.editWhiteIcon} className="img-fluid" />
                                    {appstore.getLanguageTrans("Edit")}</span>
                                </p>
                              } {(!this.checkchatbotdowngrade() && website.chatbot_active_status=='No') &&
                                <p className="col-6" style={{textAlign:"right"}}>                                 
                                    <span onClick={() => this.editSite(website.ai_chatbotuid)}>
                                      <img src={imageURLs.editWhiteIcon} className="img-fluid" />
                                    {appstore.getLanguageTrans("Edit")}</span>
                                </p>
                              }
                              {(this.checkchatbotdowngrade()) &&
                                <p className="col-6" style={{textAlign: "right"}}>                                 
                                    <span onClick={() => this.editSite(website.ai_chatbotuid)}>
                                      <img src={imageURLs.editWhiteIcon} className="img-fluid" />
                                    {appstore.getLanguageTrans("Edit")}</span>
                                </p>  
                              }
                              {(this.checkchatbotdowngrade() && website.chatbot_active_status ==='Yes') &&                          
                                <p className="col-6">
                                    <span onClick={() =>{this.scrapWebsiteStore.disablechatbotAction(website)}}><img src={imageURLs.deleteWhiteIcon} className="img-fluid" />
                                      {appstore.getLanguageTrans("Disable")}</span>
                                </p>  
                              }     
                              {(this.validateEnablechatbot() && website.chatbot_active_status==='No') &&
                                <p className="col-6">                                  
                                  <span data-tooltip={appstore.getLanguageTrans("Please upgrade your plan.")} data-tooltip-conf="top" style={{ cursor: "not-allowed", opacity: 0.5 }} >
                                    <img src={imageURLs.deleteWhiteIcon} className="img-fluid" />
                                    {appstore.getLanguageTrans("Enable")}
                                  </span>
                                </p>
                              }  
                              {(!this.validateEnablechatbot() && website.chatbot_active_status==='No') &&
                                <p className="col-6"> 
                                  <span onClick={() =>{this.scrapWebsiteStore.enablechatbotAction(website)}}>                                                                 
                                    <img src={imageURLs.deleteWhiteIcon} className="img-fluid" />
                                    {appstore.getLanguageTrans("Enable")}   
                                    </span>                               
                                </p>
                              }
                              </div>
                          </div>
                          <div className="col-12 overview">
                            <div className="row">
                              <p className="col-4">
                              <font size="2">{website.total_page_no}</font>
                                <br/>
                                <span>{appstore.getLanguageTrans("Total Pages")}</span>
                              </p>
                              <p className="col-4">
                              <font size="2">{website.embed_status}</font>
                                <br/>
                                <span>{appstore.getLanguageTrans("Status")}</span>
                              </p>
                              <p className="col-4"><br/></p>                            
                            </div>
                        </div>
                    </div>
                  </div>
              ))}  

          { websitesdetails.length==0 && this.props.appstore.userInfo.accountsmodel.chatbotusedcount < this.props.appstore.userInfo.accountplan.max_chatbot  &&
						<div className="websitesDetails col-12 col-sm-6 col-md-4">
              <div className="box col-12" style={{ paddingTop: 72, paddingBottom: 45, cursor: "pointer" }}>
                <div className="noneWrapper" onClick={() =>this.addNewSite()}>
                    <img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
                      "#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.mute_steel_blue2} alt="" className="img-fluid" />
                    <p style={{color:appstore.selectedColor.color,fontfamily:"Segoe UI Bold",fontSize:13,fontWeight:700,padding:13,textTransform:"uppercase",fontFamily:"Segoe UI Bold"}}>{appstore.getLanguageTrans("Setup ChatBot")}</p>
                </div>
              </div>
            </div>
					}
          {websitesdetails.length==0 && this.props.appstore.userInfo.accountplan.max_chatbot == this.props.appstore.userInfo.accountsmodel.chatbotusedcount &&
            <div className=" websitesDetails col-12 col-sm-6 col-md-4">
              <div className="addNewWebsite_tour box col-12">
                <div className="addWrapper" onClick={() =>this.homeComponentStore.payDollar=true}>
                  <center>
                    <p className="websitePayDollar">$</p>
                  </center>
                </div>
              </div>
            </div>
          }
          {websitesdetails.length >0 &&
            <div key={appstore.selectedWebisiteUid} className="preview col-12 col-lg-6 d-none d-lg-block" >
                    <ChatBotPreviewComponent scriptStore = {this.chatBotpreviewStore} appstore={appstore} />
            </div>  
          }
          {
            this.scrapWebsiteStore.disablechatbot &&
            <ConfirmationComponent show={this.scrapWebsiteStore.disablechatbot}
            store={this.scrapWebsiteStore}
            title="Disable ChatBot"
            body_html="<p>Are you sure you want to disable the chatbot.<p>"
            cancel_btn="Cancel"
            save_btn="Disable" 
            callBack={() => this.scrapWebsiteStore.confirmdisableOREnablechatbot(appstore,"disable")}
            closePopup={() => this.scrapWebsiteStore.disablechatbot=false}
            appStore = {appstore}/>
				  }
          {
            this.scrapWebsiteStore.enablechatbot &&
            <ConfirmationComponent show={this.scrapWebsiteStore.enablechatbot}
            store={this.scrapWebsiteStore}
            title="Enable ChatBot"
            body_html="<p>Are you sure you want to enable the chatbot.<p>"
            cancel_btn="Cancel"
            save_btn="Enable" 
            callBack={() => this.scrapWebsiteStore.confirmdisableOREnablechatbot(appstore,"enable")}
            closePopup={() => this.scrapWebsiteStore.enablechatbot=false}
            appStore = {appstore}/>
				  }       
        </div>  
      </div>
    </div>
    );
  }
});

export default ChatbotHomeComponent;
