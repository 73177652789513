import { observer } from "mobx-react";
import React, { Component } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FadeLoader } from "react-spinners";
import ScrapWebsiteStore from "./ScrapWebsiteStore";
import imageURLs from '../../../sharedFiles/ImagesURLs';
import SubpageModal from "./SubpageModal";
import axios from 'axios';
import navigationStore from '../../../sharedFiles/navigation-store';
import ConfirmationAIModalComponent from "./confirmationAIModal";
import { GridLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
  position: absolute; 
  top: 289px;
  z-index: 999;
  left: 35%; 
  opacity: 0.6;
  position: relative;
`;

const ScrapWebsiteComponent = observer(class ScrapWebsiteComponent extends Component {
  constructor(props) {
    super(props);
    this.scrapWebsiteStore = new ScrapWebsiteStore();
  }

  componentDidMount() {
    this.props.appstore.menuChanged = window.location.pathname;  
    this.scrapWebsiteStore.updateState({ispageLoaded: true});  
  }

  handleChange = (event) => {
    this.scrapWebsiteStore.setWebsitesUrl(event.target.value);
  }

  cancelForm = () => {
    this.scrapWebsiteStore.resetForm();
  }

  handleFormSubmit = async () => {
    let newWebsite = this.scrapWebsiteStore.websitesUrl.urlname.trim();

    if (newWebsite === "") {
      this.scrapWebsiteStore.updateState({ validationError: "Website URL is required." });
      return;
    }

    const httpRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/;
    const wwwRegex = /^(www\.)/;
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}$/i;

    if (!httpRegex.test(newWebsite)) {
      if (wwwRegex.test(newWebsite)) {
        newWebsite = "https://" + newWebsite;
      } else {
        newWebsite = "https://www." + newWebsite;
      }
    }

    await this.scrapWebsiteStore.validateurlExist(newWebsite,this.props.appstore);

    if (this.scrapWebsiteStore.validationurlcount>0) {
      this.scrapWebsiteStore.updateState({ validationError: "This website URL already exists." });
      return;
    }

    this.scrapWebsiteStore.updateState({ validationError: "", loading: true });
    this.getwebCrawlerPage(newWebsite);
    this.scrapWebsiteStore.websitesUrl.newWebsite = newWebsite;
  };

  handleCheckboxChange = (url, isMainUrl = true) => {
    this.scrapWebsiteStore.handleCheckboxChange(url, isMainUrl);
  };

  handleMainUrlClick = (mainUrl) => {
    const { mainAndSubUrls } = this.scrapWebsiteStore;
    const subPages = mainAndSubUrls[mainUrl] || [];
    this.scrapWebsiteStore.updateState({ showModal: true, selectedMainUrl: mainUrl, subPages });
  };

  closeModal = () => {
    this.scrapWebsiteStore.updateState({ showModal: false });
  };
  

  getwebCrawlerPage = (websiteURL) => {    
    this.scrapWebsiteStore.updateState({ showCards: true });
    const user_id = this.props.appstore.userInfo.accountsmodel.account_uid;
    fetch('https://node.dev.helponclick.com/web-crawler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        websiteurl: websiteURL,
        user_id: user_id
      })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      if (data && data.websitescrap_pagelist) {
        const { websitescrap_pagelist } = data;
        const mainAndSubUrls = websitescrap_pagelist;
        const mainUrls = Object.keys(mainAndSubUrls);
        const checkboxStates = {};
        const subCheckboxStates = {};
        const mainPagesWithCount = {};
        
        let totalTokenCount = 0;
  
        mainUrls.forEach(mainUrl => {
          checkboxStates[mainUrl] = true;
          const subUrls = mainAndSubUrls[mainUrl].map(subUrlObj => {
            const { url, path, tokensize } = subUrlObj;
            subCheckboxStates[url] = true;
            totalTokenCount += tokensize;
            return { mainUrl, url, filePath: path, tokensize }; // Use filePath here
          });
          mainPagesWithCount[mainUrl] = subUrls.length;
        });
  
        const continueDisabled = totalTokenCount > 10000;
        const tooltipMessage = continueDisabled ? "Your token limit exceeded" : "";
  
        this.scrapWebsiteStore.updateState({
          submittedWebsites: mainUrls,
          checkboxStates,
          subCheckboxStates,
          loading: false,
          mainAndSubUrls,
          mainPagesWithCount,
          urlToFilePathMap: websitescrap_pagelist,
          totalTokenCount,
          continueDisabled,
          tooltipMessage
        });

         // Check if the total page count is zero and show the modal if true
         let scrapedpageCount = Object.values(mainPagesWithCount).reduce((total, count) => total + count, 0);
         if (scrapedpageCount === 0) {
           this.scrapWebsiteStore.updateState({ showConfirmationModal: true });
         }
      } else {
        console.error('websitescrap_pagelist is not available in the response:', data);
        this.scrapWebsiteStore.updateState({ loading: false });
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      this.scrapWebsiteStore.updateState({ loading: false });
    });
  };
  

   createEmbeddings = async () => {
    this.scrapWebsiteStore.ai_chatbotuid=this.props.appstore.generateUid();    
    const { mainAndSubUrls, subCheckboxStates } = this.scrapWebsiteStore;
  
    const mainUrlStatusMap = {};
    const subUrlStatusMap = {};    
    this.scrapWebsiteStore.updateState({ispageLoaded: false});
  
    Object.keys(mainAndSubUrls).forEach(mainUrl => {
      const subUrls = mainAndSubUrls[mainUrl];
      const checkedSubUrls = subUrls.filter(subUrl => subCheckboxStates[subUrl.url]);
  
      const mainUrlStatus = checkedSubUrls.length > 0 ? 'yes' : 'no';
      mainUrlStatusMap[mainUrl] = mainUrlStatus;
  
      subUrlStatusMap[mainUrl] = subUrls.map(subUrl => ({
        mainUrl, // Include mainUrl here
        url: subUrl.url,
        filePath: subUrl.filePath, // Use filePath here
        tokensize: subUrl.tokensize,
        status: subCheckboxStates[subUrl.url] ? 'yes' : 'no'
      }));
    });    
    const formattedSubUrls = Object.entries(subUrlStatusMap).map(([mainUrl, subUrls]) => {
      return subUrls.map(subUrl => JSON.stringify(subUrl)).join('-');
    }).join('@');
  
    const allFilePaths = [];
  
    Object.values(subUrlStatusMap).forEach(subUrls => {
      subUrls.forEach(subUrl => {
        allFilePaths.push(subUrl);
      });
    });
    // Process embeddings or direct insertion based on status
    await this.processEmbeddingsAndInsert(allFilePaths, mainUrlStatusMap, formattedSubUrls);
  };
  

   processEmbeddingsAndInsert = async (allFilePaths, mainUrlStatusMap, formattedSubUrls) => {   
    const websiteurl = this.scrapWebsiteStore.websitesUrl.newWebsite;
    
    allFilePaths.forEach(filePathObj => {
      const mainUrl = filePathObj.mainUrl;
      filePathObj.scrapstatus = mainUrlStatusMap[mainUrl] === 'yes' ? 'yes' : 'no';; // Add scrapstatus to each object
    });
  
    // const processNextFile = (index) => {
    //   if (index >= allFilePaths.length) {
    //     this.updateStatusAsCompleted();
    //     return; 
    //   }      
    //   const { mainUrl,url ,filePath, status ,scrapstatus,tokensize} = allFilePaths[index];        
    //   const embdstatus=status;
    //   if (embdstatus === 'yes') {
    //     this.performEmbedding(mainUrl,url ,filePath, embdstatus,scrapstatus,tokensize,() => processNextFile(index + 1));
    //   } else {
    //     this.insertIntoDatabase(mainUrl,url ,filePath, embdstatus,scrapstatus,tokensize,() => processNextFile(index + 1),"","");
    //   }
    // };
    // processNextFile(0);

     // Process each file sequentially with async/await
    for (let index = 0; index < allFilePaths.length; index++) {
      const { mainUrl, url, filePath, status, scrapstatus, tokensize } = allFilePaths[index];
      const embdstatus = status;

      if (embdstatus === 'yes') {
        await this.performEmbedding(mainUrl, url, filePath, embdstatus, scrapstatus, tokensize);
      } else {
        await this.insertIntoDatabase(mainUrl, url, filePath, embdstatus, scrapstatus, tokensize, "", "");
      }

      await this.scrapWebsiteStore.fetchWebsites(this.props.appstore);
      
      if (this.scrapWebsiteStore.websitesdetails.length > 0) {
        this.scrapWebsiteStore.updateState({ ispageLoaded: true });
        navigationStore.push('/admin/ChatBot/ChatbotHomeComponent');
      } else {        
        this.scrapWebsiteStore.updateState({ ispageLoaded: false });
      }
    }
    this.updateStatusAsCompleted();
  };
  
  performEmbedding = async (mainUrlName,webSiteurl, filePath,embdstatus,scrapstatus,tokensize) => {
    fetch('https://node.dev.helponclick.com/create-embeddings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filePath : filePath,
        user_id: this.props.appstore.userInfo.accountsmodel.account_uid,
        websiteurl: webSiteurl // Use the mainUrl here for embedding
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      console.log("Embedding Completed for filePath:", data,data.scrapedFilePath);
      this.insertIntoDatabase(mainUrlName,webSiteurl, filePath,embdstatus,scrapstatus,tokensize,data.status,data.scrapedFilePath);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      this.insertIntoDatabase(mainUrlName,webSiteurl, filePath,embdstatus,scrapstatus,tokensize,"failed",null); // Proceed to the next file even if there's an error
    });
  };

  insertIntoDatabase = async (mainUrlName,webSiteurl, scrapfilePath,embdstatus,scrapstatus,tokensize,embdRemarks,embdFilepath) => {
    const { account_uid} = this.props.appstore.userInfo.accountsmodel;
    const formData = new FormData();
    formData.append("user_uid", account_uid);
    formData.append("website_uid", this.props.appstore.selectedWebisiteUid);
    formData.append("websiteScrapURL",this.scrapWebsiteStore.websitesUrl.newWebsite);
    formData.append("total_page_no",this.scrapWebsiteStore.totalpagecount);
    formData.append("ai_chatbotuid",this.scrapWebsiteStore.ai_chatbotuid);
    formData.append("websiteurl_name", mainUrlName);
    formData.append("websiteURL", webSiteurl);
    formData.append("webscrapfilePath", scrapfilePath);
    formData.append("webscrapStatus", scrapstatus);
    formData.append("webembdstatus", embdstatus);
    formData.append("webembdFilepath", embdFilepath);
    formData.append("webembedRemarks", embdRemarks);
    formData.append("websitescraptokensize",tokensize);
    formData.append("status",0);
  
    axios({
      method: 'POST',
      url: this.props.appstore.url + "savewebsiteurl.json",
      headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
      data: formData,
    })
    .then((result) => {
      //console.log("Data successfully saved to the database for filePath:", filePath);
    //  callback();
    }).catch((error) => {
      console.error('There was a  problem with the save operation:', error);
     // callback(); // Proceed to the next file even if there's an error
    });
  };

  updateStatusAsCompleted = () => {
    const { account_uid } = this.props.appstore.userInfo.accountsmodel;
    const formData = new FormData();
    formData.append("user_uid", account_uid);
    formData.append("website_uid", this.props.appstore.selectedWebisiteUid);
    formData.append("websiteScrapURL",this.scrapWebsiteStore.websitesUrl.newWebsite);
    formData.append("ai_chatbotuid",this.scrapWebsiteStore.ai_chatbotuid);
    formData.append("status",1);
  
    axios({
      method: 'POST',
      url: this.props.appstore.url + "savewebsiteurl.json",
      headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
      data: formData,
    }) .then((result) => {
      //console.log("Data successfully updated to the database for filePath:", filePath);
    //  callback();
    }).catch((error) => {
      console.error('There was a  problem with the save operation:', error);
     // callback(); // Proceed to the next file even if there's an error
    });
  };

  closeConfirmationModal = () => {
    this.scrapWebsiteStore.updateState({ showConfirmationModal: false ,showCards:false,
      websitesUrl: {
        ...this.scrapWebsiteStore.websitesUrl,
        urlname: ""
      }
    });
  };

  render() {
    const { appstore } = this.props;
    const {
      validationError, showCards, showModal, submittedWebsites, checkboxStates, loading, selectedMainUrl, subPages, urlToFilePathMap, continueDisabled, tooltipMessage
    } = this.scrapWebsiteStore;
    let pageCount = Object.values(this.scrapWebsiteStore.mainPagesWithCount).reduce((total, count) => total + count, 0);
    this.scrapWebsiteStore.totalpagecount = pageCount;
    if (!this.scrapWebsiteStore.ispageLoaded) {
      return (
        <div className="adminloader">
          <GridLoader css={override} size={20} margin={2} color={appstore.selectedColor.color} />
        </div>
      );
    }
    return (
      <div className="innercontent_wrapper w-100 scriptWrapper">
        {!showCards && (
          <div>
            <div className="header_title row align-items-center m-0">
              <img src={imageURLs.langPageIcon} alt="Charts" className="img-fluid" />
              <h4 className="text-left">{appstore.getLanguageTrans("Setup ChatBot")}</h4>
            </div>
            <div className="row fileTransferWrapper">
              <div className="col-12 w-100 pr-0">
                <div className="settingFormWrapper box">
                  <Form.Group as={Row} controlId="formGroupLanguage">
                    <Col xs="4" lg="10" md="2" sm="2">
                      <h4 className="text-left"><p>{appstore.getLanguageTrans("Scan your website content to generate answers to your customer questions .")}</p></h4>
                    </Col>
                  </Form.Group>
                  <form className="col-9 p-0">
                    <Form.Group as={Row} controlId="formGroupFacebookID">
                      <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Website URL")}
                        {/* <span className="mandatory">*</span> */}
                      </Form.Label>
                      <Col sm="8" lg="9">
                        <Form.Control type="text" name="websiteurlName"
                          placeholder="Enter Website URL" value={this.scrapWebsiteStore.websitesUrl.urlname}
                          onChange={this.handleChange} autoComplete="off" />
                        {validationError && (
                          <div className="col-lg-4 col-sm-1" style={{ color: "red" ,fontSize:"small"}}>
                            {validationError}
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  </form>
                  <div className="row m-0">
                    <div className="col-12 formBtns">
                      <div className="pullRight">
                        <Button className="btn_clr" variant="light" onClick={this.cancelForm}>
                          <p>{appstore.getLanguageTrans("Cancels")}</p>
                        </Button>
                        <Button className="btn_clr" variant="light" onClick={this.handleFormSubmit}>
                          <p>{appstore.getLanguageTrans("Scans")}</p>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <br />
        {showCards && (
          <div className="settingFormWrapper box">
            <div className="row m-0">
              <div className="col-12">
                <div className="saved-websites text-left">
                  <div className="header_title row align-items-center m-0">
                    <div className="text-center">
                      <FadeLoader size={10} margin={2} color={this.props.appstore.selectedColor.color} loading={loading} />
                    </div>
                    <h4 className="text-left">{appstore.getLanguageTrans(" Website :  ")}</h4>
                    <h4 style={{ color: appstore.selectedColor.color }}>  {this.scrapWebsiteStore.websitesUrl.newWebsite}</h4>
                  </div>
                  {showCards && !loading && (
                    <div>
                      <p className="text-left">{appstore.getLanguageTrans("ChatBot Classified")} {pageCount} {appstore.getLanguageTrans("pages on your website into the following categories. You can manage categories used in training.If you click on each category, you can decide which category pages should be used to train the bot.")}</p>
                      <div className="row">
                        {submittedWebsites.map((website, index) => (
                          <div className="col-3 pagebox" key={index}>
                            <div className="cardbox box">
                              <div className="col-2 checkboxalign">
                                <label className="switch">
                                  <input type="checkbox" checked={checkboxStates[website]} onChange={() => this.handleCheckboxChange(website)} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="col-12 text-left text-wrapper" style={{ wordWrap: 'break-word' }}>
                                <span onClick={() => this.handleMainUrlClick(website)} style={{ cursor: 'pointer' }}>
                                  <span style={{ fontWeight: 'bold' }}>{website}</span><br />
                                  {this.scrapWebsiteStore.mainPagesWithCount[website]} pages
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="row m-0">
                        <div className="col-12 formBtns">
                          <div className="pullRight">
                            <Button className="btn_clr" variant="light" onClick={this.cancelForm}>
                              <p>{appstore.getLanguageTrans("Cancel")}</p>
                            </Button>
                            <Button className="btn_clr" variant="light" onClick={this.createEmbeddings} disabled={continueDisabled} title={appstore.getLanguageTrans(tooltipMessage)} data-tooltip-conf="top">
                              <p>{appstore.getLanguageTrans("Continue")}</p>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <SubpageModal show={showModal} handleClose={this.closeModal} selectedMainUrl={selectedMainUrl}
        subPages={subPages} appstore={appstore} scrapWebsiteStore={this.scrapWebsiteStore}/>

        <ConfirmationAIModalComponent show={this.scrapWebsiteStore.showConfirmationModal} closeConfirmationModal={this.closeConfirmationModal}
         appstore={appstore}/>
      </div>
    );
  }
});

export default ScrapWebsiteComponent;

