import React from "react";
import { observer } from "mobx-react";
import { Modal, Button } from "react-bootstrap";

const EditChatbotAISuburlsModal = observer(({ show, handleClose, appstore, subUrls, subCheckboxStates, mainurlname, handleSubCheckboxChange }) => {
  return (
    <Modal backdrop='static' className={(appstore ? appstore.selectedColor.value : '')} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{mainurlname}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "400px", overflow: "auto", scrollbarColor: `${appstore.selectedColor.color} transparent`, scrollbarWidth: "thin" }}>
        {subUrls.map((subUrl, index) => (
          <div className="row" key={index}>
            <div className={`panelWrapper col-12 col-lg-12 p-0 ${index % 2 === 0 ? 'odd' : 'even'}`} style={{ marginBottom: "10px" }}>
              <div className="col-10" style={{ width: 'min-content', margin: '6px' }}>
                <a target="_blank" rel="noopener noreferrer" href={subUrl}><span style={{ cursor: 'pointer', wordWrap: 'break-word' }}>{subUrl}</span></a>
              </div>
              <div className="col-2 subcheckboxalign">
                <label className="switch">
                  <input type="checkbox" checked={subCheckboxStates[subUrl] || false} onChange={() => handleSubCheckboxChange(subUrl)}/>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="row m-0">
          <div className="col-12 formBtns">
            <div className="pullRight">
              <Button className="btn_clr" variant="light" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
});

export default EditChatbotAISuburlsModal;
