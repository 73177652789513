import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../../sharedFiles/navigation-store';

import FormValidator from '../../../../../sharedFiles/FormValidator';

import WidgetCodeComponent from '../../../../../sharedComponents/getWidgetCode/WidgetCodeComponent';
import IconBuilderPreviewComponent from './IconBuilderPreviewComponent';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import WarningComponent from '../../../../../sharedComponents/confirmationPopup/WarningComponent';
import ConfirmationComponent from '../../../../../sharedComponents/confirmationPopup/ConfirmationComponent';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const IconBuilderComponent = observer(class IconBuilderComponent extends Component {

	constructor(props) {
		super(props);

		this.validator = new FormValidator([
			{
				field: 'installationName',
				method: 'isEmpty',
				validWhen: false,
				message: 'Installation Name is required.'
			}
		]);

		this.state = {
			validation: this.validator.valid(),
			websiteUid: this.props.appstore.selectedWebisiteUid,
			uploading: false,
			images: "",
			actual_file: "",
			offline: "",
			online: ""
		}
		this.submitted = false;
		this.props.widgetStore.loadCustomlibrary(this.props.appstore)

	}

	componentDidMount() {		
		this.props.appstore.menuChanged = window.location.pathname; //this added extra for when user click on exits of corousol
		this.props.widgetStore.operatorDrpDwnOptions = this.props.appstore.deptOps.allOps;
		this.props.widgetStore.deptDrpDwnOptions = this.props.appstore.deptOps.allDept;
		this.props.widgetStore.iconDetails.department = this.props.widgetStore.deptDrpDwnOptions[0];
		this.props.widgetStore.iconDetails.operator = this.props.widgetStore.operatorDrpDwnOptions[0];
		this.props.widgetStore.iconDetails.language = this.props.appstore.selectedLanguage;
		this.props.widgetStore.getCode = false;
		this.props.widgetStore.sendCode = false;
		this.props.widgetStore.pageisLoaded = true;
		this.props.widgetStore.getallCustomIconByAccountId(this.props.appstore);
		// setInterval(()=>{
		// 	this.props.widgetStore.setwidgetDataforEdit(this.props.appstore);
		// },500)
			

	}


	componentDidUpdate(prevProps, prevState) {
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {
			//if user changes website then point to main widgets page
			this.setState({ websiteUid: this.props.appstore.selectedWebisiteUid });
			this.props.appstore.navigate('/admin/setup/widgets/');
		}
	}

	// setPath = e =>{
	// 	const url = window.location.href;
	// 	if(url.includes("?")){
	// 		let pos = url.indexOf("?");
	// 		let newpath =url.slice(0,pos)

	// 		this.props.appstore.menuChanged = newpath;

	// 	  }
	// }
	handleFormSubmit(event) {
		event.preventDefault();
		const validation = this.validator.validate(this.props.widgetStore.iconDetails);
		this.setState({ validation });
		this.submitted = true;
		
		if (validation.isValid) {

			const trimedName = this.props.widgetStore.iconDetails.installationName.trim();
			if (trimedName.length == 0) {
				//stop submission if spaces are entered
				return;
			}
			if (this.props.widgetStore.selectedonlinefile != "" || this.props.widgetStore.selectedofflinefile != "") {
				const formData = new FormData()
				formData.set('id', '100');  			
					this.state.online = this.props.widgetStore.selectedonlinefile ;
					this.state.offline = this.props.widgetStore.selectedofflinefile;					
					this.setState({ uploading: true })
					if(this.props.widgetStore.selectedonlinefile != ""){formData.append("offlineFile", this.state.online)}
					if( this.props.widgetStore.selectedofflinefile != ""){formData.append("file", this.state.offline)}
					 
				this.props.widgetStore.savecustomeiceon(this.props.appstore, formData);
				 
			} else {
				// handle actual form submission here
				this.props.widgetStore.saveIcon(this.props.appstore);
			}

			//  this.props.deptStore.saveDepartment(this.props.widgetStore.iconDetails);
		}
	}
	updateSelectOnlineFile(e, id,index,icon_uid,appstore,mode) {
		const files = e.target.files 

		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) { 
				const formData = new FormData()
				formData.set('id', '100'); 
				 	this.setState({ uploading: true })
					formData.append("file", e.target.files[0])	
					formData.append("titel", mode)	
					formData.append("icon_uid", icon_uid)				 
				let cont=0;let match=0;
				this.props.widgetStore.updatecustomimg(icon_uid,index,appstore,formData);
				// for(let i in this.props.widgetStore.icons){ 
				// 	// cont=cont+1;   
				// 	if((id) == this.props.widgetStore.icons[i].id){
				// 		match=1; 
				// 	  this.props.widgetStore.icons[i].updateimageURLs=e.target.files[0]; 
				// 	}
				// 	if(match==1)
				// 	break;

				//   }  
			}
		} 
	}
	componentWillUnmount() {
	}

	toggleFeature(feature, currentState) {
		const { enableVirtualAgent, enableChatBot } = currentState;
	
		if (feature === "VirtualAgent") {
			if (!enableChatBot) {			
				this.props.widgetStore.iconDetails.enableVirtualAgent = !enableVirtualAgent;
			} else {			
				this.props.widgetStore.validateChatbot_VA_Enable=true;
			}
		}
	
		if (feature === "ChatBot") {
			if (!enableVirtualAgent) {				
			  this.props.widgetStore.iconDetails.enableChatBot = !enableChatBot;
			} else {			
			  this.props.widgetStore.validateChatbot_VA_Enable=true;
			}
		}
	}

	render() {

		var self = this;
		const vacss={
			pointerEvents:"none",
			opacity:0.3,
		}

		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.props.widgetStore.iconDetails) :   // then check validity every time we render
			this.state.validation
			// if (this.props.widgetStore.pageisLoaded) {
			// 	return (<div className="profileLoader">
			// 		<GridLoader
			// 			css={override}
			// 			size={20}
			// 			marging={2}
			// 			color={this.props.appstore.selectedColor.color}
			// 		// loading={this.responseStore.responsereload}
			// 		/>
			// 	</div>)
			// }
		const { appstore,
			widgetStore } = this.props;

		return (
			<div key={appstore.selectedWebisiteUid} className="settingsForm directLinkWrapper col-12 w-100 pr-0">
				<h4 className="text-left">{appstore.getLanguageTrans("Icon Builder")}
				</h4>

				<div className="settingFormWrapper box">
					<div className="row">
						<div className={"col-12 col-xl-7" + (!appstore.menuActive ? ' col-lg-7' : '')}>
							<form>
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className={"pr-0" + (validation.installationName.isInvalid && ' field-error')}>Installation name <span className="mandatory">*</span></Form.Label>
									<Col sm="6" lg="7">
										<Form.Control type="text" className={validation.installationName.isInvalid && 'has-error'}
											name="installationName" placeholder={appstore.getLanguageTrans("Enter Installation Name")}
											value={widgetStore.iconDetails.installationName}
											onChange={(e) => widgetStore.iconDetails.installationName = e.target.value} />
										<span className="error-block">{validation.installationName.message}</span>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Department")}</Form.Label>
									<Col sm="6" lg="7" className="customDrpDwn">
										<DropDownComponent dropDownOptions={widgetStore.deptDrpDwnOptions}
											store={widgetStore}
											key={Math.random()}
											callBack={(selectedOption) => widgetStore.getOpforDept(appstore, widgetStore.iconDetails, selectedOption)}
											selectedOption={widgetStore.iconDetails.department} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Operator")}</Form.Label>
									<Col sm="6" lg="7" className="customDrpDwn">
										<DropDownComponent dropDownOptions={widgetStore.operatorDrpDwnOptions}
											store={widgetStore}
											key={Math.random()}
											callBack={(selectedOption) => widgetStore.iconDetails.operator = selectedOption}
											selectedOption={widgetStore.iconDetails.operator} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Language")}</Form.Label>
									<Col sm="6" lg="7" className="customDrpDwn">
										<DropDownComponent dropDownOptions={appstore.languages}
											store={appstore}
											key={Math.random()}
											callBack={(selectedLanguage) => widgetStore.setLanguage(selectedLanguage, appstore, widgetStore.iconDetails)}
											selectedOption={widgetStore.iconDetails.language} />
									</Col>
								</Form.Group>
								{/* <Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("Virtual Agent")}</Form.Label>
									{appstore.userInfo.accountplan.canUseVirtualAgent !=1 &&
						             <span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
									<Col className="custom_radioBtn" style={vacss}>
										<CheckBoxComponent radioValue={widgetStore.iconDetails.enableVirtualAgent}
											callBack={() => widgetStore.iconDetails.enableVirtualAgent = !widgetStore.iconDetails.enableVirtualAgent} />
									</Col></span>}
									{appstore.userInfo.accountplan.canUseVirtualAgent !=0 &&
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={widgetStore.iconDetails.enableVirtualAgent}
											callBack={() => widgetStore.iconDetails.enableVirtualAgent = !widgetStore.iconDetails.enableVirtualAgent} />
									</Col>}
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("ChatBot")}</Form.Label>
									{appstore.userInfo.accountplan.enable_chatbot !=1 &&
									 <span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
									 <Col className="custom_radioBtn" style={vacss}>
										 <CheckBoxComponent radioValue={widgetStore.iconDetails.enableChatBot}
											 callBack={() => widgetStore.iconDetails.enableChatBot = !widgetStore.iconDetails.enableChatBot} />
									 </Col></span>
									}
									{(appstore.userInfo.accountplan.enable_chatbot != 0 && appstore.userInfo.accountsmodel.chatbotusedcount > 0) &&
										( appstore.userInfo.accountplan.max_chatbot != 0 ? 
										<Col className="custom_radioBtn">
											<CheckBoxComponent radioValue={widgetStore.iconDetails.enableChatBot}
											callBack={() => widgetStore.iconDetails.enableChatBot = !widgetStore.iconDetails.enableChatBot} />											
										</Col>
										: 
										<span data-tooltip={appstore.getLanguageTrans("Please Set Up Your ChatBot")} data-tooltip-conf="top">								
											<Col className="custom_radioBtn" style={vacss}>
											<CheckBoxComponent radioValue={widgetStore.iconDetails.enableChatBot}
											callBack={() => widgetStore.iconDetails.enableChatBot = !widgetStore.iconDetails.enableChatBot} />
											</Col>
										</span>
										)
									}
								</Form.Group> */}
								<Form.Group as={Row} controlId="formGroupMsg">
								<Form.Label column lg="5" sm="6" className="pr-0">
									{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("Virtual Agent")}
								</Form.Label>
								{appstore.userInfo.accountplan.canUseVirtualAgent != 1 && (
									<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
										<Col className="custom_radioBtn" style={vacss}>
											<CheckBoxComponent 
												radioValue={widgetStore.iconDetails.enableVirtualAgent}
												callBack={() => this.toggleFeature("VirtualAgent", {
													enableVirtualAgent: widgetStore.iconDetails.enableVirtualAgent,
													enableChatBot: widgetStore.iconDetails.enableChatBot
												}, widgetStore)}
											/>
										</Col>
									</span>
								)}
								{appstore.userInfo.accountplan.canUseVirtualAgent != 0 && (
									<Col className="custom_radioBtn">
										<CheckBoxComponent 
											radioValue={widgetStore.iconDetails.enableVirtualAgent}
											callBack={() => this.toggleFeature("VirtualAgent", {
												enableVirtualAgent: widgetStore.iconDetails.enableVirtualAgent,
												enableChatBot: widgetStore.iconDetails.enableChatBot
											}, widgetStore)}
										/>
									</Col>
								)}
							</Form.Group>

							<Form.Group as={Row} controlId="formGroupMsg">
								<Form.Label column lg="5" sm="6" className="pr-0">
									{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("ChatBot")}
								</Form.Label>
								{appstore.userInfo.accountplan.enable_chatbot != 1 && (
									<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
										<Col className="custom_radioBtn" style={vacss}>
											<CheckBoxComponent 
												radioValue={widgetStore.iconDetails.enableChatBot}
												callBack={() => this.toggleFeature("ChatBot", {
													enableVirtualAgent: widgetStore.iconDetails.enableVirtualAgent,
													enableChatBot: widgetStore.iconDetails.enableChatBot
												}, widgetStore)}
											/>
										</Col>
									</span>
								)}
								{(appstore.userInfo.accountplan.enable_chatbot != 0 && appstore.userInfo.accountplan.max_chatbot != 0 ) && (
									appstore.userInfo.accountsmodel.chatbotusedcount > 0  ? (
										<Col className="custom_radioBtn">
											<CheckBoxComponent 
												radioValue={widgetStore.iconDetails.enableChatBot}
												callBack={() => this.toggleFeature("ChatBot", {
													enableVirtualAgent: widgetStore.iconDetails.enableVirtualAgent,
													enableChatBot: widgetStore.iconDetails.enableChatBot
												}, widgetStore)}
											/>
										</Col>
									) : (
										<span data-tooltip={appstore.getLanguageTrans("Please Set Up Your ChatBot")} data-tooltip-conf="top">
											<Col className="custom_radioBtn" style={vacss}>
												<CheckBoxComponent 
													radioValue={widgetStore.iconDetails.enableChatBot}
													callBack={() => this.toggleFeature("ChatBot", {
														enableVirtualAgent: widgetStore.iconDetails.enableVirtualAgent,
														enableChatBot: widgetStore.iconDetails.enableChatBot
													}, widgetStore)}
												/>
											</Col>
										</span>
									)
								)}
							</Form.Group>

								{/* <Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Enable pre-chat fields")}</Form.Label>
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={widgetStore.iconDetails.preChatEnabled}
											callBack={() => widgetStore.iconDetails.preChatEnabled = !widgetStore.iconDetails.preChatEnabled} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Show pre-chat fields to invited users")}</Form.Label>
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={widgetStore.iconDetails.preChatToUsers}
											callBack={() => widgetStore.iconDetails.preChatToUsers = !widgetStore.iconDetails.preChatToUsers} />
									</Col>
								</Form.Group> */}
								<Form.Group as={Row} controlId="formGroupMsg">
									<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Hide when offline")}</Form.Label>
									{appstore.userInfo.accountplan.hide_widget_offline !=1 &&
									<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
									<Col className="custom_radioBtn" style={vacss}>
										<CheckBoxComponent radioValue={widgetStore.iconDetails.offlineHidden}
											callBack={() => widgetStore.iconDetails.offlineHidden = !widgetStore.iconDetails.offlineHidden} />
									</Col></span>}
									{appstore.userInfo.accountplan.hide_widget_offline !=0 &&
									
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={widgetStore.iconDetails.offlineHidden}
											callBack={() => widgetStore.iconDetails.offlineHidden = !widgetStore.iconDetails.offlineHidden} />
									</Col>}
								</Form.Group>
							</form>
						</div>
						<div className={"col-12 col-xl-5" + (!appstore.menuActive ? ' col-lg-5' : '')}>
							<IconBuilderPreviewComponent widgetStore={widgetStore} appstore={appstore} />
						</div>
					</div>
					<div className="row m-0">
						<div className="col-12 formBtns">
							<div className="pullRight">
								<Button className="btn_clr" variant="light" onClick={() => (widgetStore.selectedWidget = 0, widgetStore.selectedofflinefile = "", widgetStore.selectedofflinefile = "")}>
									<p>{appstore.getLanguageTrans("Cancel")}</p>
								</Button>
								<Button className="btn_save" onClick={(event) => this.handleFormSubmit(event)}>
									<p>{appstore.getLanguageTrans("Save & Get code")}</p>
								</Button>
							</div>
						</div>
					</div>
					{
						widgetStore.getCode &&
						<WidgetCodeComponent store={widgetStore} appstore={appstore} />
					}
				</div>
				{widgetStore.saveLoader &&
					<div className="adminloader">
						<GridLoader
							css={override}
							size={20}
							marging={2}
							color={this.props.appstore.selectedColor.color}
						// loading={this.responseStore.responsereload}
						/>
					</div>
				}
				{widgetStore.errorPopup &&
					<WarningComponent show={widgetStore.errorPopup}
						store={widgetStore}
						cancel_btn="OK"
						closePopup={() => widgetStore.errorPopup = false}
						appStore={appstore} />
				}
				{
					widgetStore.conformationpopup &&
					<ConfirmationComponent show={widgetStore.conformationpopup}
						store={widgetStore}
						title="Delete Icon"
						body_html="<p>Are you sure you want to delete the selected Icon?</p>"
						cancel_btn="Cancel"
						save_btn="Confirm"
						callBack={() => widgetStore.deletecustomicon(appstore)}
						closePopup={() => (widgetStore.conformationpopup = false, widgetStore.icon_uid="",widgetStore.id="",widgetStore.mode="")}
						appStore={appstore} />
				}
				{
				   widgetStore.validateChatbot_VA_Enable && 
					<ConfirmationComponent show={widgetStore.validateChatbot_VA_Enable}
					store={this.appstore}
					body_html="<p>You cannot enable both the Virtual Agent and the ChatBot at the same time..</p>"
					cancel_btn="Okay" singleButton
					callBack={() =>  widgetStore.validateChatbot_VA_Enable=false}
					closePopup={() => widgetStore.validateChatbot_VA_Enable=false}
					appStore = {appstore}/>
				}
			</div>
		)
	}
})
export default IconBuilderComponent;
