import { decorate, observable, action } from 'mobx';
import axios from 'axios';

class ScrapWebsiteStore {
  constructor() {
    this.websitesUrl = {
      urlname: "",
      newWebsite: "",
    };
    this.validationError = "";
    this.showCards = false;
    this.showModal = false;
    this.submittedWebsites = [];
    this.checkboxStates = {};
    this.loading = false;
    this.selectedMainUrl = "";
    this.subPages = [];
    this.mainPagesWithCount = {};
    this.mainAndSubUrls = {}; // Initialize as empty object
    this.subCheckboxStates = {};
    this.urlToFilePathMap = {};
    this.totalTokenCount = 0;
    this.continueDisabled = false;
    this.tooltipMessage = "";
    this.urlStatusMap = {};
    this.totalpagecount = 0;
    this.ai_chatbotuid = "";
    this.websitesdetails = [];
    this.websiteurlloading = false;
    this.showConfirmationModal = false;
    this.validationurlcount = 0;
    this.website_pagename = [];
    this.ispageLoaded = false;
    this.subUrls = [];
    this.mainurlname = "";
    this.ai_chatbot_id=0;   
    this.disablechatbot=false;
    this.enablechatbot=false; 
  }

  setWebsitesUrl(value) {
    this.websitesUrl.urlname = value;
  }

  resetForm() {
    this.websitesUrl.urlname = '';
    this.validationError = "";
    this.showCards = false;
    this.showModal = false;
    this.submittedWebsites = [];
    this.checkboxStates = {};
    this.subCheckboxStates = {};
    this.mainAndSubUrls = {};
  }

  updateState(data) {
    Object.assign(this, data);
  }

  // Function to handle checkbox change for both main and sub URLs
  handleCheckboxChange(url, isMainUrl = true) {
    const { checkboxStates, subCheckboxStates, mainAndSubUrls } = this;
    let totalTokenCount = this.totalTokenCount;
    // Function to update token count based on tokensize and add/remove state
    const updateTokenCount = (tokensize, add) => {
      return add ? totalTokenCount + tokensize : totalTokenCount - tokensize;
    };

    if (isMainUrl) {
      // Toggle the state of main URL checkbox
      const isChecked = !checkboxStates[url];
      checkboxStates[url] = isChecked;

      // Update sub URL checkboxes if main URL is toggled
      if (mainAndSubUrls[url]) {
        mainAndSubUrls[url].forEach(subPage => {
          const { url: subUrl, tokensize } = subPage;
          subCheckboxStates[subUrl] = isChecked;
          totalTokenCount = updateTokenCount(tokensize, isChecked);
        });
      }
    } else {
      // Toggle the state of sub URL checkbox
      subCheckboxStates[url] = !subCheckboxStates[url];

      // Check if all sub URLs for the main URL are checked or not
      const mainUrl = Object.keys(mainAndSubUrls).find(key => mainAndSubUrls[key].some(subPage => subPage.url === url));
      if (mainUrl) {
        const allSubUrlsChecked = mainAndSubUrls[mainUrl].every(subPage => subCheckboxStates[subPage.url]);
        checkboxStates[mainUrl] = allSubUrlsChecked;
      }

      // Update total token count based on sub URL state change
      const subPage = mainAndSubUrls[mainUrl].find(subPage => subPage.url === url);
      if (subPage) {
        const { tokensize } = subPage;
        totalTokenCount = updateTokenCount(tokensize, subCheckboxStates[url]);
      }
    }

    // Update total token count and tooltip message based on token limit
    const continueDisabled = totalTokenCount > 10000;
    const tooltipMessage = continueDisabled ? "Your token limit exceeded" : "";

    this.updateState({
      checkboxStates: { ...checkboxStates },
      subCheckboxStates: { ...subCheckboxStates },
      totalTokenCount,
      continueDisabled,
      tooltipMessage,
    });
  }

  // Fetch all websites URLs based on selectedWebisiteUid
  async fetchWebsites(appstore) {
    this.websiteurlloading = true;
    try {
      const result = await axios({
        method: 'GET',
        url: `${appstore.url}getAllWebsitesURL.json`,
        headers: { 'Content-Type': 'application/json' },
        params: { website_uid: appstore.selectedWebisiteUid },
      });

      if (result.data != null) {      
        this.updateState({ websitesdetails: result.data,ispageLoaded: true });
      }
    } catch (error) {
      console.error('Error fetching websites:', error);
    } finally {
      this.updateState({ websiteurlloading: false });
    }
  }

  // Validate if website URL exists based on website_uid and websiteurl_name
  async validateurlExist(websiteurlname, appstore) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${appstore.url}getvalidateurl.json`,
        headers: { 'Content-Type': 'application/json' },
        params: { website_uid: appstore.selectedWebisiteUid, websiteurl_name: websiteurlname },
      });

      if (result.data != null) {
        this.updateState({ validationurlcount: result.data });
      }
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
  }

  // Fetch list of website main page names based on ai_chatbotuid
  async getWebsitePageNames(appstore) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${appstore.url}getListOfWebsiteMainpageName.json`,
        headers: { 'Content-Type': 'application/json' },
        params: { ai_chatbotuid: appstore.ai_chatbotuid },
      });

      if (result.data != null) {
        this.updateState({ website_pagename: result.data, ispageLoaded: true });
      }
    } catch (error) {
      console.error('Error fetching websitePageNames :', error);
    }
  }
  disablechatbotAction(chatbotval){      
      this.disablechatbot=true;
      this.chatbotdisablevalue = chatbotval;   
  }
  enablechatbotAction(chatbotval){      
    this.enablechatbot=true;
    this.chatbotdisablevalue = chatbotval
}

  confirmdisableOREnablechatbot=(appstore,status) =>{
    axios({
      method: 'POST',
      url: appstore.url+'disable_enable_ChatBot.json',
      headers: { 'content-type': 'application/json' },
      data: {
        ...this.chatbotdisablevalue, 
        type: status
      }
      }).then(result=> { 
        if(result.data.length==1){
          if(result.data[0].logoutstatus==1){
            appstore.logout();
          }else{
            this.websitesdetails=result.data;
          //  this.props.appstore.userInfo.accountsmodel.chatbotusedcount = result.data.chatbotUsedcnt          
          //  this.props.appstore.userInfo.accountsmodel.enabled_chatbotcount = result.data.enabledChatbotCount
          }
        }
      appstore.global_errorcount=0;
       
     }).catch((error) => {        
        console.log(error);
        console.log("error count :"+appstore.global_errorcount);
        appstore.global_errorcount=appstore.global_errorcount+1;
        if(appstore.global_errorcount>20){
        // appstore.logout();
        }
    })
    if(status=='disable')
      this.disablechatbot=false;
    else
      this.enablechatbot=false;
  } 
}

decorate(ScrapWebsiteStore, {
  websitesUrl: observable,
  validationError: observable,
  showCards: observable,
  showModal: observable,
  submittedWebsites: observable,
  checkboxStates: observable,
  loading: observable,
  selectedMainUrl: observable,
  subPages: observable,
  mainPagesWithCount: observable,
  mainAndSubUrls: observable,
  subCheckboxStates: observable,
  urlToFilePathMap: observable,
  totalTokenCount: observable,
  continueDisabled: observable,
  tooltipMessage: observable,
  urlStatusMap: observable,
  totalpagecount: observable,
  ai_chatbotuid: observable,
  websitesdetails: observable,
  websiteurlloading: observable,
  showConfirmationModal: observable,
  validationurlcount: observable,
  website_pagename: observable,
  ispageLoaded: observable,
  subUrls: observable,
  mainurlname: observable,
  setWebsitesUrl: action,
  resetForm: action,
  updateState: action,
  handleCheckboxChange: action,
  handleMainCheckboxChange: action,
  handleSubCheckboxChange: action,
  fetchWebsites: action,
  validateurlExist: action,
  getWebsitePageNames: action,
  fetchSubUrls: action,
  ai_chatbot_id : observable,
  disablechatbot : observable,
  chatbotdisablevalue : observable,
  enablechatbot : observable,
});

export default ScrapWebsiteStore;
