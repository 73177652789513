import { observer } from "mobx-react";
import React from "react";
import { Modal, Button } from 'react-bootstrap';

    const confirmationAIModalComponent = observer(({ show, closeConfirmationModal,appstore }) => {

        return(
            <Modal backdrop='static' className={(appstore ? appstore.selectedColor.value : '')} show={show} onHide={closeConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Invalid URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please enter a valid URL. No pages were found to scrape.</Modal.Body>
                <Modal.Footer>          
                    <div className="row m-0">
                    <div className="col-12 formBtns">
                        <div className="pullRight">
                        <Button className="btn_clr" variant="light" onClick={closeConfirmationModal}>
                            <p>{appstore.getLanguageTrans("Close")}</p>
                        </Button>
                        </div>
                    </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
});

export default confirmationAIModalComponent;