import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { observer } from 'mobx-react';

import imageURLs from './sharedFiles/ImagesURLs';
import logo from './logo.svg';
import $ from 'jquery';
import './App.scss';

import ErrorMessageComponent from './sharedComponents/confirmationPopup/ErrorMessageComponent';
import navigationStore from './sharedFiles/navigation-store';
import SessionOverPopup from './sharedComponents/sessionOverPopup'
import HeaderComponent from './sharedComponents/header/Header';
import SideMenuComponent from './sharedComponents/sideMenu/SideMenu';
import ConfirmationComponent from './sharedComponents/confirmationPopup/ConfirmationComponent';
import HomepageLayout from './layouts/HomePageLayout';
import AdminPageLayout from './layouts/AdminPageLayout';
import ProfilePageLayout from './layouts/ProfilePageLayout';
import TrafficPageLayout from './layouts/TrafficPageLayout';
import ChatsPageLayout from './layouts/ChatsPageLayout';
import SockJsClient from 'react-stomp';
import AppStore from './AppStore';
import Login from './layouts/Login';
import ForgotComponent from './components/ForgotComponent';
import axios from 'axios';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'; 
import firebase from './firebase';  
import soundManagerjs from 'soundmanager2/script/soundmanager2.js';
import ChatConfirmationComponent from './sharedComponents/confirmationPopup/ChatConfirmationComponent';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 42%; 
opacity: 0.6 ;
position: relative` 
 

const App = observer(class App extends Component {

  constructor(props) {
    super(props);
    this.appstore = new AppStore();
    this.socket = null;
    this.state = {
      socket: null,
      sourceStation:"",
    }; 
    if (window.performance) { 
      //if user refresh page
      this.appstore.colorVal = localStorage.getItem("color");
      localStorage.setItem("currentpath",window.location.pathname);
      this.appstore.getColor();
      this.appstore.pageRefreshing = true;
      //this.appstore.navigate('/refreshComponent')
      this.login(this.appstore);
    } 
   

}
//testing call dont use it
heloponclickcookiesget = () =>{
  var helponclickcookieName="Helponclick_useremail";
      var helponclickcookiepass="Helponclick_userpass";
      var chelponclickorgintockenkeycheck="chelponclicktockenchat";
      var helponclickName = helponclickcookieName + "=";
      var helponclickpass = helponclickcookiepass + "=";
      var helponclicktc = chelponclickorgintockenkeycheck + "=";
      var cusermail="";
      var cuserpass="";
      var cuserhelptocken="";
      var chelponclickorgintockenvalue="helponclicklivetarget";	
      var cvaluenew="";
      var decodedCookie = decodeURIComponent(document.cookie);
      var helponclickloginfromweb = decodedCookie.split(';');
      var d = new Date();
      d.setTime(d.getTime() + (60*1000));
      var helponcookiexpires = "expires="+ d.toUTCString();
      for(var i = 0; i <helponclickloginfromweb.length; i++) {
        var helponclickcookie = helponclickloginfromweb[i];
        while (helponclickcookie.charAt(0) == ' ') {
          helponclickcookie = helponclickcookie.substring(1);
        } 
        if (helponclickcookie.indexOf(helponclicktc) == 0) {
          cuserhelptocken=helponclickcookie.substring(helponclicktc.length, helponclickcookie.length);
        }
        if (helponclickcookie.indexOf(helponclickName) == 0) {
          cusermail=helponclickcookie.substring(helponclickName.length, helponclickcookie.length);
          // localStorage.setItem("color","steelBlue");
          // localStorage.setItem("email",helponclickcookie.substring(helponclickName.length, helponclickcookie.length));
          // localStorage.setItem("currentpath","/traffic");
         
          // document.cookie = helponclickcookieName + "=" + cvaluenew + ";" + helponcookiexpires + ";path=/";          
        }
        if (helponclickcookie.indexOf(helponclickpass) == 0) {
          cuserpass=helponclickcookie.substring(helponclickpass.length, helponclickcookie.length);
          // localStorage.setItem("repass",helponclickcookie.substring(helponclickpass.length, helponclickcookie.length));
          // document.cookie = helponclickcookiepass + "=" + cvaluenew + ";" + helponcookiexpires + ";path=/";
        }
      }
      if(cuserhelptocken==chelponclickorgintockenvalue){
        localStorage.setItem("color","steelBlue");
        localStorage.setItem("email",cusermail);
        localStorage.setItem("currentpath","/traffic");
        localStorage.setItem("repass",cuserpass);
        document.cookie = helponclickcookieName + "=" + cvaluenew + ";" + helponcookiexpires + ";path=/";
        document.cookie = helponclickcookiepass + "=" + cvaluenew + ";" + helponcookiexpires + ";path=/";
        document.cookie = chelponclickorgintockenkeycheck + "=" + cvaluenew + ";" + helponcookiexpires + ";path=/";
      }
}

  componentWillMount() {

  }

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
      window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          return this.appstore.updateUserStatus(-1);
      });
  };

  componentDidMount() {
    // Activate the event listener
    //this.setupBeforeUnloadListener(); 
    
    const time = 15*60*1000;  //15 min
    setInterval(()=>{this.appstore.pingAction()},time); 
    try{
      var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') == -1){
        const messaging = firebase.messaging()
              messaging.requestPermission().then(()=>{
                return messaging.getToken()
              }).then(token=>{
                console.log('Token: ',token);
                localStorage.setItem("firbasedevicetoken",token); 
                // try{
                //   if(this.appstore.userInfo){ 
                //     this.appstore.storetockendeviceid();
                //   } 
                // }catch(error){}
              }).catch((e)=>{
                console.log("error");
                console.log(e);
              });
        }
    }catch(error){
    }
  }
  
  login = (appstore) => {
    let data = {
      "email": localStorage.getItem("email"),
      "time_zone_offset":new Date().getTimezoneOffset(),
      "platform":"web",
      "deviceId": localStorage.getItem("firbasedevicetoken")
    }
    if (data.email === null) {
      var windowurl=window.location.href; 
      if (window.location.href != appstore.url && windowurl.includes("new_password")!=true) {
        appstore.colorVal = 'steelBlue';
        appstore.getColor();
        appstore.navigate('/')
      };
      appstore.pageRefreshing = false;
      return;
    }
    else if (window.location.href == appstore.url) {
      appstore.colorVal = 'steelBlue';
      appstore.getColor();
      appstore.pageRefreshing = false;
      return;
    } 

    axios({
      method: 'POST',
      url: appstore.url + 'loginuser.json',
      headers: { 'content-type': 'application/json' },
      data: data
    }).then(result => {
      try{
      if (result.data.usersmodel != null) {
 
        //get current ongoing chat on refresh 
        this.appstore.getCurrentOngoingChats();
        
        appstore.userInfo = result.data;
        appstore.userlivestatus=appstore.userInfo.usersmodel.user_live_status;
        appstore.monthly_chat_enable_count = appstore.userInfo.accountsmodel.chatcount;
        if(appstore.userInfo.accountsmodel.private_label==1){
        document.title = appstore.userInfo.accountsmodel.private_label_title;
        }
        if(appstore.userInfo.accountplan.chat_routing != 1){
          appstore.downgrade();
        }
        let soundvalue=localStorage.getItem("soundOnorOff"); 
        appstore.soundon_off = soundvalue==1 ? true :false; 

        this.appstore.setUserStatus_Topics();
        appstore.localLanguage = JSON.parse(result.data.language);
        appstore.colorVal = result.data.usersmodel.color;

        appstore.getColor();
        if (appstore.userInfo.allwebistes.length == 0 || appstore.userInfo.allwebistes == null) {
          if (appstore.isAdmin) {
            appstore.addNewSite = true;
          }
        }
        appstore.pageRefreshing = false; 
        //redirect to current path 
        let  currentpath =  localStorage.getItem("currentpath");
        if(currentpath.indexOf('/admin/setup/')!=-1){
          appstore.editwebsite=true;
        }else{
          appstore.editwebsite=false;
        }
        if(currentpath){
          if(currentpath!="/"){
            appstore.navigate(currentpath);
          }else{
            appstore.navigate('/traffic');
          }
        }else{
         appstore.navigate('/home');
        }
        
         //console.log("currentpath   :::: "+currentpath);
        //unset current path from localstorage
        localStorage.removeItem("currentpath");
        currentpath = ''
        if(appstore.userInfo.allwebistes.length==0 || appstore.userInfo.allwebistes==null) {
          if(appstore.isAdmin){
              appstore.addNewSite=true;
             appstore.navigate(currentpath+'admin');
          }else {
              appstore.navigate('/traffic'); 
          }      
          
      }
      else if(appstore.userInfo.allwebistes.length > appstore.userInfo.accountplan.max_websites){
          if(appstore.isAdmin){
             appstore.deletesite=true;
             appstore.navigate(currentpath+'admin');
          }else {
              appstore.deletesite=true;
              appstore.navigate('/traffic'); 
          }   
      }
      else if(appstore.userInfo.alloperators.length > appstore.userInfo.accountplan.max_ops && appstore.userInfo.alldepartments.length > appstore.userInfo.accountplan.max_deps && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites && appstore.userInfo.accountplan.max_ops !=0 && appstore.userInfo.accountplan.max_deps!=0) {
          if(appstore.isAdmin){
              
             // appstore.navigate('admin/setup/widgets');
             appstore.deleteoperator=true;
             appstore.navigate(currentpath+'admin');
          }else {
              appstore.deleteoperator=true;
              appstore.navigate('/traffic'); 
          }      
      }
      else if(appstore.userInfo.alldepartments.length > appstore.userInfo.accountplan.max_deps && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites && appstore.userInfo.accountplan.max_deps!=0) {
          if(appstore.isAdmin){
             // appstore.navigate('admin/setup/widgets');
             appstore.deletedept=true;
             appstore.navigate(currentpath+'admin');
          }else {
              appstore.deletedept=true;
              appstore.navigate('/traffic'); 
          }      
      }
      else if(appstore.userInfo.alloperators.length > appstore.userInfo.accountplan.max_ops && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites && appstore.userInfo.accountplan.max_ops!=0) {
          if(appstore.isAdmin){
             // appstore.navigate('admin/setup/widgets');
             appstore.deleteopt=true;
             appstore.navigate(currentpath+'admin');
          }else {
              appstore.deleteopt=true;
              appstore.navigate('/traffic'); 
          }      
      }else if(appstore.userInfo.accountsmodel.chatbotusedcount > appstore.userInfo.accountplan.max_chatbot &&  appstore.userInfo.accountplan.enable_chatbot ==1){
        if(appstore.isAdmin){
           appstore.deletechatbot=true;
           appstore.navigate(currentpath+'admin');
        }else {
            appstore.deletechatbot=true;
            appstore.navigate('/traffic'); 
        }   
      }
      if(result.data.allassistdata != null){
      if(result.data.allassistdata.length != 0 && result.data.allassistdata.length!=null){
        for(let i in  result.data.allassistdata){
            if(JSON.parse(result.data.allassistdata[i].clientConfigJson).toolbar){
            var toolbardata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
            toolbardata.toolbar.buttons.chat.hideWhenOffline = false;
            result.data.allassistdata[i].clientConfigJson = JSON.stringify(toolbardata);
           }
           if(JSON.parse(result.data.allassistdata[i].clientConfigJson).icon){
            var icondata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
            icondata.icon.hideWhenOffline = false;
            if(JSON.parse(result.data.allassistdata[i].clientConfigJson).widget){
               icondata.widget.hideWhenOffline = false;
            }
            result.data.allassistdata[i].clientConfigJson = JSON.stringify(icondata);
           }
           if(JSON.parse(result.data.allassistdata[i].clientConfigJson).color && JSON.parse(result.data.allassistdata[i].clientConfigJson).widget && !JSON.parse(result.data.allassistdata[i].clientConfigJson).icon){
            var widgetdata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
            widgetdata.widget.hideWhenOffline = false;
            result.data.allassistdata[i].clientConfigJson = JSON.stringify(widgetdata);
           }
       }
       appstore.updateclientConfigJson(result.data.allassistdata); 
    }
  }
      }
      else {
        // this.hideLoader();
        appstore.userInfo = result.data;
        appstore.monthly_chat_enable_count = appstore.userInfo.accountsmodel.chatcount;
        appstore.pageRefreshing = false;
        appstore.colorVal = 'steelBlue';
        appstore.getColor();
        appstore.navigate('/');

      }
    }catch(error){
      console.log("status 200 but no data");
      console.log(error);
        localStorage.clear();
        window.location.href=this.appstore.url;
        this.appstore.userInfo = "";
        this.appstore.isloggedout = false; 
        this.appstore.pageRefreshing = false;
    }
  if(result.data.allassistdata != null){  
  if(result.data.allassistdata.length != 0 && result.data.allassistdata.length!=null){
      for(let i in  result.data.allassistdata){
          if(JSON.parse(result.data.allassistdata[i].clientConfigJson).toolbar){
          var toolbardata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
          toolbardata.toolbar.buttons.chat.hideWhenOffline = false;
          result.data.allassistdata[i].clientConfigJson = JSON.stringify(toolbardata);
         }
         if(JSON.parse(result.data.allassistdata[i].clientConfigJson).icon){
          var icondata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
          icondata.icon.hideWhenOffline = false;
          if(JSON.parse(result.data.allassistdata[i].clientConfigJson).widget){
             icondata.widget.hideWhenOffline = false;
          }
          result.data.allassistdata[i].clientConfigJson = JSON.stringify(icondata);
         }
         if(JSON.parse(result.data.allassistdata[i].clientConfigJson).color && JSON.parse(result.data.allassistdata[i].clientConfigJson).widget && !JSON.parse(result.data.allassistdata[i].clientConfigJson).icon){
          var widgetdata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
          widgetdata.widget.hideWhenOffline = false;
          result.data.allassistdata[i].clientConfigJson = JSON.stringify(widgetdata);
         }
     }
     //console.log(result.data.allassistdata);
     appstore.updateclientConfigJson(result.data.allassistdata);
    } }
    }).catch((error) => {
      if (error.response) {
        //console.log(error)
        localStorage.clear();
        //sessionStorage.clear();
        window.location.href=this.appstore.url;
        this.appstore.userInfo = "";
        this.appstore.isloggedout = false; 
        this.appstore.pageRefreshing = false;
      }
    });
  }

  onMessage = async (msg) => {  
    this.appstore.handleTrafficMonitorData(msg);
    if (msg.chatStatus === "logoutOp") {
      //check if operator is in chat with visitor
      // if(this.appstore.requestedVisitorId){
       
      // }
      const unsetData=true;
      this.appstore.logout(unsetData);  //admin can logout any operator
      this.appstore.showSessionOverPopup = true;
      return;
    }
    else if(msg.chatStatus === "online" || msg.chatStatus === "offline"){
      for(let i=0; i<this.appstore.operators.length; i++){
         if(msg.operatorUid === this.appstore.operators[i].user_uid){
          this.appstore.operators[i].user_live_status = msg.chatStatus;
          break;
         }     
      }
      if(msg.operatorUid === this.appstore.userInfo.usersmodel.user_uid){
        this.appstore.userInfo.usersmodel.userlivestatus = msg.chatStatus; 
        this.appstore.userlivestatus=msg.chatStatus;
        this.appstore.loggedUserOnline = msg.chatStatus === "online" ? true : false;
      }

      //update onlineSubmenuOperators to reflect updated online operators in traffic opeartor submenu
      setTimeout(()=>{
        this.appstore.onlineSubmenuOperators = Math.random();
      } ,2000)
       
      return;
    }
    else if(msg.chatStatus === "opQuit"){  
      //push left chat msg
      msg.message = msg.name+' has left the chat.';
      if(msg.sourceStation){
        if(this.appstore.visitors[msg.sourceStation]){
           this.appstore.visitors[msg.sourceStation].push(msg);
           this.appstore.visitors[msg.sourceStation][0].opHasLeft=true; 
        }
      }
    }
    else if(msg.chatStatus === "supervisionQuit"){
        delete this.appstore.supervisedBy[msg.sourceStation];
      if(this.appstore.assignedTopics.includes(msg.operatorUid))
        {  
          for(let i = this.appstore.assignedTopics.length - 1; i >= 0; i--) {
            if(this.appstore.assignedTopics[i] === 5) {
              this.appstore.assignedTopics.splice(i, 1);
            }
          }
        } 
        //push left chat msg
        if(msg.sourceStation){
          if(this.appstore.visitors[msg.sourceStation])
             this.appstore.visitors[msg.sourceStation].push(msg) 
        }
    }
    else if(msg.chatStatus === "chatMissed"){
      if(this.appstore.chatRequests[msg.sourceStation]){
        console.log(this.appstore.chatRequests[msg.sourceStation]);
        this.appstore.chatRequests[msg.sourceStation][0].chatrequestAccepted=false;
        this.appstore.chatpopuparray =  this.appstore.chatpopuparray.filter(e => e !== msg.sourceStation);
        this.appstore.lodasound=1; 
        soundManagerjs.soundManager.stopAll();
      }
    }
    else if(msg.chatStatus === "accepted"){
      
        if (msg.acceptedBy) {

          let now = new Date();
         // const currentTime = Math.floor((now.getTime() + (now.getTimezoneOffset() * 60000))/1000);
         const currentTime = Math.floor((now.getTime())/1000);
          const user = this.appstore.userInfo.usersmodel;
          const userRole = user.user_type_id == 3 ? 'admin':'operator';
          const  visitorId = msg.sourceStation;
          let opMsg = {
                  id: '', type: "opsMsg",system_msg:true, role:userRole,userUid: visitorId, operatorUid:user.user_uid,  message: user.nick_name+" has left the chat",
                  creationTime: currentTime, hostname: 'ip', sourceStation: visitorId, chatStatus: "chatTransferred",
                  fileName:"",account_uid:user.account_uid, name: user.nick_name, email: user.email,targetStation: '/topic/' + visitorId
              }

          if(!this.appstore.chatRequests[msg.sourceStation])
          return;
           

          if (msg.acceptedBy !== this.appstore.userInfo.usersmodel.user_uid) {
            console.log("msg.transferredChat:"+msg.transferredChat+"-msg.inviteTojoin:"+msg.inviteTojoin);
              //avoid for same op
              //true && !true
              if(msg.inviteTojoin && !msg.transferredChat)
              return;

              if(msg.transferredChat){
                  // send left message if chat is tranferred.
                   this.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                      
                      opMsg.targetStation = '/topic/' + msg.acceptedBy;
                      this.appstore.clientRef.sendMessage("/app/op2op", JSON.stringify(opMsg))
                      
                      //remove entry from session
                      this.appstore.handleQuitchat([this.appstore.requestedVisitorId]); 
                      
                      this.appstore.chatisTransferred = true;

                      //close accept chat popup for other operators , if already accepted
                    this.appstore.chatRequests[msg.sourceStation][0].chatrequestAccepted=false;
                    this.appstore.chatrequestAccepted = false;
                    this.appstore.requestSoundRef.current.pause();
                    //clear visitor chat record 
                      delete this.appstore.visitors[msg.sourceStation];
                      delete  this.appstore.chatRequests[msg.sourceStation];
                    
                    let visitorList =[]; 
                    
                    for(let i in this.appstore.visitors){
                        visitorList.push(this.appstore.visitors[i][0]);
                    } 
                    this.appstore.visitorList = visitorList;
                                
                    //quit chat with current visitor and set current chat different visitor if available
                      if(Object.keys(this.appstore.visitors).length == 0 ){
                        this.appstore.requestedVisitorId = "";
                      }
                      else{
                        let keys = Object.keys(this.appstore.visitors); //array of visitor id 
                        this.appstore.requestedVisitorId = keys[0];
                      } 
              }
              else{
              if(msg.accepted_opid!==this.appstore.userInfo.usersmodel.user_uid){
            //close accept chat popup for other operators , if already accepted
            this.appstore.chatRequests[msg.sourceStation][0].chatrequestAccepted=false;
            this.appstore.chatrequestAccepted = false;
            this.appstore.requestSoundRef.current.pause();

             
              await  this.appstore.handleQuitchat(msg.sourceStation);
              let concurrent_chatscount= await this.appstore.checkconcurrent_chats();
              //clear visitor chat record 
              this.appstore.chatpopuparray =  this.appstore.chatpopuparray.filter(e => e !== this.appstore.requestedVisitorId);
                delete this.appstore.visitors[msg.sourceStation];
                delete  this.appstore.chatRequests[msg.sourceStation];
                
                
              let visitorList =[]; 
              
              for(let i in this.appstore.visitors){
                  visitorList.push(this.appstore.visitors[i][0]);
              } 
              this.appstore.visitorList = visitorList;
              //quit chat with current visitor and set current chat different visitor if available
                if(Object.keys(this.appstore.visitors).length == 0 ){
                  this.appstore.requestedVisitorId = "";
                  //this.appstore.navigate('/chats/operator/1');  
                }
                else{
                  let keys = Object.keys(this.appstore.visitors); //array of visitor id 
                  this.appstore.requestedVisitorId = keys[0];
                }  
              }
              try{
                // console.log(this.appstore.acceptedvistorlist);
                if(this.appstore.acceptedvistorlist.includes(msg.sourceStation)){  
                  // console.log("exists vi");              
                        this.appstore.chatacceptalert = true;
                        this.appstore.acceptedvistorlist =  this.appstore.acceptedvistorlist.filter(e => e !== msg.sourceStation);
                        
                }
              }catch(er){}
              }
              this.appstore.lodasound=1;
              soundManagerjs.soundManager.stopAll();
              
            
            return;
          }
         
      }
    }
    else if(msg.chatStatus === "chatTransferred"){
      if(!this.appstore.visitors[msg.sourceStation])
        return;

      if(this.appstore.visitors[msg.sourceStation].length ==0)
        return;

        if(msg.sourceStation){
             msg.system_msg = true;
             this.appstore.visitors[msg.sourceStation].push(msg);
          }
    }

    else if(msg.chatStatus === "inviteJoined"){
      if(!this.appstore.visitors[msg.sourceStation])
        return;

      if(this.appstore.visitors[msg.sourceStation].length ==0)
        return;

        msg.system_msg = true;
        this.appstore.visitors[msg.sourceStation].push(msg);

        if(this.appstore.inviteJoinedByOp[msg.sourceStation])
          this.appstore.inviteJoinedByOp[msg.sourceStation].push(msg.operatorUid);

        else{
        this.appstore.inviteJoinedByOp[msg.sourceStation]=[];
        this.appstore.inviteJoinedByOp[msg.sourceStation].push(msg.operatorUid);
        }
      
        //get unique array of op uids
        this.appstore.inviteJoinedByOp[msg.sourceStation] = [...new Set(this.appstore.inviteJoinedByOp[msg.sourceStation])]
    
    }

    else if( msg.chatStatus ===  "inviteLeft"){
 
      if(this.appstore.inviteJoinedByOp[msg.sourceStation])
        {
          let newArray = this.appstore.inviteJoinedByOp[msg.sourceStation].filter(id => {  
                          if(id !==  msg.operatorUid)
                            return id;
                          })

          if(newArray.length === 0)
              delete this.appstore.inviteJoinedByOp[msg.sourceStation];
          
          else
             this.appstore.inviteJoinedByOp[msg.sourceStation] = newArray;
        } 

        msg.system_msg = true;
        if(this.appstore.visitors[msg.sourceStation])
          this.appstore.visitors[msg.sourceStation].push(msg);
     
    }

    else if(msg.chatStatus ===  "cancelInvite"){

           if(this.appstore.chatRequests[msg.sourceStation])
           this.appstore.chatRequests[msg.sourceStation][0].chatrequestAccepted=false;
    }
    
    else if (msg.chatStatus === "chat" && msg.role != "virtual") {      
      if(!this.appstore.visitors[msg.sourceStation])
      return;

      if(this.appstore.visitors[msg.sourceStation].length ==0)
        return;


        //notify new msg 
      if(this.appstore.requestedVisitorId !== msg.sourceStation){
        for(let i=0; i<this.appstore.visitorList.length; i++){
          if(this.appstore.visitorList[i].sourceStation && this.appstore.visitorList[i].sourceStation===msg.sourceStation ){
            this.appstore.visitorList[i].gotNewMsg = true;
            break;
          }
        }
     }

      if(msg.staff_message == true ){
           //set topic of supervising admin
           if(!this.appstore.assignedTopics.includes(msg.operatorUid))
              this.appstore.assignedTopics.push(msg.operatorUid);
            //set supervisor admin id  
            this.appstore.supervisedBy[msg.sourceStation] = msg.operatorUid;    
       }

       
      if (msg.message.trim().length != 0 || (msg.fileName != null && msg.fileName != "")) {
        
          if(msg.avoidEntry && msg.operatorUid==this.appstore.userInfo.usersmodel.user_uid)
          return; 

         if( msg.role === "visitor")
           msg.type = "inMsg";
         if( msg.role === "operator" || msg.role === "admin")   
           msg.type = "opMsg"
 
           if(msg.role != "virtual"){  
             
            if(this.appstore.visitors[msg.sourceStation][0].visitorLeft){
              // let enterChatObject = this.appstore.visitors[msg.sourceStation].find((obj)=> obj.chatStatus === "chatRequest");
              // if(enterChatObject){
              //     this.appstore.visitors[msg.sourceStation].push(enterChatObject)
              //   }
                 this.appstore.visitors[msg.sourceStation][0].visitorLeft = false; 
            }
                  
             this.appstore.visitors[msg.sourceStation].push(msg)
             if(this.appstore.soundon_off)
              this.appstore.getplaySound(this.appstore.visitors[msg.sourceStation][0].websiteUid,'message_received')
            }
      }

      this.appstore.visitorsTyping[msg.sourceStation] = { message: "" };
      
    }
    else if (msg.chatStatus === "typing") {
      //if (msg.message.trim().length != 0) {
        msg.type = "";
        this.appstore.visitorsTyping[msg.sourceStation] = msg;
        this.appstore.visitorTypingMsgChanged = Math.random();
     // }
    }

    
    else if (msg.chatStatus === "chatRequest") { 
      console.log(this.appstore.acceptedvistorlist);
      if(this.appstore.acceptedvistorlist.includes(msg.sourceStation)){ 
          return;
      }
      
      console.log(this.appstore.userInfo.usersmodel.user_live_status);
      if(this.appstore.userInfo.usersmodel.user_live_status==="offline"){
        return;
      }
      console.log(this.appstore.userlivestatus);
      if(this.appstore.userlivestatus==="offline"){
        return;
      }

      if(msg.chatRejected)
      return;
      
      this.setState({sourceStation:msg.sourceStation});

      if(!this.appstore.visitors[msg.sourceStation])
          this.appstore.visitors[msg.sourceStation] = [];
      
      if(!this.appstore.chatRequests[msg.sourceStation])
          this.appstore.chatRequests[msg.sourceStation] = [];
      
      msg.type = "inMsg";
      if(msg.prechatfields == null)
         msg.prechatfields="{}";

      let fields = Object.values(JSON.parse(msg.prechatfields.replace(/\n/g, " ")));

      //set request popup body text
      this.appstore.requestBodyText = "";
      let headText = "";
      let noNamefield = true;
      this.visitorName = "Visitor";
      if (fields.length != 0) {
        for (let i in fields) {
          if (fields[i].type === "dropdown"){
            if(fields[i].name!="" && fields[i]["params"][fields[i].value]!=undefined)
            this.appstore.requestBodyText = this.appstore.requestBodyText + "<p><b>" + fields[i].name + "</b> : " + (fields[i]["params"][fields[i].value] ? fields[i]["params"][fields[i].value].replace(/[\[\]"]+/g,'') : "") + "</p>"
          
          } else if (fields[i].id && fields[i].id === "noweb_new_department"){
            if(fields[i].name!="" && fields[i].value!=undefined)
            this.appstore.requestBodyText = this.appstore.requestBodyText + "<p><b>" + fields[i].name + "</b> : " + fields[i].value + "</p>";
          
          }else {
            if(fields[i].name!="" && fields[i].value!=undefined)
            this.appstore.requestBodyText = this.appstore.requestBodyText + "<p><b>" + fields[i].name + "</b> : " + fields[i].value + "</p>"
           
            // if (fields[i].name && fields[i].name.toLowerCase() === "name") {
            //   noNamefield = false;
            //   if (this.visitorName === "Visitor") {
            //     this.visitorName = fields[i].value.trim() ? fields[i].value.trim() : "Visitor";
            //     headText = "<p><b>" + this.visitorName + "</b> is in chat now, waiting for assistance </p>";
            //   }
            // }
            
            if (fields[i].type && fields[i].type.toLowerCase() === "name") {
              
              noNamefield = false;
              if (this.visitorName === "Visitor") {
                this.visitorName = (fields[i].value!=undefined && fields[i].value.trim()!="") ? fields[i].value.trim() : "Visitor";
                headText = "<p><b>" + this.visitorName + "</b> is in chat now, waiting for assistance </p>";
              }
            }
          }
        }

      if(noNamefield)
          headText = "<p> <b>Visitor</b> is in chat now, waiting for assistance </p>";
      } else
        headText = "<p> <b>Visitor</b> is in chat now, waiting for assistance </p>";
      
      msg.name=this.visitorName;
      
      
     let concurrent_chatscount= await this.appstore.checkconcurrent_chats();  
   
      let opmaxchatlimit=this.appstore.userInfo.usersmodel.max_chats;    
     if(opmaxchatlimit!=0 && this.appstore.maxconcurrentchats>=opmaxchatlimit) 
     return;
     

      //this.appstore.visitors[msg.sourceStation].push(msg);      
      this.appstore.chatRequests[msg.sourceStation].push(msg);      
      this.appstore.visitors[msg.sourceStation].push(msg)

      this.appstore.requestBodyText = headText + this.appstore.requestBodyText; 
      
      this.appstore.pushpopp=false;
      if(!this.appstore.chatpopuparray.includes(msg.sourceStation)){
        this.appstore.chatpopuparray.push(msg.sourceStation);
      }
      if(this.appstore.userInfo.allwebistes.length > this.appstore.userInfo.accountplan.max_websites){
        this.appstore.pushpopp=false;     
       }
      else if(this.appstore.userInfo.alloperators.length > this.appstore.userInfo.accountplan.max_ops && this.appstore.userInfo.accountplan.max_ops !=0 || this.appstore.userInfo.alldepartments.length > this.appstore.userInfo.accountplan.max_deps && this.appstore.userInfo.accountplan.max_deps!=0 && this.appstore.userInfo.allwebistes.length <= this.appstore.userInfo.accountplan.max_websites ) {
        this.appstore.pushpopp=false;
      }
      else{     
      this.appstore.pushpopp=true;
      }
      this.appstore.chatRequests[msg.sourceStation][0].requestBodyText= this.appstore.requestBodyText;
      this.appstore.chatRequests[msg.sourceStation][0].request_visitorName=this.visitorName;
      this.appstore.chatRequests[msg.sourceStation][0].chatrequestAccepted= true;

      //this.appstore.visitors[msg.sourceStation][0].requestBodyText= this.appstore.requestBodyText;
      //this.appstore.visitors[msg.sourceStation][0].chatrequestAccepted= true;
    //  try{ 
    //    toast.dismiss();
    //   const Foo = () => (
    //     <div>
    //     <span><img src={"#3ba8c5" == this.appstore.selectedColor.color ? imageURLs.bell_steel_blue : "#4cb6ac" == this.appstore.selectedColor.color ? imageURLs.bell_light_sea_green : "#ec407a" == this.appstore.selectedColor.color ?imageURLs.bell_pink : "#6e4c42" == this.appstore.selectedColor.color ?imageURLs.bell_brown : "#234e91" == this.appstore.selectedColor.color ?imageURLs.bell_Navy_blue : "#e89712" == this.appstore.selectedColor.color ?imageURLs.bell_yellow : "#b968c7" ==this.appstore.selectedColor.color ?imageURLs.bell_voilet : 
		// 							"#039be6" == this.appstore.selectedColor.color ? imageURLs.bell_blue : "#00887a" == this.appstore.selectedColor.color ? imageURLs.bell_dark_green : "#4b8f4e" == this.appstore.selectedColor.color ? imageURLs.bell_green : "#5f7c8c" == this.appstore.selectedColor.color ? imageURLs.bell_grey : "#64a9e0"== this.appstore.selectedColor.color ? imageURLs.bell_deep_sky_blue : imageURLs.bell_steel_blue} alt=""  /> new chat request</span>
    //     </div>
    //     );
    //   toast(Foo, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   });
    // }catch(e){}

      this.appstore.chatrequestAccepted = true;
      if(this.appstore.soundon_off){
        this.appstore.lodasound=0;
       this.appstore.getplaySound(this.appstore.chatRequests[msg.sourceStation][0].websiteUid,'chat_request');
      }
     
    }
  } 

  closeAccountDisabled = (appstore) => {
    appstore.isloggedout = true;
    appstore.logout();
  }
  onSockjsConnect =() => {
    console.log("connected");
    this.appstore.updateUserStatus("online");
    const user = this.appstore.userInfo.usersmodel;
    this.appstore.setofflineOnlineoplogin(this.appstore.userInfo.alloperators,user,"online");
    
    this.appstore.clientRef.sendMessage("/app/onOpconnect", JSON.stringify({"operatorUid":user.user_uid}));
    
  }
  
  onSocketDisconnect =()=>{
       console.log("disconnected.....");
       //this.appstore.clientRef.sendMessage("/app/onOpdisconnect", JSON.stringify({"op":123}));
      // alert("dis") 
  }
  getContent =() =>{
    if(this.appstore.deletesite){
     return "<p>Your plan includes "+this.appstore.userInfo.accountplan.max_websites+" websites. So please delete websites according to your plan.</p>"
    }else if(this.appstore.deleteoperator){
      return "<p>Your plan includes "+this.appstore.userInfo.accountplan.max_ops+" Operators and  "+this.appstore.userInfo.accountplan.max_deps+" Departments. So please delete operators and departments according to your plan.</p>"
    }else if(this.appstore.deleteopt){
      return "<p>Your plan includes "+this.appstore.userInfo.accountplan.max_ops+" Operators. So please delete operators according to your plan.</p>"
    }else if(this.appstore.deletechatbot && this.appstore.userInfo.accountplan.enable_chatbot==1){
      return "<p>Your plan includes "+this.appstore.userInfo.accountplan.max_chatbot+" ChatBot. So please disable ChatBot according to your plan.</p>"
    }else {
      return "<p>Your plan includes "+this.appstore.userInfo.accountplan.max_deps+" Departments. So please departments according to your plan.</p>"
    }
  }
   
  getContentop =() =>{
    this.appstore.disableop=true
  }
  render() {
    var self=this;    
    if (this.appstore.pageRefreshing) {
      return (
        <div className="profileLoader">
          <GridLoader
            css={override}
            size={20}
            marging={2}
            color={this.appstore.selectedColor.color}
          // loading={this.responseStore.responsereload}
          />
        </div>)
    }
    if (this.appstore.isloggedout) {
      return (
        <div className="profileLoader">
          <GridLoader
            css={override}
            size={20}
            marging={2}
            color={this.appstore.selectedColor.color}
          />
        </div>)
    }
    return (
      <React.Fragment> 
        <Router history={navigationStore.history} >
          <div className={"App container-fluid " + this.appstore.selectedColor.value}>
            {
              this.appstore.islogged &&
              <HeaderComponent appstore={this.appstore} />
            }
            <div className={"wrapper" + (this.appstore.operatorMenuActive ? ' operatorPanel' : '')}>

              {
                this.appstore.islogged &&
                <SideMenuComponent appstore={this.appstore} menuChanged={this.appstore.menuChanged} />
              }

              <div className={!this.appstore.menuActive ? "content row w-100 mr-0 main_content" : "content row w-100 mr-0 main_content menu_active"} >

                <Switch>
                   <Route exact path="/" render={() => <Login
                    appstore={this.appstore} />} /> 
                    <Route exact path="/new_password/:email/:token/:lang" 
                    render={(routerProps)=><ForgotComponent routerProps={routerProps} appstore={this.appstore}/>} /> 
                  <Route exact path="/home" render={() => <HomepageLayout
                    appstore={this.appstore} />} />
                  <Route path="/admin" render={() => <AdminPageLayout
                    appstore={this.appstore} />} />
                  <Route path="/profile" render={() => <ProfilePageLayout
                    appstore={this.appstore} />} />
                  <Route path="/traffic" render={() => <TrafficPageLayout
                    appstore={this.appstore} />} />
                  <Route path="/chats" render={() => <ChatsPageLayout
                    appstore={this.appstore} />} />
                </Switch>
              </div>

            </div>
            <div className={!this.appstore.menuActive ? "overlay d-lg-none" : "overlay active d-lg-none"}></div>
          </div>
        </Router>

        {this.appstore.islogged &&
          <div>
            <audio className="d-none"  id="helponclickaudio" loop="3" ref={this.appstore.requestSoundRef} src={this.appstore.soundPlaying} preload="auto" />
            
            <SockJsClient url= {this.appstore.socketUrl} topics={this.appstore.assignedTopics}
              headers={{"operatorUid":this.appstore.userInfo.usersmodel.user_uid , "account_id":this.appstore.userInfo.usersmodel.account_id}}
              onMessage={(msg) => { console.log("message recieved from server: " + JSON.stringify(msg)); this.onMessage(msg); }}
              ref={(client) => { this.appstore.clientRef = client }} autoReconnect={true}
              onConnect={() => { this.onSockjsConnect() }}
              onDisconnect={() => { this.onSocketDisconnect() }} />
          </div>
        }
        
          {
          this.appstore.showSessionOverPopup &&
           <SessionOverPopup show={this.appstore.showSessionOverPopup}
            store={this.appstore}
            title={'Your session has expired. Please log in again'}
            save_btn="Login"
            isAutoLoggedOff={this.appstore.isAutoLoggedOff}
            appStore={this.appstore} />
        } 
        {
				   this.appstore.deletesite && 
           <ConfirmationComponent show={this.appstore.deletesite}
           store={this.appstore}
           title="Plan Downgraded"
           body_html={this.appstore.isOwner ? this.getContent():"<p>Your account has downgraded. Please contact "+ this.appstore.userInfo.usersmodel.ownerEmail +"</p>"}
           cancel_btn={this.appstore.isOwner ? "Okay":"Logout"}
           singleButton
           callBack={() => this.appstore.isOwner ? this.appstore.deletesite=false : this.closeAccountDisabled(this.appstore)}
           closePopup={() => this.appstore.isOwner ? this.appstore.deletesite=false : this.closeAccountDisabled(this.appstore)}
           appStore = {this.appstore}/>
         }
         {
				   (this.appstore.deletechatbot && this.appstore.userInfo.accountplan.enable_chatbot ==1) && 
           <ConfirmationComponent show={this.appstore.deletechatbot}
           store={this.appstore}
           title="Plan Downgraded"
           body_html={this.appstore.isOwner ? this.getContent():"<p>Your account has downgraded. Please contact "+ this.appstore.userInfo.usersmodel.ownerEmail +"</p>"}
           cancel_btn={this.appstore.isOwner ? "Okay":"Logout"}
           singleButton
           callBack={() => this.appstore.isOwner ? this.appstore.deletechatbot=false : this.closeAccountDisabled(this.appstore)}
           closePopup={() => this.appstore.isOwner ? this.appstore.deletechatbot=false : this.closeAccountDisabled(this.appstore)}
           appStore = {this.appstore}/>
         }
        {
				   this.appstore.deleteoperator &&
					 <ConfirmationComponent show={this.appstore.deleteoperator}
           store={this.appstore}
           title="Plan Downgraded"
           body_html={this.appstore.isOwner ? this.getContent():"<p>Your account has downgraded. Please contact "+ this.appstore.userInfo.usersmodel.ownerEmail +"</p>"}
           cancel_btn={this.appstore.isOwner ? "Okay":"Logout"}
           singleButton
           callBack={() => this.appstore.isOwner ? this.appstore.deleteoperator=false : this.closeAccountDisabled(this.appstore)}
           closePopup={() => this.appstore.isOwner ? this.appstore.deleteoperator=false : this.closeAccountDisabled(this.appstore)}
           appStore = {this.appstore}/>
				}
         {
				   this.appstore.deletedept &&
					 <ConfirmationComponent show={this.appstore.deletedept}
           store={this.appstore}
           title="Plan Downgraded"
           body_html={this.appstore.isOwner ? this.getContent():"<p>Your account has downgraded. Please contact "+ this.appstore.userInfo.usersmodel.ownerEmail +"</p>"}
           cancel_btn={this.appstore.isOwner ? "Okay":"Logout"}
           singleButton
           callBack={() => this.appstore.isOwner ? this.appstore.deletedept=false : this.closeAccountDisabled(this.appstore)}
           closePopup={() => this.appstore.isOwner ? this.appstore.deletedept=false : this.closeAccountDisabled(this.appstore)}
           appStore = {this.appstore}/>
				}
         {
				   this.appstore.deleteopt &&
					 <ConfirmationComponent show={this.appstore.deleteopt}
           store={this.appstore}
           title="Plan Downgraded"
           body_html={this.appstore.isOwner ? this.getContent():"<p>Your account has downgraded. Please contact "+ this.appstore.userInfo.usersmodel.ownerEmail +"</p>"}
           cancel_btn={this.appstore.isOwner ? "Okay" :"Logout"}
           singleButton
           callBack={() => this.appstore.isOwner ? this.appstore.deleteopt=false : this.closeAccountDisabled(this.appstore)}
           closePopup={() => this.appstore.isOwner ? this.appstore.deleteopt=false : this.closeAccountDisabled(this.appstore)}
           appStore = {this.appstore}/>
				}
        {/* {
          this.appstore.chatrequestAccepted &&
           <ConfirmationComponent show={this.appstore.chatrequestAccepted}
            store={this.appstore}
            title={this.visitorName}
            body_html={this.appstore.requestBodyText}
            cancel_btn="Reject"
            save_btn="Accept"
            callBack={() => this.appstore.acceptChatRequest(this.state.sourceStation)}
            closePopup={() => this.appstore.rejectChatRequest()}
            appStore={this.appstore} />
        } */}
        { this.appstore.pushpopp &&
          this.appstore.chatpopuparray.map(function (response, index) {
           
            return (

              (self.appstore.chatRequests[response] && self.appstore.chatRequests[response][0] && self.appstore.chatRequests[response][0].chatrequestAccepted) &&
              <ChatConfirmationComponent show={self.appstore.chatRequests[response][0].chatrequestAccepted}
                store={self.appstore}
                // title={self.visitorName}
                title={self.appstore.chatRequests[response][0].request_visitorName}
                body_html={self.appstore.chatRequests[response][0].requestBodyText}
                cancel_btn="Reject"
                save_btn="Accept"
                chat_accept_enable={(self.appstore.userInfo.accountplan.monthly_chats_limit!=0 && self.appstore.monthly_chat_enable_count>=self.appstore.userInfo.accountplan.monthly_chats_limit) ? false :true}
                callBack={() => self.appstore.acceptChatRequest(self.appstore.chatRequests[response][0].sourceStation)}
                closePopup={() => self.appstore.rejectChatRequest(self.appstore.chatRequests[response][0])}
                appStore={self.appstore} />
                

            )
          
            }
          )
        }
        { 
					this.appstore.chatacceptalert && 
					<ConfirmationComponent show={this.appstore.chatacceptalert}
											store={this.appstore}
											title="Chat accept status"
											body_html={"<p>"+this.appstore.getLanguageTrans("Oops! other operator accepted the chat before you accept.")+"</p>"}
											cancel_btn="Ok"
                      callBack={() => this.appstore.chatacceptalert = false}
											closePopup={() => this.appstore.chatacceptalert = false}
                      appStore = {this.appstore}
                      singleButton={true} />
				}
        
       {/* <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      /> */}
      {/* Same as */}
      {/* <ToastContainer /> */}
     
      </React.Fragment>
    );
  }
})

export default App;
