import { decorate, observable, extendObservable,action } from 'mobx';
import draftToHtml from 'draftjs-to-html';
import {  convertToRaw, ContentState, DefaultDraftBlockRenderMap } from 'draft-js';

import axios from 'axios';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class ChatComponentStore {
	  

  	constructor(){
      this.setDomEditorRef = ref => this.domEditor = ref;
         this.optypingcount=0;
         this.opHasleft=false;
          this.cannedFileNameRemove =[];
          this.prechatfields=[];
          this.filterResponseInput="";
          //this.cannedFile=[];
          this.widgetWebsite={};
          this.allVirtualAgents = [];
          this.undersupervision=false;
          this.currentVisitorTyping="";
         // this.appstore=appStore ? appStore :'';
          this.userdata ={
              "name":"",
              "email":"",
              "website":"",
              "question":"",
              "sourceStation":""
          };
         
          this.defaultTab="info";
          this.chatOnrefresh=false;
          this.showgeoMap= false;
          this.fullcontactFound= false;
          this.showOpforTransferchat=false;
          this.showResponses = false;
          this.currentChattingVisitor = [];

          this.hideInfoSection = false;
          this.quitChatConfirm=false;
          this.historyTabContent = [
            {
              "date": "",
              "time": "",
              "issue": "",
              "showDetails": false,
              "transcripts":{
                "website": "",
                "dept": "",
                "chatTime": "",
                "chatData": [
                  {
                    "name": "",
                    "time": "",
                    "chat": ""
                  }
                ]
              }
            }]; 
          
          this.infoTabData = {
              name: '',
              email: '',
              location: '',
              ipAddress:'',
              coordinates: {"latitude":"","longitude":""},
              privacyPolicyConsent: false,
              website: '',
              dept: '',
              fullContact: {
                  name: '',
                  websites: [],
                  gender: ''
              },
              currentPage: '',
              startPage: '',
              referrer: '',
              screen: '',
              browser: '',
              ip: '',
              language: '',
              notes: []
          };
        this.infoTabAddNotes = false;
        this.infoNote = '';
        this.footstepsTabContent = [];
        this.responsesTabContent = [];
        this.fileLife = [
          {value: 0, label: 'Permanent'},
          {value: 1, label: 'Until chat session ends'}
        ]
        this.fileData = {
          name: '',
          size: '',
          life: this.fileLife[0]
        }

        this.chat = "";
        this.editorEditedValue = ""; 
        this.imageRef = null;
        this.imagewarning = false;
        this.warning_image_name = "";
        this.count=0; 
        this.transcript_error="";
        this.validcondtion="false";
        this.mailstatus=false;
        this.banvisitor_initiate=false;
        this.errormodel=false;
        this.chatprintdata="";
        this.iscannedEnabled=0;
        this.isSc_CannedEnabled=0;
     }


    getcurrentUtcTime = ()=>{
      //get current utc time without seconds
      let now = new Date();
     // return Math.floor((now.getTime() + (now.getTimezoneOffset() * 60000))/1000);
     return Math.floor((now.getTime())/1000);
    }
    
    getLocalTime = (utcTimestamp) =>{
      //get converted current local time from utc time
      let now = new Date();
       // let cur = now.setTime(utcTimestamp*1000 - (now.getTimezoneOffset() * 60000));
       let cur = now.setTime(utcTimestamp*1000);
        let date = new Date(cur);
        // Hours part from the timestamp
        var hours = date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();
        //date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  
        // return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    }

   

    displayTyping(appstore){ 
  //    const currentTime = this.getcurrentUtcTime();
  //    const user = appstore.userInfo.usersmodel;
  //    const userRole = user.user_type_id == 3 ? 'admin' : 'operator';
  //    let opMsg = {
  //     id: '', type: "opsMsg", role: userRole, userUid: appstore.requestedVisitorId, operatorUid: user.user_uid,
  //     message: "", systemMsg : true,creationTime: currentTime, hostname: 'ip', sourceStation: user.user_uid, chatStatus: "op_typing",
  //     account_uid: appstore.userInfo.accountsmodel.account_uid, name: user.nick_name, email: user.email
  //     , fileName: "", originalFileName: "", fileSize: "", targetStation: '/topic/' +appstore.requestedVisitorId
  // };

  // appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
    }

     onEditorStateChange(editorState) { 
      this.optypingcount=this.optypingcount+1;
       let checkText= "";
       this.editorState = editorState;
       this.editorEditedValue = draftToHtml(convertToRaw(this.editorState.getCurrentContent()));
      /// this.chat =this.editorEditedValue
       checkText = this.editorEditedValue.replace(/<\/?[^>]+(>|$)/g, "").trim();
      // console.log(checkText.lastIndexOf("#"));
      
      //  if(checkText.length > 1) {
      //    if(checkText[checkText.length-1]==="#" && checkText[checkText.length-2]===" ")
      //         this.showResponses=true;
      //     else
      //         this.showResponses=false;  
      //   }else if(checkText.length==1){
      //       if(checkText ==="#")
      //         this.showResponses=true;
      //   }else {
      //       this.showResponses=false;
      //   } 
      
       if(checkText.length > 1) {
         let pos = checkText.lastIndexOf("#")
         if((pos != -1 && checkText[pos-1]===" ") || (pos != -1 && checkText[0]==="#"))
           { 
            checkText = checkText.replace(/&nbsp;/g, ' ');
            this.filterResponseInput = checkText.slice(pos+1,checkText.length).trim();
            //console.log(this.filterResponseInput)
            this.showResponses= (this.iscannedEnabled==1 && this.isSc_CannedEnabled==1) ? true : false;
           }
            else
                this.showResponses=false;  
        }
        else if(checkText.length==1){
            if(checkText ==="#"){
              
              this.showResponses= (this.iscannedEnabled==1 && this.isSc_CannedEnabled==1) ? true : false;
            }
              
        }else {
            this.filterResponseInput="";
            this.showResponses=false;
        } 
     }

     getConsent(appstore,uid){
        let website =  appstore.userInfo.allwebistes.find(website => website.website_uid === uid)
        return website ? (website.enable_consent == 1 ? "Yes":"No" ): "";
      }

     getWebsiteName(appstore,uid){
       let website =  appstore.userInfo.allwebistes.find(website => website.website_uid === uid)
       return website ? website.name : "";
     }

     getDeptName(appstore,uid){
        let department =  appstore.userInfo.alldepartments.find(dept => dept.website_uid === uid)
        return department ? department.name : "All";
      }

     getResponsesData = (appstore,widgetWebsite="") => {
      //get response data for response  
      axios.get(appstore.url + 'getresponsedata.json')
        .then(result => {
          if(result.data.length==1){
            if(result.data.logoutstatus==1){
					  appstore.logout();
            }
				  }
          //load response data
          this.showResponseslist(result.data, appstore,widgetWebsite);
          appstore.global_errorcount=0;
        }).catch((error) => {
          if (error.response) {
           // console.log(error)
           //this.props.appstore.logout();
           console.log(error);
            console.log("error count :"+appstore.global_errorcount);
            appstore.global_errorcount=appstore.global_errorcount+1;
            if(appstore.global_errorcount>20){
            // appstore.logout();
            }
          }
        });
    }

    getUserNotes(appstore,ip){
        
      axios({
			method: 'POST',
			url: appstore.url + 'getusernotes.json',
            headers: { 'content-type': 'application/json' },
            data:{user_uid:'akdabsjkbfjk113e23',ip:ip}
	 	  }).then(result => {  
        if(result.data.length==1){
          if(result.data[0].logoutstatus==1){
            appstore.logout();
          }
          }          
        if(result.data != null){
          this.infoTabData.notes=[];
            for(let i in result.data)
              {
                this.infoTabData.notes.push({
                    time: result.data[i].date_added,
                    by: appstore.userInfo.usersmodel.nick_name,
                    note: result.data[i].note
                });
             }
                this.infoNote = "";
            }
           
		}).catch((error) => {
			if (error.response) {
				//console.log(error);
				//appstore.logout();
			}

        })
     
    }


    addUserNotes(appstore,ip){
        this.infoTabAddNotes = !this.infoTabAddNotes
        if(this.infoNote !== '') {
        axios({
			method: 'POST',
			url: appstore.url + 'addusernotes.json',
            headers: { 'content-type': 'application/json' },
            data:{user_uid:'akdabsjkbfjk113e23',ip:ip,note: this.infoNote,email:""}
		}).then(result => {
            
        if(result.data != null){
          this.infoTabData.notes=[];
            for(let i in result.data)
              {
                this.infoTabData.notes.push({
                    time: result.data[i].date_added,
                    by: appstore.userInfo.usersmodel.nick_name,
                    note: result.data[i].note
                });

             }
                this.infoNote = "";
            }
            appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	//console.log(error);
			// 	appstore.logout();
			// }
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }

        })
     }
    }
    getAddressforIp = (appstore,ip) => {
	
    //get address of ip  
    if(!ip)
    return;

		axios.get(appstore.url+'getgeodata.json?ipAddress='+ip)
			.then(result => {
                //show address based on Ip address
                
                if(result.data != null) {
                  this.infoTabData.ipAddress = ip;
                  this.infoTabData.coordinates = {"latitude":result.data.latitude,"longitude":result.data.longitude	};
                  this.infoTabData.location = (result.data.city!=undefined && result.data.country!=undefined) ? result.data.city+", "+result.data.country:"";
                  //this.showgeoMap=true;
                }else{
                 //console.log("address not found");
                }
               
			}).catch((error) => {
				if (error.response) {
					//console.log(error)
					// this.props.appstore.logout();
				}
			});
    }

    getCurrentchatOnRefresh= async (appstore) =>{

      const visitorId = appstore.requestedVisitorId;
      await axios.post(appstore.url+'getallOngoingchat.json?visitorid='+visitorId)
			.then(result => {
        if(result.data.length==1){
          if(result.data[0].logoutstatus==1){
            appstore.logout();
          }
          }
        // if(result.data !=null){
        //    appstore.visitors[visitorId] = [];
        //    for(let i in result.data) {
        //      if(result.data[i].role ==="visitor")
        //        result.data[i].type="inMsg"; 
        //        else
        //        result.data[i].type="opsMsg"; 

        //        appstore.visitors[visitorId].push(result.data[i]);  
        //        appstore.requestedVisitorId = visitorId;
        //    }
       
        //    return visitorId;
        // }else{
        //   return "";
        // }
                    
			}).catch((error) => {
				if (error.response) {
					//console.log(error)
				}
			});
    }

    showResponseslist(data,appstore,widgetWebsite='') {
 
        let allresponses = data.responsesmodel;
        let loggedOp = appstore.userInfo.usersmodel.user_uid;
        let allDept = data.dropdowndatavo.opDepts;
        let allWebsites = data.dropdowndatavo.opWebsites;
        let order=appstore.userInfo.usersmodel.response_order;
    
        if(allresponses.length == 0 || allresponses==null){
          this.responsesTabContent=allresponses;
          this.allResponses=allresponses.slice(0);
        }
        let visitor_selected_dept="";
        let fields=[];
        fields= this.prechatfields;
        
      for (let i in fields) {
        if (fields[i].hasOwnProperty("department")) {
          visitor_selected_dept = fields[i]["department"].trim();
          break;
        }
      }
      var ex=0;
      for (let i in fields) {
        if (fields[i].hasOwnProperty("id")) {
          if(fields[i]["id"]=="noweb_new_department"){
            // console.log("consent");
            ex=1;
          break;
          }
        }
      }
      if(ex==1){
       this.infoTabData.privacyPolicyConsent=true;
      }else{
        this.infoTabData.privacyPolicyConsent=false;
      }

        let arrayresponse =[]; 
        let filteredResponse = allresponses.filter(function (response) { 
            if((response.website_uid.length ==0 || response.website_uid === widgetWebsite) && (response.department_uid.length == 0 || response.department_uid==visitor_selected_dept)){ 
              arrayresponse.push(response);
            } 
            return arrayresponse;
        });
// console.log(arrayresponse);
        if(typeof(order)=="string" && order.length !=0){
          order = JSON.parse(order);
          allresponses =  this.sortResponse(order,arrayresponse);
        }

        this.allResponses = arrayresponse;
        //clone array of responses
        this.responsesTabContent = arrayresponse.slice(0);
        //open response tab as default
        let iscannedEnabled = appstore.userInfo.accountplan.canned_response;
        if(iscannedEnabled==1){
        this.defaultTab="response";
        }
        // if(appstore.isAdmin){
        //   if(typeof(order)=="string" && order.length !=0){
        //     order = JSON.parse(order);
        //     allresponses =  this.sortResponse(order,arrayresponse);
        //   }
        //   this.responsesTabContent=allresponses;
        //   this.allResponses=allresponses.slice(0);
        //   this.defaultTab="response";
        //   return;
        // }
        
        // let arrayresponse =[]; 
        // let filteredResponse = allresponses.filter(function (response) {
        //   if(response.op_uid.length !=0 && response.op_uid === loggedOp ){
        //     arrayresponse.push(response);
        //   }
        //  else if(response.op_uid.length==0 && response.department_uid.length !=0 ){
        //     if(allDept.includes(response.department_uid)){
        //       arrayresponse.push(response);
        //     }
        //   }
        //    if(response.op_uid.length==0 && response.department_uid.length ==0 && response.website_uid.length ==0){
        //       arrayresponse.push(response); 
        //   }
        //   else if (response.op_uid.length==0 && response.department_uid.length ==0 && response.website_uid.length !=0)
        //   {
        //     if(allWebsites.includes(response.website_uid)){
        //       arrayresponse.push(response);
        //     }
    
        //   }
       
        //   return arrayresponse;
        // });
        //sort as per order for operator role
        // if(typeof(order)=="string" && order.length !=0){
        //   order = JSON.parse(order);
        //   arrayresponse =  this.sortResponse(order,arrayresponse);
        // }
    
        //store for using while filtering responses
        //  this.allResponses = arrayresponse;
        // //clone array of responses
        // this.responsesTabContent = arrayresponse.slice(0);
        // //open response tab as default
        // this.defaultTab="response";
        }

        sortResponse(order,responses){
            let sortedOrder =[];
            //sort order as per value
            if(order){
              for (var uid in order) {
                sortedOrder.push([uid, order[uid]]);
            }
             sortedOrder.sort(function(a, b) {
                  return a[1] - b[1];
              });
            }
            //sort responses as per the orders saved by user
            let sortedResponses=[];
              for(let i in sortedOrder)
              {
                for(let j in responses){
                  if(sortedOrder[i][0]==responses[j].response_uid){
                      sortedResponses.push(responses[j]);
                  }
                }
              }
        
              let filtered = responses.filter( function(e) {
                    if(this.indexOf(e) < 0){
                          sortedResponses.push(e);
                     }
                  },sortedResponses);
            return sortedResponses;
          }
    
      
    
     resetValues() {
        this.chat="";
        this.editorState  = null;
        this.editorEditedValue = "";

     }

    getTime(timeinMs){
       // Milliseconds to current date and current Time
      let date = new Date(timeinMs);
      let time=date.toString().split(" ");
      return time[4]

      }

      isEmptyOrSpaces=(str) =>{

      let msgForTest = str;
          msgForTest =msgForTest.replace(/<\/?[^>]+>/gi, '');
          return msgForTest.replace(/&nbsp;|\s/g, '').length === 0;
   }

   filelifeupdate=(appstore,filepath,selectedoption,talkSessionUid) =>{ 
    var fileid=filepath.split("/"); 
    var filename=fileid[7].split("."); 
  
    let dataObj = { "talkSessionUid": "" ,"resourceUid": filename[0]};
    if(selectedoption.value == 1){
      dataObj.talkSessionUid=talkSessionUid;
    } 
    else{
       delete dataObj["talkSessionUid"];
    }

    axios({
        method: 'POST',
        url: appstore.url + 'chatfilelife.json',
        headers: { 'content-type': 'application/json' },
        data: dataObj
    }).then(result => {
       // console.log(result.data);
       appstore.global_errorcount=0;
    }).catch(error =>{
      //  appstore.logout();
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })
    }

    banVisitor=(appstore) =>{
      this.banvisitor_initiate=true; 
    }
    banvisitor_initiate_ip = (appstore) =>{
      let visitor="";
      if(Array.isArray(this.currentChattingVisitor)){
          visitor =  this.currentChattingVisitor[0];
      }else 
        return;

        if(!visitor)
        return;

        let ip = visitor.visitorEnvironment.ipAddress ?  visitor.visitorEnvironment.ipAddress:""; 
        if(ip===""){
          return;
        }
     
        axios({
          method: 'POST',
          url: appstore.url + 'banvisitor.json',
          headers: { 'content-type': 'application/json' },
          data: { "account_uid": visitor.account_uid ,"ip_black_list":ip }
        }).then(result => {
          appstore.global_errorcount=0;
        }).catch(error =>{
          //  appstore.logout();
          console.log(error);
          console.log("error count :"+appstore.global_errorcount);
          appstore.global_errorcount=appstore.global_errorcount+1;
          if(appstore.global_errorcount>20){
          // appstore.logout();
          }
        })
        this.banvisitor_initiate=false; 
        this.currentChattingVisitor[0].quitAndBackTosupervision=false ; 
        this.quitChat(appstore)
    } 

     getFullContactDetail = async (appstore,email) =>{
     this.fullcontactFound= false;
     
      if(email.trim().length ==0)
        return;
       
       await axios.get(appstore.url+"getfullcontact.json?e="+email)
        .then(response => {
          // handle success
          if(response.data.fullName != null){
           this.infoTabData.fullContact= response.data;
           this.fullcontactFound = true;
          }
        })
        .catch(error => {
          // handle error
          //console.log(error);
        })
    }

    getHistoryOfVisitor = (appstore,email) => {
      //get history based on email
      if(email.trim().length ==0  || email === "not provided")
      return;
  
        axios({
          method: 'POST',
          url:appstore.url+"gethistoryofVisitor.json",
          headers: { 'content-type': 'application/json' },
          data: {account_uid:appstore.userInfo.accountsmodel.account_uid,email:email,account_id:appstore.userInfo.accountsmodel.account_id}
          })
         .then(response => {
          //console.log(response);
          const data = response.data;
          
          if(data == null || data.length ==0)
          return;

          this.allVirtualAgents = data[0].vasettings;
          this.allAISettings =  data[0].aisettings;
          this.historyTabContent=[];

        for(let i=0; i< data.length; i++){
            let timeandDate = this.convertUTCDateToLocalDate(data[i].archived);
            timeandDate =  moment(timeandDate).format("DD.MM.YYYY HH:mm").toString();
            const date = timeandDate.split(" ")[0];
            const time =  timeandDate.split(" ")[1];;

            this.historyTabContent.push({ 
              "session_uid":data[i].session_uid,
              "date": date,
              "time": time,
              "issue": "",
              "showDetails": false,
              // "transcripts":{
              //   "website": "HelpOnClick",
              //   "dept": "Sales",
              //   "chatTime": "5m 32s",
              //   "chatData": [
              //     {
              //       "name": "Megan",
              //       "time": "23:44:11",
              //       "chat": "Hi!<br>This is so cool and helpful!<br>But I wonder why I remove settime as this"
              //     },
              //     {
              //       "name": "Tatiana",
              //       "time": "23:44:11",
              //       "chat": "hi. this is not working for IE11"
              //     }
              //   ]
              // }
            })
           }
           })
        .catch(error => {
         // appstore.logout();
        })
    }

    downloadingfile = (appstore,fileurl,filename) =>{
      //var file_url=appstore.url+"filesdownload?fileurl="+fileurl;
      appstore.toDataURL(fileurl, function(dataUrl) { 
       appstore.downloadBase64File(dataUrl, filename)
      });
    }


    handleSelectedResponse(response) {
      
       const pos = this.editorEditedValue.lastIndexOf("#"); 
       const length = this.editorEditedValue.length;
       //this.editorEditedValue =  this.editorEditedValue.replace(this.editorEditedValue[pos],"")+"<p>  "+response.question+"</p>";
    
       
      
       let replaceText = this.editorEditedValue;
      var regexp = new RegExp('#([^\\s]*)','g');
        replaceText = replaceText.replace(regexp, '');
        replaceText =replaceText + "</p>";
       //this.editorEditedValue = replaceText +" "+response.question+"</p>"; 
       replaceText = replaceText.replace(/<p>​<\/p>/gi, '');
       replaceText = replaceText.replace(/<p>\s*<\/p>/ig, '');
       replaceText = replaceText.replace(/<p\s*\/>/ig, '');
       replaceText = replaceText.replace(/\n/g, ""); 
       this.editorEditedValue = replaceText.replace("<[^/>]+>[ \n\r\t]*</[^>]+>","") +" "+response.value;
       

       // find if image attached in response
      if(response.imageslist.length !=0){
           //this.cannedFile =  response.imageslist;
           for(let i=0; i<response.imageslist.length; i++){
            this.editorEditedValue =  this.editorEditedValue + "</br>"+response.imageslist[i].title;
            this.cannedFileNameRemove.push(response.imageslist[i]);
           }
      }
   
       this.count = this.count+1;
       this.chat = this.editorEditedValue;
       this.showResponses=false;

   }

   handleDoublclickedResponse(response){
    //replace empty tags and add selected response value 
    this.editorEditedValue=this.editorEditedValue.replace(/<p>​<\/p>/gi, '');
    this.editorEditedValue = this.editorEditedValue.replace(/<p>\s*<\/p>/ig, '');
    this.editorEditedValue = this.editorEditedValue.replace(/<p\s*\/>/ig, '');
    this.editorEditedValue = this.editorEditedValue.replace(/\n/g, "");
       this.editorEditedValue =  this.editorEditedValue.replace("<[^/>]+>[ \n\r\t]*</[^>]+>","") +""+response.value;
        // find if image attached in response
       if(response.imageslist.length !=0){
        //this.cannedFile =  response.imageslist;
        for(let i=0; i<response.imageslist.length; i++){
         this.editorEditedValue =  this.editorEditedValue + "</br>"+response.imageslist[i].title;
         this.cannedFileNameRemove.push(response.imageslist[i]);
        }
      }
     
       this.count = this.count+1;
       this.chat = this.editorEditedValue; 
   }


   async quitChat(appstore){
    this.quitChatConfirm = false;
    appstore.chatpopuparray = appstore.chatpopuparray.filter(e => e !== appstore.requestedVisitorId);    
   
    if(!appstore.requestedVisitorId)
    return;


   // const currentTime = Math.round((new Date()).getTime() / 1000);
    let now = new Date();
    //const currentTime = Math.floor((now.getTime() + (now.getTimezoneOffset() * 60000))/1000);
    const currentTime = Math.floor((now.getTime())/1000);
    const user = appstore.userInfo.usersmodel;
    const userRole = user.user_type_id == 3 ? 'admin':'operator';
    let opMsg = {
        id: '', type: "opsMsg", role:userRole,userUid: appstore.requestedVisitorId, operatorUid:user.user_uid,  message: "",
         creationTime: currentTime, hostname: 'ip',system_msg :true, sourceStation: appstore.requestedVisitorId, chatStatus: "opQuit",
         account_uid: appstore.userInfo.accountsmodel.account_uid, name: user.nick_name, email: user.email
        , fileName: "", originalFileName: "",fileSize:"", targetStation: '/topic/' + appstore.requestedVisitorId
    }

    
    let chattingOperators =[];
    let isInvitedChat = false
    //alert(JSON.stringify(appstore.inviteJoinedByOp[appstore.requestedVisitorId]))
    if(appstore.inviteJoinedByOp[appstore.requestedVisitorId]){
      isInvitedChat = true;
    }
    if(isInvitedChat){

      let otherOps = appstore.inviteJoinedByOp[appstore.requestedVisitorId];
      //send to other opeartors
      for(let i=0; i<otherOps.length; i++){
        opMsg.chatStatus = "inviteLeft";
        opMsg.system_msg = true;
        opMsg.message = user.nick_name +" has left the chat";
        opMsg.sourceStation = appstore.requestedVisitorId;
        opMsg.targetStation = '/topic/' + otherOps[i];
        appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
        opMsg.message = "";
       }
      
       //send to visitor
       opMsg.targetStation = '/topic/' + appstore.requestedVisitorId;
       appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));

       appstore.handleQuitchat([appstore.requestedVisitorId]); 
    }

   if(this.undersupervision){
        //dont quit ongoing chat if under supervision, unset targetStation from operator and visitor
        
        if(this.currentChattingVisitor[0].joinedConversation){
              opMsg.chatStatus = "supervisionQuit";
              opMsg.pageTitle = this.currentChattingVisitor[0].pageTitle;
              opMsg.formUrl = this.currentChattingVisitor[0].formUrl;
              opMsg.staff_message=false;
              opMsg.message = user.nick_name+" has left chat";
              //set for visitor
              opMsg.targetStation = '/topic/' + appstore.requestedVisitorId;
              appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
              chattingOperators = this.currentChattingVisitor[0].allchattingOperators;
                    

             
              // for(let i=0; i< this.currentChattingVisitor.length; i++){
                
              //   if(this.currentChattingVisitor[i].role ==="visitor")
              //       continue;

              //   else if(this.currentChattingVisitor[i].acceptedBy && this.currentChattingVisitor[i].acceptedBy !== user.user_uid){
              //      opMsg.targetStation = '/topic/'+ this.currentChattingVisitor[i].acceptedBy; 
              //     //send to operator
              //     if(!chatting_operators.includes(this.currentChattingVisitor[i].acceptedBy)){
              //         opMsg.acceptedBy = "supervisor";
              //         appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
              //         chatting_operators.push(this.currentChattingVisitor[i].acceptedBy);
              //     }
                  
              //   }
              // }
              if(chattingOperators && chattingOperators.length !=0) {  
                // if( chattingOperators.length > 1){
                    for(let opid of chattingOperators){
                      if(opid!=user.user_uid){
                        opMsg.targetStation = '/topic/' + opid;
                        opMsg.acceptedBy = "supervisor";
                        //send msg to operator
                        appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                      }
                    }
                // }
              }
            
          }

          if(this.currentChattingVisitor[0].quitAndBackTosupervision)  
              this.undersupervision = this.currentChattingVisitor[0].quitAndBackTosupervision ? true : false;
       
          this.currentChattingVisitor[0].joinedConversation = false;
          delete appstore.undersupervisionvistors[appstore.requestedVisitorId];
    }
    else if(!isInvitedChat){

          if( appstore.supervisedBy[appstore.requestedVisitorId]){
              //operator under supervised, send message to supervising admin
              opMsg.targetStation = '/topic/' + appstore.supervisedBy[appstore.requestedVisitorId];
              appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
              opMsg.targetStation = '/topic/' +  appstore.requestedVisitorId;
            }

            
          //to visitor
          appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));

        await  appstore.handleQuitchat([appstore.requestedVisitorId])

          //remove from  trffic data
          appstore.removeFromTraffic([appstore.requestedVisitorId]);

          //send message to all op about quit chat
           //send status to Admin
           
          let targetUid ="";
          const visitorObj = this.currentChattingVisitor.find(obj => obj.chatStatus==="chatRequest");
          
          //check widget relation betwwen operators
          if(visitorObj)
          {
            targetUid = visitorObj.targetStation.split("/")[2];
          }
          
              opMsg.targetStation ='/topic/'+targetUid;
              opMsg.operatorUid = user.user_uid;
              opMsg.chatStatus = "chatted";
              opMsg.pageTitle = this.currentChattingVisitor[0].pageTitle;
              opMsg.formUrl = this.currentChattingVisitor[0].formUrl;
              opMsg.system_msg=true;
              opMsg.sourceStation = this.currentChattingVisitor[0].sourceStation;
              opMsg.visitorEnvironment = this.currentChattingVisitor[0].visitorEnvironment;
              opMsg.acceptedBy = "";
              opMsg.creationTime = currentTime;
            
              appstore.clientRef.sendMessage("/app/op2op", JSON.stringify(opMsg)); 
        
          //if visitor has left or closed tab , archive now 
          if(this.currentChattingVisitor[0].visitorLeft)
              this.callToArchive(appstore);

          opMsg.account_uid = appstore.userInfo.accountsmodel.account_uid;
          opMsg.assist_uid = this.currentChattingVisitor[0].assist_uid;
          opMsg.websiteUid = this.currentChattingVisitor[0].websiteUid ;
          opMsg.activeStatus = "inactive";
         
          appstore.updateActiveWebsite_widget(opMsg);   
      }

      // remove entry from traffic monitor
      //this.updateTrafficEntry(appstore, opMsg);
    
       //return here if Popup quit supervision mode has not selected and show 'Back to supervision mode' option to admin
     
       if(this.currentChattingVisitor[0].quitAndBackTosupervision){
            this.superVisionMode(appstore,chattingOperators);
            return;
       }
         

       //remove from global chat object
       this.resetToDefault();

      this.currentChattingVisitor = [];
      this.prechatfields=[];
      delete appstore.visitors[appstore.requestedVisitorId];
      
      let visitorList =[]; 
    
      for(let i in appstore.visitors){
          visitorList.push(appstore.visitors[i][0]);
      } 
      appstore.visitorList = visitorList;
     
      // appstore.trafficVisitorStatus = trafficData;
      
      if(Object.keys(appstore.visitors).length == 0){
         appstore.requestedVisitorId = "";
      }
      else{
        let keys = Object.keys(appstore.visitors); //array of visiotr id 
        appstore.requestedVisitorId = keys[0];
      }

   }

   resetToDefault=()=> {
    this.userdata ={
      "name":"",
      "email":"",
      "website":"",
      "question":"",
      "sourceStation":""
    };

    this.infoTabAddNotes = false;
    this.infoNote = '';
    this.footstepsTabContent = [];
    this.responsesTabContent = [];
    this.prechatfields=[];
  }

   callToArchive =(appstore) =>{    
    axios({
      method: 'POST',
      url: appstore.url + 'callArchive.json?visitorId='+appstore.requestedVisitorId,
            headers: { 'content-type': 'application/json' }
      }).then(result => {            
        
        //console.log(result)
        appstore.global_errorcount=0;
    }).catch((error) => {
      // appstore.logout();
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
     })
   }

   superVisionMode(appstore,chatting_operators){
        
      const visitorId =  appstore.requestedVisitorId;
      //send supervision status to operator and visitor for setting desitination
      const user =  appstore.userInfo.usersmodel;
      const currentTime = Math.round((new Date()).getTime() / 1000);
      const userRole = user.user_type_id == 3 ? 'admin' : 'operator';

      let opMsg = { 
                    id:'',system_msg:true, userUid:visitorId, operatorUid:user.user_uid, creationTime:currentTime,
                    role:userRole, account_uid:appstore.userInfo.accountsmodel.account_uid, acceptedBy:"", message:"",
                    fileName:"",originalFileName:"",fileSize:"", hostname:'ip', sourceStation:user.user_uid,chatStatus:"chat", 
                    name:user.nick_name,staff_message:true, email:user.email,targetStation:'/topic/'+visitorId
                  }

      //send to visitor
      appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));  
      //send to operator

      for(let opUid of chatting_operators){
            opMsg.targetStation = '/topic/' +opUid; 
            opMsg.sourceStation = visitorId;
            appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg)); 
      }
   }


   joinSupervisor =(appstore) =>{
        const user =  appstore.userInfo.usersmodel;
        const currentTime = this.getcurrentUtcTime();
        const userRole = user.user_type_id == 3 ? 'admin' : 'operator';
        if(userRole !=="admin")
          return;

        let opMsg = { 
                id:'',system_msg:true, userUid:appstore.requestedVisitorId, operatorUid:user.user_uid, creationTime:currentTime,
                role:userRole, account_uid:appstore.userInfo.accountsmodel.account_uid, acceptedBy:user.user_uid, message:user.nick_name+" has entered chat",
                fileName:"",originalFileName:"",fileSize:"", hostname:'ip', sourceStation:appstore.requestedVisitorId,chatStatus:"chat", 
                name:user.nick_name,staff_message:true, email:user.email,targetStation:'/topic/'+appstore.requestedVisitorId
              }

         //send to visitor
         appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));  

         //send to operators 
         let chattingOperators = [];
         appstore.visitors[appstore.requestedVisitorId].filter((obj)=> {
          if(obj.operatorUid !=='' && obj.operatorUid !== user.user_uid && (obj.role==="operator" || obj.role==="admin"))
              if(!chattingOperators.includes(obj.operatorUid))
                chattingOperators.push(obj.operatorUid)
        })

        for(let id of  chattingOperators){
            
            opMsg.targetStation = '/topic/'+id;
            appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg)); 
        }
       

        this.currentChattingVisitor[0].joinedConversation=true;
        this.currentChattingVisitor[0].quitAndBackTosupervision = false;
   }

   updateTrafficEntry(appstore){
      let currentChatVisitor=[];
      for(let i=0; i< appstore.trafficVisitorStatus.length; i++){
        if(appstore.trafficVisitorStatus[i].sourceStation === appstore.requestedVisitorId){
          //remainingEntry.push(appstore.trafficVisitorStatus[i]);
          appstore.trafficVisitorStatus[i].status = "chatted";
          appstore.trafficVisitorStatus[i].acceptedBy="";
          currentChatVisitor = appstore.trafficVisitorStatus[i];
          break;
        }
      }

      if(currentChatVisitor.length == 0)
      return;
      
      let targetUid ="";
      const visitor = this.currentChattingVisitor[0];
      //check widget relation betwwen operators
      if(visitor.departmentUid && !visitor.operatorUid){
        targetUid = visitor.departmentUid;
      }
      else if(visitor.departmentUid && visitor.operatorUid || !visitor.departmentUid && visitor.operatorUid){
        targetUid = visitor.operatorUid;
      }
      else {
        //only website
        targetUid = visitor.websiteUid;
      }
 

      //update traffic data of other operator belongs to website
      const user = appstore.userInfo.usersmodel;
      const currentTime = Math.round((new Date()).getTime() / 1000);
      let updateMsg = {
        id: '', role:"operator",userUid: appstore.requestedVisitorId, operatorUid:user.user_uid,  message: "",
         creationTime: currentTime, hostname: 'ip', sourceStation: appstore.requestedVisitorId, chatStatus: "chatted",
         account_uid: appstore.userInfo.accountsmodel.account_uid, name: user.nick_name, email: user.email
        , fileName: "",system_msg:true, originalFileName: "",fileSize:"", targetStation: '/topic/' + targetUid
       }
       
       appstore.clientRef.sendMessage("/app/op2op", JSON.stringify(updateMsg));
        
   }

  //  getTimeFormat(unix_timestamp){
  //     // Create a new JavaScript Date object based on the timestamp
  //     // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  //     var date = new Date(unix_timestamp * 1000);
  //     // Hours part from the timestamp
  //     var hours = date.getHours();
  //     // Minutes part from the timestamp
  //     var minutes = "0" + date.getMinutes();
  //     // Seconds part from the timestamp
  //     var seconds = "0" + date.getSeconds();
      
  //     // Will display time in 10:30:23 format
  //     var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
      
  //     return formattedTime;
  //  }

  setPrechatFields = (visitordata) => {
        let fields = visitordata.find(obj => {
          if(obj.prechatfields)
          return Object.keys(obj.prechatfields).length !==0
      });
     if(fields)
      this.prechatfields = Object.values(JSON.parse(fields.prechatfields.replace(/\n/g, " ")));
       
      return this.prechatfields;
  }


   setWebsite = (uid, allwebsites) =>{
     for(let j=0; j<allwebsites.length; j++)
      if(allwebsites[j].website_uid === uid ){
        this.widgetWebsite = allwebsites[j];
        break;
      }
 
       if(this.widgetWebsite.transferLinkLiveType === "all")
        this.fileData.life =  this.fileLife[0];
       else
        this.fileData.life = this.widgetWebsite.transferLinkLiveType === "persistent" ? this.fileLife[0] :  this.fileLife[1];
   }

   
	filterResponses = () => {
    if(this.filterResponseInput.trim().length == 0)
      return this.responsesTabContent;
      
		return this.responsesTabContent.filter(
			(response) => {
				return response.question.toLowerCase().indexOf(this.filterResponseInput.toLowerCase()) != -1 ||
					response.value.toLowerCase().indexOf(this.filterResponseInput.toLowerCase()) != -1;
			})
    }


    convertUTCDateToLocalDate=(date) =>{
      date = new Date(date);

      var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
    
      return newDate;   
    }


 }

 decorate(ChatComponentStore, {
     infoTabData: observable,
     infoTabAddNotes: observable,
     infoNote: observable,
     footstepsTabContent: observable,
     responsesTabContent: observable,
     userdata: observable,
     hideInfoSection: observable,
     historyTabContent: observable,
     fileData: observable,
     editorState: observable,
     chat: observable,
     editorEditedValue: observable,
     currentChattingVisitor:observable,
     imageRef: observable,
     imagewarning: observable,
     warning_image_name: observable,
     count:observable,
     showOpforTransferchat:observable,
     showResponses:observable,
     currentVisitorTyping:observable,
     fullcontactFound:observable,
     showgeoMap:observable,
     chatOnrefresh:observable,
     undersupervision:observable,
     allVirtualAgents:observable,
     discSizeFull:observable,
     widgetWebsite:observable,
     defaultTab:observable,
     filterResponseInput:observable,
     quitChatConfirm:observable,
     mailtranscripts:observable,
     transcript_emailid:observable,
     validcondtion:observable,
     transcript_error:observable,
     labour_tab:observable,
     prechatfields:observable,
     cannedFileNameRemove:observable,
     banvisitor_initiate:observable,
     opHasleft:observable,
     errormodel:observable,
     optypingcount:observable,
     chatprintdata:observable,
     componentScroll:observable,
     iscannedEnabled:observable,
     isSc_CannedEnabled:observable
    })
 export default ChatComponentStore;
