import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import CustomScroll from 'react-custom-scroll';
import axios from 'axios';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const HistoryContentComponent = observer(class HistoryContentComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }

    getchatData = (history,appstore) =>{
	
		if(history.session_uid.trim().length ==0)
		  return;
		
		axios({
			method: 'POST',
			url: appstore.url+"getchatData.json",
			headers: { 'content-type': 'application/json' },
			data: { session_uid : history.session_uid}
		  })
		.then(response => {
			// handle success
			const chatData = this.setChatinfoData(response.data, appstore);
			console.log(chatData);

			let lengthofChat = chatData.transcript.length;
			let startTime  = chatData.transcript[0] ? chatData.transcript[0].chatTime : null;
			let endTime = chatData.transcript[lengthofChat-1] ? chatData.transcript[0].chatTime : null;
			history.transcripts = {
				   "information":chatData.information,
				  "chatTime": this.getChatLength(startTime,endTime),
				  "chatData": chatData.transcript
				}
			history.showDetails = true;
			appstore.global_errorcount=0;
		})
		.catch(error => {
			// appstore.logout();
			console.log(error);
			console.log("error count :"+appstore.global_errorcount);
			appstore.global_errorcount=appstore.global_errorcount+1;
			if(appstore.global_errorcount>20){
			// appstore.logout();
			}
		})
	}

    getChatLength = (start_time ,end_time) =>{
		let starttime = new Date(Date.parse(start_time, "dd/mm/yyyy HH:mm:ss"));
		let endtime = new Date(Date.parse(end_time, "dd/mm/yyyy HH:mm:ss"));
		//var utc1 = Date.UTC(new_date_obj.getFullYear(), new_date_obj.getMonth(), new_date_obj.getDate());
		//var utc2 = Date.UTC(old_date_obj.getFullYear(), old_date_obj.getMonth(), old_date_obj.getDate());
  
  
		let old_date_objs = new Date(starttime).getTime() / 1000;
		let new_date_objs = new Date(endtime).getTime() / 1000;
  
		var difference_ms = Math.abs(new_date_objs - old_date_objs); 
		let hours = Math.floor(difference_ms / 3600).toString();
		difference_ms %= 3600;
		let minutes = Math.floor(difference_ms / 60).toString();
		let seconds = (difference_ms % 60).toString();
		
		return hours>0 ? hours+ "h " + minutes + "m " + seconds +"s" : minutes + "m " + seconds +"s";
	}
	 
	setChatinfoData = (chatjsondata, appstore) => {
		
		    let chatdata = chatjsondata;
			let data = {
			  "information": [],
			  "transcript": []
			}
			
			

			let previousUser;
			for (let i in chatdata) {
			  if (i === "meta") {
				if( chatdata[i]["visitor_data"]["fields"]){
				  data.information = Object.values(JSON.parse(chatdata[i]["visitor_data"]["fields"].replace(/\n/g," ")));
				  data.visitor_data = chatdata[i]["visitor_data"];
				}
				
				// chatdata[i].chatTime = total_time;
				// chatdata[i].department = depts[chatdata[i]["visitor_data"]["params"]["department_uid"]] ? depts[chatdata[i]["visitor_data"]["params"]["department_uid"]] : "";
				// chatdata[i].website = websites[chatdata[i]["visitor_data"]["params"]["website_uid"]] ? websites[chatdata[i]["visitor_data"]["params"]["website_uid"]] : "";
				 
				// data.information.push(chatdata[i]);

				//moment().format("HH:mm:ss").toString(),
			  } else {
				if(chatdata[i].c)  
					data.transcript.push({
					"chat": chatdata[i].c,
					"time": this.props.chatComponentStore.convertUTCDateToLocalDate(chatdata[i].d),
					"user": chatdata[i].s,
					"name": this.getSenderName(chatdata[i]["s"], appstore),
					"chatTime":chatdata[i].d,
				 // "name": previousUser === chatdata[i]["s"] ? "          " : this.getSenderName(chatdata[i]["s"], appstore)
				})
				//previousUser = chatdata[i]["s"];
			  }
			}
			
			data.information.push({"name":"Website","value": this.getDeptorWebsiteName(data,"website")});
			data.information.push({"name":"Department","value": this.getDeptorWebsiteName(data,"department")});	
         
			return data;
	  }

	  getDeptorWebsiteName =(data ,type) =>{

		if(!data)
		return;
		let name ="";
		const param = data.visitor_data.params;

		if(type==="website")
			name = this.props.appstore.userInfo.allwebistes.find(web => web.website_uid === param.website_uid );
			
		else
			name = this.props.appstore.userInfo.alldepartments.find(dept => dept.department_uid === param.department_uid );

		 return name ? name.name : "deleted "+type;	
	  }

	  getSenderName = (uid, appstore) => {
		let senderData = appstore.userInfo.alloperators.find(function (user) { return user.user_uid === uid });
	
		if (senderData) {
		  return senderData['nick_name'];
		} else if (uid == -2) {
		  if (this.props.chatComponentStore.allVirtualAgents == null)
			return "Judy Virtual agent";
	
		  let vaName = this.props.chatComponentStore.allVirtualAgents.find((va) => { return va.accountUid === appstore.userInfo.accountsmodel.account_uid });
		  let va = vaName ? vaName.name : 'Judy';
		  
		  return va + " (Virtual agent)";
		}else if (uid == -3) {
			if (this.props.chatComponentStore.allAISettings == null)
			  return "ChatBot";
	  
			let aiName = this.props.chatComponentStore.allAISettings.find((ai) => { return ai.accountUid === appstore.userInfo.accountsmodel.account_uid });
			let ai = aiName ? aiName.ai_name : 'AI';
			return ai + " (ChatBot)";
		  }
		else {
		  return 'Visitor';
		}
	  }
	 
	render(){
		const { appstore,
				chatComponentStore } = this.props;
		var self = this;

		return (
			<div className="tabContent">
				{
				  !chatComponentStore.hideInfoSection && 
				  	<React.Fragment>
				  		<h6>{appstore.getLanguageTrans("History")}</h6>
				  		<div className="col-12 historyWrapper data">
					  		{
					  			chatComponentStore.historyTabContent.map(function(history, historyIndex) {
					  				return (
					  					<div className="row" key={historyIndex}>
											 
											<div className="col-4 label" onClick={() => self.getchatData(history,appstore)}>
												<p className="time">{history.date} <span>{history.time}</span></p>
											</div>
											<div className="col-8 value" onClick={() => history.showDetails = true}>
												<p className="link">{history.issue}</p>
											</div>
											{
												history.showDetails &&
													<div className="col-12 transcriptDetails">
														<img src={imageURLs.deleteIcon} className="float-right img-fluid" onClick={() => history.showDetails=false}/> 
														<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
														{ history.transcripts.information &&
															history.transcripts.information.map(function(info, chatIndex) {
															return (
																	<div className="row">
																		<div className="col-4 label">
																			<p className="time">{appstore.getLanguageTrans(info.name)}:</p>
																		</div>
																
																		<div className="col-8 value">
																			<p className="link"  dangerouslySetInnerHTML={{__html:info.type === "dropdown" ? info.params[info.value] : info.value}}></p>
																		</div>
																	</div>
																)
															})
								  						}	
															<div className="transcript row">
																<h6>{appstore.getLanguageTrans("Transcript")}</h6>
																{
																	history.transcripts.chatData.map(function(chat, chatIndex) {
																		return (
																			<div className="col-12" key={chatIndex}>
																				<p>{chat.name} <span>{ moment(chat.time).format("HH:mm:ss")}</span></p>
																				<p className="response" dangerouslySetInnerHTML={{__html: chat.chat}}></p>
																			</div>
																		)
																	})
																}
															</div>
														</CustomScroll>	
													</div>	
											}
											
										</div>
					  				)
					  			})
					  		}
				  		</div>
				  	</React.Fragment>
				}
			</div>
		)
	}
})
export default HistoryContentComponent;
