import { decorate, observable, extendObservable } from 'mobx';
import React, { Component } from 'react';
import { data } from '../../../assets/JSON/admin_languages';
import { saveAs } from 'file-saver';
import axios from 'axios';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);
class ArchiveChatStore {

  editScriptRef;

  constructor() {

    this.pageisLoaded = false;
    this.archiveChatDrpDwn = [
      { value: 1, label: 'All' },
      { value: 2, label: 'affka.ru' },
      { value: 0, label: 'webieon.com' }
    ];

    this.searchValue = "";
    this.responseData = {
      "transferEnabled": 1,
      "archivechatdata": [],
    };

    this.confirm = false;
    this.test = false;
    this.chatInfo = false;
    this.percenSpaceUsed = ((this.responseData.totalSpace - this.responseData.freeSpace) / this.responseData.totalSpace) * 100;

    this.setDropDownValues();
    this.setInitialValues();
    this.mailtranscripts=false;
    this.transcript_emailid="";
    this.emailArchiveArray = [];
    this.transcript_error="";
    this.mailstatus=false;

  }


  setChatinfoData(selected, chatjsondata, appstore) {
    if(!selected.session_uid)
    return;


    this.saveinProcess = true; 
    axios({
      method: 'POST',
      url: appstore.url + 'getchatData.json',
      headers: { 'content-type': 'application/json' },
      data: { session_uid :selected.session_uid}
    }).then(result => {
      if (result.data.length != 0) {
                
        //var chatdata = {"0":{"c":"new visitor","s":"F1581505203309","d":"2020-02-12 16:30:06.0"},"1":{"c":"new request","s":"F1581505203309","d":"2020-02-12 16:30:06.0"},"2":{"c":"jola","s":"F1581505203309","d":"2020-02-12 16:30:06.0"},"3":{"c":"new visitor","s":"F1581505203309","d":"2020-02-12 16:30:06.0"},"4":{"c":"entered chat","s":"50b6b9c0-ac00-4d8c-a833-7393b86abfd4","d":"2020-02-12 16:30:06.0"},"5":{"c":"","s":"F1581505203309","d":"2020-02-12 16:30:06.0"},"6":{"c":"","s":"F1581505203309","d":"2020-02-12 16:30:06.0"},"meta":{"website_name":"","referrer":"","date_created":"2020-02-12 16:30:06.0","visitor_data":{"params":{"website_uid":"5b9b7f0a-a974-4a6c-b2ad-46d82d37e101","department_uid":""},"fields":"{\"0\":{\"name\":\"name\",\"value\":\"sagar\",\"type\":\"text\"},\"1\":{\"name\":\"email\",\"value\":\"jhgjh@JKHk.com\",\"type\":\"text\"},\"2\":{\"name\":\"name\",\"value\":\"kjhkj\",\"type\":\"text\"},\"3\":{\"name\":\"drop\",\"params\":[\"ww\",\"ee\"],\"value\":0,\"type\":\"dropdown\"},\"4\":{\"id\":\"noweb_new_department\",\"name\":\"hello google  \",\"value\":\"\",\"type\":\"radio\"}}"},"rating":"","fc":"","from_url":"file:///C:/Project/modelpopup.html"},"7":{"c":"<p>a<\/p>\n","s":"50b6b9c0-ac00-4d8c-a833-7393b86abfd4","d":"2020-02-12 16:30:06.0"},"8":{"c":"","s":"50b6b9c0-ac00-4d8c-a833-7393b86abfd4","d":"2020-02-12 16:30:06.0"}}

       var chatdata = result.data; 
        if (!chatdata)
          return;
    
        let total_time = selected.time;
        // let opdata = { "5dd7f338-07d0-497b-a1a2-018ca64e71ad": "Manish" }
        // let depts = { "5dd7f338-07d0-497b-a1a2-018ca64e71ad": "Manish", "5de6057c-3728-455b-9e3c-0d06a64e71ad": "Marketing" }
        // let websites = { "5dd7f338-07d0-497b-a1a2-018ca64e71ad": "Manish", "5dd7f338-94f0-40e0-840a-018ca64e71ad": "googel" }
    
        let data = {
          "information": [],
          "transcript": [],
          "fc": {
            "full_name":"",
            "gender":"",
            "location":"",
            "social_profiles":[],
            "img":"",
            "imgfrom":""
          }
        } 
        
        let previousUser;
        for (let i in chatdata) {
  
          if (i === "meta") {
            if( chatdata[i]["visitor_data"]["fields"]){
              if(selected.session_uid.indexOf("-") !== -1){
                data.information=chatdata[i]["visitor_data"]["fields"];
              }else{
               data.information = Object.values(JSON.parse(chatdata[i]["visitor_data"]["fields"].replace(/\n/g," ")));
              }
            }
            
            // chatdata[i].chatTime = total_time;
            // chatdata[i].department = depts[chatdata[i]["visitor_data"]["params"]["department_uid"]] ? depts[chatdata[i]["visitor_data"]["params"]["department_uid"]] : "";
            // chatdata[i].website = websites[chatdata[i]["visitor_data"]["params"]["website_uid"]] ? websites[chatdata[i]["visitor_data"]["params"]["website_uid"]] : "";
             
            // data.information.push(chatdata[i]);
            if(chatdata[i]["fc"]){  
             // let fullcontact=JSON.parse(chatdata[i]["fc"]);
              if(chatdata[i]["fc"].hasOwnProperty("contactInfo")){
                  var fc ={ 
                    "full_name": chatdata[i]["fc"].hasOwnProperty("contactInfo") ? chatdata[i]["fc"].contactInfo.fullName:"",
                    "gender":chatdata[i]["fc"].hasOwnProperty("demographics") ? chatdata[i]["fc"].demographics.gender:"",
                    "location": chatdata[i]["fc"].hasOwnProperty("demographics") ? chatdata[i]["fc"].demographics.locationGeneral:"",
                    "social_profiles":[],
                    "img":chatdata[i]["fc"].hasOwnProperty("photos") ? chatdata[i]["fc"].photos[0].url:"",
                    "imgfrom":chatdata[i]["fc"].hasOwnProperty("photos") ? chatdata[i]["fc"].photos[0].typeName:""
                  }  
                  data.fc=fc; 
                  if(chatdata[i]["fc"].hasOwnProperty("socialProfiles")){
                    for(let k=0;k<chatdata[i]["fc"].socialProfiles.length;k++){ 
                      if(chatdata[i]["fc"].socialProfiles[k].type=="googleplus"||chatdata[i]["fc"].socialProfiles[k].type=="twitter"
                      ||chatdata[i]["fc"].socialProfiles[k].type=="facebook" || chatdata[i]["fc"].socialProfiles[k].type=="linkedin" ||
                        chatdata[i]["fc"].socialProfiles[k].type=="youtube"){
                        var social={
                          "type" :chatdata[i]["fc"].socialProfiles[k].type, 
                        } 
                        data.fc.social_profiles.push(social);
                      }
                    } 
                  }
              }else{
                
                let fullcontact=JSON.parse(chatdata[i]["fc"]);  
                var fc ={ 
                  "full_name": fullcontact.hasOwnProperty("fullName") ? fullcontact.fullNam!=null ? fullcontact.fullName:"":"",
                  "gender": fullcontact.hasOwnProperty("details") ? fullcontact.details.hasOwnProperty("demographics") ? fullcontact.details.demographics.gender!=null ? fullcontact.details.demographics.gender:"" :"":"",
                  "location": fullcontact.hasOwnProperty("details") ? fullcontact.details.hasOwnProperty("location") ? fullcontact.details.location!=null ? fullcontact.details.location:"":"":"",
                  "social_profiles":[],
                  "img": fullcontact.hasOwnProperty("details") ? fullcontact.details.hasOwnProperty("photos") ? fullcontact.details.photos>0 ? fullcontact.details.photos[0].value:"":"" :"",
                  "imgfrom": fullcontact.hasOwnProperty("details") ? fullcontact.details.hasOwnProperty("photos") ? fullcontact.details.photos>0 ? fullcontact.details.photos[0].label:"":"":""
                }  
                data.fc=fc; 
                if(fullcontact.hasOwnProperty("details") && fullcontact.details.hasOwnProperty("profiles")){
                  let socialprofiles=fullcontact.details.profiles; 
                    if(socialprofiles.hasOwnProperty("googleplus")){
                      var social={
                        "type" :socialprofiles.googleplus.service, 
                      } 
                      data.fc.social_profiles.push(social);
                    }
                    if(socialprofiles.hasOwnProperty("twitter")){
                      var social={
                        "type" :socialprofiles.twitter.service, 
                      } 
                      data.fc.social_profiles.push(social);
                    }
                    if(socialprofiles.hasOwnProperty("facebook")){
                      var social={
                        "type" :socialprofiles.facebook.service, 
                      } 
                      data.fc.social_profiles.push(social);
                    }
                    if(socialprofiles.hasOwnProperty("linkedin")){ 
                      var social={
                        "type" :socialprofiles.linkedin.service, 
                      } 
                      data.fc.social_profiles.push(social); 
                    }
                    if(socialprofiles.hasOwnProperty("youtube")){
                      var social={
                        "type" :socialprofiles.youtube.service, 
                      } 
                      data.fc.social_profiles.push(social);
                    }   
                    
                } 
              }
            }

          }
           else {
            data.transcript.push({
              "message": chatdata[i].c.trim(),
              "date": chatdata[i].d,
              "user": chatdata[i].s,
              "op_name": previousUser === chatdata[i]["s"] ? "          " : this.getSenderName(chatdata[i]["s"], appstore)
            })
            previousUser = chatdata[i]["s"];
    
          }
    
        }
       
        data.information.push({"name":"Date","value":chatdata.meta.date_created});
        data.information.push({"name":"Website","value": chatdata.meta.website_name});
        //data.information.push({"name":"Website","value": this.getWebsiteNameinfo(appstore,chatdata["meta"].visitor_data.params.website_uid)});
        data.information.push({"name":"Start page","value":chatdata.meta.from_url}); 

        if(chatdata["meta"]["rating"])
           data.information.push({"name":"Operator rating","value":chatdata["meta"]["rating"]})

        data.information.push({"name":"Chat time","value":total_time}); 
           
        this.userChatdata = data;
        this.chatInfo = true;
      }
      else {
       
      }
    
      this.saveinProcess = false;
      appstore.global_errorcount=0;
    }).catch((error) => {
      // appstore.logout();
      this.saveinProcess = false;
      console.log(error);
          console.log("error count :"+appstore.global_errorcount);
          appstore.global_errorcount=appstore.global_errorcount+1;
          if(appstore.global_errorcount>20){
          // appstore.logout();
          }
    });

    
  }
  getWebsiteNameinfo(appstore,uid){
    let website =  appstore.userInfo.allwebistes.find(website => website.website_uid === uid)
    return website ? website.name : "";
  }
  setInitialValues() {
    this.deleteArchiveArray = [];
    this.allSelected = false;
    this.responseData.archivechatdata.map(function (filetype, typeIndex) {
      filetype.selected = false;
    });
  }

  setDropDownValues() {
    let self = this;
    this.archiveChatDrpDwn.map(function (filetype, typeIndex) {
      if (filetype.value === self.responseData.transferEnabled) {
        self.fileTransferType = filetype;
      }
    });
  }

  selectAll() {
    let self = this;
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.responseData.archivechatdata.map(function (filetype, typeIndex) {
        filetype.selected = true;
        self.deleteArchiveArray.push(typeIndex);
      });
    }
    else {
      this.responseData.archivechatdata.map(function (filetype, typeIndex) {
        filetype.selected = false;
      });
      this.deleteArchiveArray = [];
    }
  }

  confirmAction(appstore) {
    this.confirm = false;
    this.pageisLoaded = false;
    let deletefiles = [];
    let self = this;
    if (this.deleteArchiveArray.length == 0)
      return;

    for (let i in this.deleteArchiveArray) {
      deletefiles.push(
        this.responseData.archivechatdata.find(function (value, index) {
          return index == self.deleteArchiveArray[i];
        })
      )
    }
    axios({
      method: 'POST',
      url: appstore.url + 'deleteArchiveHistory.json',
      headers: { 'content-type': 'application/json' },
      data: deletefiles
    }).then(result => {
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      if (result.data.length != 0 && result.data[0].queryStatus != -1) {
        this.setArchivehistory(result.data);
        this.allVirtualAgents = result.data[0].vasettings;
      } else if (result.data[0].queryStatus == -1) {
        this.errormodel = true;
      }
      else {
        this.responseData.archivechatdata = [];
        this.totalPages = 1;
      }
      this.pageisLoaded = true;
      appstore.global_errorcount=0;
    }).catch((error) => {
     // appstore.logout();
      this.pageisLoaded = true;
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    });
    // this.pageisLoaded = true;           
    this.setInitialValues();
  }

  chatInfoAction() {
    this.chatInfo = false;
  }

  chatSelected(selected, appstore) {
    //  this.selectedChatId = selectedId;
    let data = "hi";
    this.setChatinfoData(selected, data, appstore);
   
  }

  getArchiveHistoryList = (appstore, start, end) => {
    let content = this.searchValue;
    axios({
      method: 'POST',
      url: appstore.url + 'getarchiveHistoryList.json',
      headers: { 'content-type': 'application/json' },
      data: { "offset": [start, end], "searchContent": content },
    }).then(result => { 
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      if (result.data.length != 0) {
        this.setArchivehistory(result.data); 
        this.allVirtualAgents = result.data[0].vasettings; 
        this.allAISettings = result.data[0].aisettings        
      }
      else {
        this.responseData.archivechatdata = [];
        this.totalPages = 1;
      } 
      this.pageisLoaded = true;
      this.saveinProcess = false; 
      appstore.global_errorcount=0;
    }).catch((error) => {
      // if (error.response) {
      //   appstore.logout();
      // }
      this.pageisLoaded = true;
      this.saveinProcess = false; 
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    });
  }


  setArchivehistory = (data) => {
    this.responseData.archivechatdata = [];
    for (let i in data) { 
      let starttime = new Date(Date.parse(data[i].start_time, "dd/mm/yyyy HH:mm:ss"));
      let endtime = new Date(Date.parse(data[i].end_time, "dd/mm/yyyy HH:mm:ss"));
      let started = new Date(Date.parse(data[i].started, "dd/mm/yyyy HH:mm:ss"));
      let pickup = new Date(Date.parse(data[i].pickup, "dd/mm/yyyy HH:mm:ss"));
      
      let old_date_objs = new Date(starttime).getTime() / 1000;
      let new_date_objs = new Date(endtime).getTime() / 1000;
      let started_date_objs = new Date(started).getTime() / 1000;
      let pickup_date_objs = new Date(pickup).getTime() / 1000;

      //pickup time
      var difference_msp = Math.abs(pickup_date_objs - started_date_objs); 
      let pickhours = Math.floor(difference_msp / 3600).toString();
      difference_msp %= 3600;
      let pickminutes = Math.floor(difference_msp / 60).toString();
      let pickseconds = (difference_msp % 60).toString(); 
      //time
      var difference_ms = Math.abs(new_date_objs - old_date_objs); 
      let hours = Math.floor(difference_ms / 3600).toString();
      difference_ms %= 3600;
      let minutes = Math.floor(difference_ms / 60).toString();
      let seconds = (difference_ms % 60).toString(); 
     
      var dates = moment(moment.utc(data[i].archived_time).toDate()).format("DD.MM.YYYY HH:mm");

      let time = data[i].started;
      var d = time.split("T");
      var t = d[1].split(".");  
      this.responseData.archivechatdata[i] = {
        "session_uid": data[i].session_uid,
        "date": dates,
        "time": hours>0 ? hours+ "h " + minutes + "m " + seconds +"s" : minutes + "m " + seconds +"s",
        "visitor": data[i].nick_name,
        "email": data[i].email,
        "pickuptime": pickhours>0 ? pickhours+ "h " + pickminutes + "m " + pickseconds +"s" : pickhours== "NaN" ? " ": pickminutes + "m " + pickseconds +"s",
        "Operators": data[i].concat_op_name.trim().length == 0 ? "Deleted Operator" : data[i].concat_op_name
      } 
    } 
 
    this.setInitialValues()
    //set page no
    if (data.length != 0) {
      let count = data[0].full_count / 30;  //30 record in each page
      let decimals = count - Math.floor(count);
      if (decimals != 0)
        this.totalPages = Math.floor(count) + 1;
      else
        this.totalPages = Math.floor(count);
    } 
  }
  convertUTCDateToLocalDate=(date) =>{
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
// alert(newDate)
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
  exportChat = async(appstore) =>{
    let selectedRows = this.deleteArchiveArray;
    //if seleted rows for exports is 0 then return.
    if (selectedRows.length == 0)
      return;

    let zipfiledata = await this.setDataforexport(appstore);
    const zip = require('jszip')();
    let mainFolder = zip.folder("chat_history");

    for (let i in zipfiledata) {
      let filename = zipfiledata[i]["name"] + ".txt";
      mainFolder.file(filename, zipfiledata[i]["data"]);
    }

    mainFolder.generateAsync({ type: "blob" })
      .then(function (content) {
        // see FileSaver.js
        saveAs(content, "chat12324.zip");
      });
  }


  async setDataforexport (appstore) {

    var x = {
      "0": { "c": "hi", "d": "2019-12-21 07:33:00", "s": "1893dae1-7bff-4343-b358-7894077d879f" },
      "1": { "c": "<p>ok<\/p>", "d": "2019-12-21 07:33:06", "s": "-2" },
      "2": { "c": "<p>#gg<\/p>", "d": "2019-12-21 07:33:20", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "3": { "c": "<p> &nbsp;<\/p>", "d": "2019-12-21 07:33:21", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "4": { "c": "<a href=\"http:\/\/app.helponclick.com\/download\/83b4f48b-858e-4a31-8780-2b7d4a25e45c\/sample.jpg\" target=\"_blank\">app.helponclick.com\/download\/83b4f48b-858e-4a31-8780-2b7d4a25e45c\/sample.jpg<\/a>", "d": "2019-12-21 07:33:21", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "5": { "c": "<p>ok<\/p>", "d": "2019-12-21 07:33:32", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "6": { "c": "<p><img src=\"https:\/\/traffic8.helponclick.com\/v6.1-58\/static\/\/tinymce\/plugins\/emotions\/img\/smiley-kiss.gif\" alt=\"Kiss\"><\/p>", "d": "2019-12-21 07:33:39", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "7": { "c": "<p><img src=\"https:\/\/traffic8.helponclick.com\/v6.1-58\/static\/\/tinymce\/plugins\/emotions\/img\/smiley-laughing.gif\" alt=\"Laughing\">&nbsp;emoji<\/p>", "d": "2019-12-21 07:33:49", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "8": { "c": "<p><img src=\"https:\/\/traffic8.helponclick.com\/v6.1-58\/static\/\/tinymce\/plugins\/emotions\/img\/smiley-laughing.gif\" alt=\"Laughing\"><\/p>", "d": "2019-12-21 07:33:58", "s": "50b6b9c0-ac00-4d8c-a833-7393b86abfd4" },
      "meta": { "from_url": null, "website_name": "", "date_created": "2019-12-21 07:32:59", "rating": null, "referrer": "", "visitor_data": { "params": { "website_uid": "5dd7f338-94f0-40e0-840a-018ca64e71ad", "department_uid": "5de6057c-3728-455b-9e3c-0d06a64e71ad" } }, "fc": null }
    }
    let self = this;
    let allchatData =[];
    let sessionsUid=[];
    for (let i in this.deleteArchiveArray) {
      sessionsUid.push(
        this.responseData.archivechatdata.find(function (value, index) {
          return index == self.deleteArchiveArray[i];
        }))
      }

    await axios({
      method: 'POST',
      url: appstore.url + 'getselectedchatData.json',
      headers: { 'content-type': 'application/json' },
      data: sessionsUid,
    }).then(result => {
      if (result.data.length != 0) {
        allchatData =  result.data;     
      }
      appstore.global_errorcount=0;
    })
    .catch(err=>{
      // appstore.logout();
      console.log(err);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })

    //get the selected chat list
    let selectedchat = [];
    let zipfiledata = [];
  
    for (let i in this.deleteArchiveArray) {
      selectedchat.push(
        this.responseData.archivechatdata.find(function (value, index) {
          return index == self.deleteArchiveArray[i];
        }))
      zipfiledata[i] = {
        "name": selectedchat[i].session_uid,
        "data": allchatData[i] ? JSON.parse(allchatData[i]) :"",
      }
      // if( chatdata[i]["visitor_data"]["fields"]){
      //   if(selected.session_uid.indexOf("-") !== -1){
      //     data.information=chatdata[i]["visitor_data"]["fields"];
      //   }else{
      //    data.information = Object.values(JSON.parse(chatdata[i]["visitor_data"]["fields"].replace(/\n/g," ")));
      //   }
      // }
      // console.log(zipfiledata[i]['data']['meta']["visitor_data"]["fields"]);
      var prechat=JSON.parse(zipfiledata[i]['data']['meta']["visitor_data"]["fields"]);
      
      //read chat file and set zip data
      var vistorName="";
      let msg = "";
      msg = msg + "Information\r\n-------------------------------- ";
      msg = msg + "\r\n";
      
      if(prechat)
        for(let k in prechat) {        
          if(prechat[k].name!=undefined && prechat[k].value!=undefined && prechat[k].value!=""){
            msg = msg +prechat[k].name+": "+prechat[k].value+ "\r\n";
                if(prechat[k].type.toLowerCase() === "name" && prechat[k].value!=undefined && prechat[k].value!=""){
                  if(vistorName==""){
                  vistorName=prechat[k].value;
                  }
                }
          }
        }

      msg = msg + "Date:  " + moment(this.convertUTCDateTime(zipfiledata[i]['data']['meta']['date_created'])).format("DD.MM.YYYY") + "\r\n";
      msg = msg + "Website: " + zipfiledata[i]['data']['meta']['website_name'] + "\r\n";
      msg = msg + "Start page: " + zipfiledata[i]['data']['meta']['from_url'] + "\r\n";
      msg = msg + "Chat time: " + selectedchat[i].time;
      msg = msg + (zipfiledata[i]['data']["meta"]['rating'] ? "Operator rating:"+ zipfiledata[i]["data"]['meta']['rating'] :"");
      msg = msg + "\r\n \r\n";
      msg = msg + "Transcript\r\n-------------------------------- \r\n";

      let startTime="";
      let endTime="";
      let prev;
      let emptyspace = '';
      
      for (let j in zipfiledata[i]['data']) {
        if (j !== "meta") {
          if (prev === zipfiledata[i]['data'][j]['s'])
            emptyspace = '          ';
          else {
            emptyspace = this.getSenderName(zipfiledata[i]['data'][j]['s'], appstore)=="Visitor" ? (vistorName=="" ? "Visitor":vistorName) : this.getSenderName(zipfiledata[i]['data'][j]['s'], appstore) ;
          }

          // if(startTime==""){
          //   startTime=zipfiledata[i]['data'][0]['d'];
          // }
          // endTime=zipfiledata[i]['data'][0]['d'];
          
         // msg = msg + emptyspace + "   " + zipfiledata[i]['data'][j]['d'].split(" ")[1] + "     " + zipfiledata[i]['data'][j]['c'].replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", " ") + "\r\n\r\n";
          msg = msg + emptyspace + "   " + moment(self.convertUTCDateTime(zipfiledata[i]['data'][j]['d'])).format("HH:mm:ss") + "     " + zipfiledata[i]['data'][j]['c'].replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", " ") + "\r\n\r\n";
          prev = zipfiledata[i]['data'][j]['s'];
        }
      }

      


      zipfiledata[i].data = msg;
    }
   
    return zipfiledata;
  }

	convertUTCDateTime = (datestring) =>{ 
		// console.log("start");
		// console.log(datestring);
		var newDate = new Date(datestring);
		// console.log(newDate);
		var chatdate=moment(newDate).format("MM/DD/YYYY HH:mm:ss");
		// console.log(chatdate);
		//var dateconverted = new Date(chatdate+" UTC");
		var dateconverted = new Date(chatdate+" UTC");
		// console.log(dateconverted);
		// console.log("end");
		return dateconverted.toString();
	}


  getSenderName(uid, appstore) {
    let senderData = appstore.userInfo.alloperators.find(function (user) { return user.user_uid === uid });    
    if (senderData) {
      return senderData['nick_name'];
    } else if (uid == -2) {
      if (this.allVirtualAgents == null)
        return "Judy Virtual agent";

      let vaName = this.allVirtualAgents.find((va) => { return va.accountUid === appstore.userInfo.accountsmodel.account_uid });
      let va = vaName ? vaName.name : 'Judy';
      return va + " (Virtual agent)";
    }else if(uid == -3){
      if (this.allAISettings == null)
        return "ChatBot";

      let aiName = this.allAISettings.find((ai) => { return ai.accountUid === appstore.userInfo.accountsmodel.account_uid });
      let ai = aiName ? aiName.ai_name : 'AI';
      return ai + " (ChatBot)";            
    }
    else {
      return 'Visitor';
    }
  }

  getTitle = (chatData)=>{
		//console.log(chatData.information);
        let title="";
		for(let i=0; i< chatData.information.length; i++){
			if (chatData.information[i].value != null) {
				if(chatData.information[i].type === "name"){
					title = chatData.information[i].value;
					break;
				}
			}
		}

		return title.trim().length != 0 ? title : "Visitor";//+" "+chatData.information[0].date_created;
		
	}
}

decorate(ArchiveChatStore, {
  fileTransferType: observable,
  transFileLifeTimeType: observable,
  responseData: observable,
  deleteArchiveArray: observable,
  percenSpaceUsed: observable,
  confirm: observable,
  chatInfo: observable,
  tableHeight: observable,
  selectedChatId: observable,
  test: observable,
  totalPages: observable,
  pageisLoaded: observable,
  userChatdata: observable,
  searchValue: observable,
  saveinProcess: observable,
  errormodel: observable,
  mailtranscripts:observable,
  transcript_emailid:observable,
  emailArchiveArray:observable,
  mailstatus:observable,
  transcript_error:observable
})
export default ArchiveChatStore;